import { useMemo } from "react";

import { ClassNameMap } from "@mui/material/styles";

export const useMergeClasses = <ClassKey extends string = string>(
  internalClasses: ClassNameMap<ClassKey>,
  externalClasses: Partial<ClassNameMap<ClassKey>> | undefined
) => {
  return useMemo(() => {
    const mergedClasses = { ...internalClasses }; // Start with all properties from internalClasses

    if (!externalClasses) {
      return mergedClasses;
    }

    for (const key in externalClasses) {
      if (mergedClasses[key]) {
        // If the key exists in both records, join the values with a space
        mergedClasses[key] = `${mergedClasses[key]} ${externalClasses[key]}`;
      } else {
        // Otherwise, just add the key and value
        mergedClasses[key] = externalClasses[key] as string;
      }
    }

    return mergedClasses;
  }, [internalClasses, externalClasses]);
};
