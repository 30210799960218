export var PaymentMethod;
(function (PaymentMethod) {
    /**
     * @type {string}
     * @deprecated.
     */
    PaymentMethod["Transfer"] = "transfer";
    /**
     * @type {string}
     * @deprecated.
     */
    PaymentMethod["Unknown"] = "unknown";
    /**
     * @type {string}
     * @deprecated.
     */
    PaymentMethod["Manual"] = "manual";
    PaymentMethod["Free"] = "free";
    PaymentMethod["Card"] = "card";
    PaymentMethod["Cheque"] = "cheque";
    PaymentMethod["Pad"] = "pad";
    PaymentMethod["Ach"] = "ach";
    PaymentMethod["ApplePayOrGooglePay"] = "applePayOrGooglePay";
    PaymentMethod["Cash"] = "cash";
    PaymentMethod["InKind"] = "inKind";
    PaymentMethod["Stocks"] = "stocks";
    PaymentMethod["Other"] = "other";
    PaymentMethod["TapToPay"] = "tapToPay";
    PaymentMethod["CashApp"] = "cashApp";
})(PaymentMethod || (PaymentMethod = {}));
export var StripeRecurringInterval;
(function (StripeRecurringInterval) {
    StripeRecurringInterval["day"] = "day";
    StripeRecurringInterval["week"] = "week";
    StripeRecurringInterval["month"] = "month";
    StripeRecurringInterval["year"] = "year";
})(StripeRecurringInterval || (StripeRecurringInterval = {}));
export var ChargeType;
(function (ChargeType) {
    ChargeType["Donation"] = "Donation";
    ChargeType["Ticket"] = "Ticket";
    ChargeType["DebitConnectedAccount"] = "DebitConnectedAccount";
})(ChargeType || (ChargeType = {}));
export var StripeAvailablePaymentMethod;
(function (StripeAvailablePaymentMethod) {
    StripeAvailablePaymentMethod["Card"] = "card";
    StripeAvailablePaymentMethod["Pad"] = "acss_debit";
    StripeAvailablePaymentMethod["Ach"] = "us_bank_account";
    StripeAvailablePaymentMethod["CashApp"] = "cashapp";
})(StripeAvailablePaymentMethod || (StripeAvailablePaymentMethod = {}));
export var AvailablePaymentCurrency;
(function (AvailablePaymentCurrency) {
    AvailablePaymentCurrency["Cad"] = "cad";
    AvailablePaymentCurrency["Usd"] = "usd";
})(AvailablePaymentCurrency || (AvailablePaymentCurrency = {}));
