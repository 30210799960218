export var FilterType;
(function (FilterType) {
    FilterType["Date"] = "Date";
    FilterType["String"] = "String";
    FilterType["Number"] = "Number";
    FilterType["Multiselect"] = "Multiselect";
    FilterType["Boolean"] = "Boolean";
})(FilterType || (FilterType = {}));
export var DateFilterOperation;
(function (DateFilterOperation) {
    DateFilterOperation["Before"] = "Before";
    DateFilterOperation["After"] = "After";
    DateFilterOperation["DateRange"] = "DateRange";
    DateFilterOperation["Is"] = "Is";
})(DateFilterOperation || (DateFilterOperation = {}));
export var StringFilterOperation;
(function (StringFilterOperation) {
    StringFilterOperation["Contains"] = "Contains";
    StringFilterOperation["EqualAt"] = "EqualAt";
    StringFilterOperation["ContainsWithFuzzy"] = "ContainsWithFuzzy";
})(StringFilterOperation || (StringFilterOperation = {}));
export var NumberFilterOperation;
(function (NumberFilterOperation) {
    NumberFilterOperation["GreaterThan"] = "GreaterThan";
    NumberFilterOperation["LessThan"] = "LessThan";
    NumberFilterOperation["NumberRange"] = "NumberRange";
})(NumberFilterOperation || (NumberFilterOperation = {}));
export var MultiselectFilterOperation;
(function (MultiselectFilterOperation) {
    MultiselectFilterOperation["In"] = "In";
    MultiselectFilterOperation["NotIn"] = "NotIn";
})(MultiselectFilterOperation || (MultiselectFilterOperation = {}));
export var BooleanFilterOperation;
(function (BooleanFilterOperation) {
    BooleanFilterOperation["EqualAt"] = "EqualAt";
})(BooleanFilterOperation || (BooleanFilterOperation = {}));
export const FilterOperationEnum = {
    ...DateFilterOperation,
    ...StringFilterOperation,
    ...MultiselectFilterOperation,
    ...NumberFilterOperation,
    ...BooleanFilterOperation,
};
