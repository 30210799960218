import React, { forwardRef, useCallback } from "react";

import classnames from "classnames";
import { useSnackbar } from "notistack";

import { useMergeClasses } from "../useMergeClasses";

import { zeffySnackbarClasses, StyledSnackbarContent } from "./styles";
import { SnackbarProps, SnackbarVibe } from "./types";

import { Button } from "@/design-system/Button";
import { Icon } from "@/design-system/Icon";
import { IconButton } from "@/design-system/IconButton";
import { Typography } from "@/design-system/Typography";
import { Error, Success as CheckCircle, Cross as Close, Info, Warning } from "@/icons/outlined";

const SnackbarIconVariant: Record<SnackbarVibe, React.ReactNode> = {
  brand: <Info />,
  danger: <Error />,
  warning: <Warning />,
  positive: <CheckCircle />,
};

export const Snackbar = forwardRef<HTMLDivElement, SnackbarProps>(
  (
    {
      buttonsProps = [],
      classes: externalClasses,
      className,
      hideClose = false,
      hideIcon = false,
      icon,
      id,
      message,
      style,
      vibe = "brand",
      "data-test": dataTest = "snackbar",
    },
    ref
  ) => {
    const { closeSnackbar } = useSnackbar();
    const classes = useMergeClasses(zeffySnackbarClasses, externalClasses);

    const onClose = useCallback(() => {
      closeSnackbar(id);
    }, [closeSnackbar, id]);

    return (
      <StyledSnackbarContent
        className={classnames(className, classes.root, classes[vibe])}
        ref={ref}
        style={style}
        data-test={dataTest}
      >
        <div className={classes.messageSection}>
          {!hideIcon && (icon || SnackbarIconVariant[vibe]) && (
            <Icon size="medium" vibe={`text-${vibe}-moderate`} className={classes.icon} data-test="snackbar-icon">
              {icon || SnackbarIconVariant[vibe]}
            </Icon>
          )}
          <Typography variant="body2" vibe={`text-${vibe}-moderate`}>
            {message}
          </Typography>
        </div>
        <div className={classes.actionsSection}>
          {buttonsProps.map(({ key, className: buttonClassname, ...restOfButtonProps }) => (
            <Button
              key={key}
              className={classnames(classes.action, buttonClassname)}
              variant="tonal"
              vibe={vibe}
              {...restOfButtonProps}
            />
          ))}
          {!hideClose && (
            <IconButton
              className={classes.close}
              size="medium"
              onClick={onClose}
              vibe={vibe}
              data-test="snackbar-dissmiss-button"
            >
              <Close />
            </IconButton>
          )}
        </div>
      </StyledSnackbarContent>
    );
  }
);

Snackbar.displayName = "Snackbar";
