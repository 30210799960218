export var QuestionType;
(function (QuestionType) {
    QuestionType["Simple"] = "Simple";
    QuestionType["Multiple"] = "Multiple";
    QuestionType["MultipleCheckboxes"] = "MultipleCheckboxes";
    QuestionType["PhoneNumber"] = "PhoneNumber";
    QuestionType["Email"] = "Email";
    QuestionType["Date"] = "Date";
    QuestionType["Checkbox"] = "Checkbox";
    QuestionType["Utm"] = "Utm";
    QuestionType["Name"] = "Name";
})(QuestionType || (QuestionType = {}));
