import { CSSProperties } from "@mui/styles/withStyles";

export const getTransitions = (
  transition = "all 100ms ease-out",
  properties = "box-shadow,background-color,color,fill,outline,width,opacity,border,max-width"
): CSSProperties => ({
  "-webkit-transition": transition,
  "-moz-transition": transition,
  "-o-transition": transition,
  "-ms-transition": transition,
  transition,
  ...(properties ? { transitionProperty: properties } : undefined),
});

export const INTERACTIVE_PSUEDO_CLASSES = `&:active, &:hover, &:focus, &:focus-visible, &:focus-within`;
