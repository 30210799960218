import { Icon } from "@mui/material/styles/createTheme";

export const icon: Icon = {
  size: {
    default: {
      extraSmall: 12,
      small: 16,
      medium: 20,
      large: 24,
    },
    inline: {
      extraSmall: 16,
      small: 20,
      medium: 24,
      large: 28,
    },
  },
};
