export var SubscriptionStatus;
(function (SubscriptionStatus) {
    SubscriptionStatus["Cancelled"] = "cancelled";
    SubscriptionStatus["InProgress"] = "inProgress";
})(SubscriptionStatus || (SubscriptionStatus = {}));
export var CancellationChurnReason;
(function (CancellationChurnReason) {
    CancellationChurnReason["ManualDonor"] = "ManualDonor";
    CancellationChurnReason["ManualAdmin"] = "ManualAdmin";
    CancellationChurnReason["PaymentFailure"] = "PaymentFailure";
    CancellationChurnReason["MarkAsFraudulent"] = "MarkAsFraudulent";
})(CancellationChurnReason || (CancellationChurnReason = {}));
