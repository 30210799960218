import { DonationFormCategory, FormType, Locales, TicketingFormCategory, OrganizationCountry } from "@simplyk/common";

import {
  FeatureFlagValue,
  GetCurrentUserFrontendObject,
  OrganizationDetailsObject,
  OrganizationObject,
} from "../gql/gql-types";

export interface IdentifyAmplitudeUserProps {
  currentUser: GetCurrentUserFrontendObject;
  organization?: OrganizationObject | null;
  currentOrganizationDetails?: OrganizationDetailsObject | null;
}

export const REFERRAL_PROMPT_AT_FIRST_PAYOUT_EXPERIENCE_VALUE = {
  [FeatureFlagValue.A]: "Referral prompt at first payout - Version A",
  [FeatureFlagValue.B]: "Referral prompt at first payout - Version B",
  [FeatureFlagValue.C]: "Referral prompt at first payout - Version C",
};

export enum AmplitudeEvents {
  // Frontend
  "AutomaticRedirect" = "Automatic Redirection",
  "PageViewed" = "Page Viewed",
  "ThankYouPageViewed" = "Thank You Page Viewed",
  "DonorFormViewed" = "Donor Form Viewed",
  "RegisterPageViewed" = "Register Page Viewed",
  "ConfirmDonation" = "DON-CONFIRM-DONATION",
  "ConfirmOrder" = "TICK-CONFIRM-ORDER",
  "SuccessSubscription" = "DON-SUCCESS-SUBSCRIPTION",
  "SuccessAnyDonation" = "DON-CONFIRMATION-ANY-DON",
  "SuccessCheque" = "DON-SUCCESS-CHEQUE",
  "SuccessTransfer" = "DON-SUCCESS-TRANSFER",
  "SubmitWithGoogleOrApplePay" = "DON-SUBMIT-APPLE-GOOGLE-PAY",
  "SubmitTicketingWithGoogleOrApplePay" = "TICK-SUBMIT-APPLE-GOOGLE-PAY",
  "ClickOnNextButtonInThanksProducts" = "DON-CLICK-NEXT-THANKS-PRODUCTS",
  "ClickOnTicketingButtonInThanksProducts" = "DON-CLICK-TICKETING-THANKS-PRODUCTS",
  "ClickOnDonationButtonInThanksProducts" = "DON-CLICK-DONATION-THANKS-PRODUCTS",
  "ClickOnNextButtonInThanksMessage" = "DON-CLICK-NEXT-THANKS-MESSAGE",
  "ClickOnNextButtonInTicketingThanksProducts" = "TICK-CLICK-NEXT-THANKS-PRODUCTS",
  "ClickOnTicketingButtonInTicketingThanksProducts" = "TICK-CLICK-TICKETING-THANKS-PRODUCTS",
  "ClickOnDonationButtonInTicketingThanksProducts" = "TICK-CLICK-DONATION-THANKS-PRODUCTS",
  "ClickOnNextButtonInTicketingThanksMessage" = "TICK-CLICK-NEXT-THANKS-MESSAGE",
  "ClickOnCopyLinkInReferrer" = "DON-CLICK-COPY-LINK-REFERRRER",
  "ClickOnSimplykHomePage" = "DON-CLICK-SIMPLYK-HOMEPAGE-REFERRER",
  "PostEventThankYouEmailDisplayed" = "POST_EVENT_THANK_YOU_EMAIL_DISPLAYED",
  "EligibilityCountryModalClicked" = "Eligibility Country Modal Clicked",
  "EligibilityCountryModalViewed" = "Eligibility Country Modal Viewed",
  /**
   * @description Event is fired anytime someone make an order that succeed
   * (including : normal order[cheque - transfer - CB] - manual order[dashboard] - free order)
   * @template TicketingConversion
   */
  "SuccessAnyOrder" = "TICK-SUCCESS-ANY-ORDER",

  /**
   * @description Event is fired anytime someone make an order by CB, with positive amount that succeed
   * (including : normal order [CB])
   */
  "SuccessOrder" = "TICK-SUCCESS-ORDER",

  /**
   * @description Event is fired anytime someone make an order without payment method, because of 0$ amount that succeed
   * (including : free order)
   */
  "SuccessFreeOrder" = "TICK-FREE-SUCCESS-ORDER",

  /**
   * @description Event is fired anytime someone make an order by transfer, with positive amount that succeed
   * (including : normal order [transfer])
   */
  "SuccessOrderByTransfer" = "TICK-SUCCESS-ORDER-BY-TRANSFER",

  /**
   * @description Event is fired anytime someone make an order by cheque, with positive amount that succeed
   * (including : normal order [cheque])
   */
  "SuccessOrderSubscription" = "TICK-SUCCESS-SUBSCRIPTION",
  "SuccessOrderByCheque" = "TICK-SUCCESS-ORDER-BY-CHEQUE",
  "OpenTicketingTicketDrawer" = "TICK-OPEN-TICKET-DRAWER",
  "OpenTicketingOrderDrawer" = "TICK-OPEN-ORDER-DRAWER",
  "DonorFormStepSubmitted" = "Donor Form Step Submitted",
  "ValidateBuyerFormInTicketing" = "TICK-VALIDATE-BUYER-FORM",
  "FailOrderOnFullTicketing" = "TICK-FAIL-ORDER-FULL-TICKETING",
  "FailOrderOnFullTicketingByTransfer" = "TICK-FAIL-ORDER-FULL-TICKETING-BY-TRANSFER",
  "FailOrderOnFullTicketingByCheque" = "TICK-FAIL-ORDER-FULL-TICKETING-BY_CHEQUE",
  "ConfirmFreePayment" = "PAY-CONFIRM-FREE-PAYMENT",
  "FailOrderOnFullTicketingFreeOrder" = "TICK-FAIL-ORDER-FULL-TICKETING-FREE_ORDER",
  "OpenPadStripeModal" = "PAY-OPEN-PAD-STRIPE-MODAL",
  "SuccessPadStripeModal" = "PAY-SUCCESS-PAD-STRIPE-MODAL",
  "SuccessAchStripeModal" = "PAY-SUCCESS-ACH-STRIPE-MODAL",
  "FailedAchStripeConfirmation" = "PAY-FAILED-ACH-STRIPE-CONFIRMATION",
  "FailedPadStripeModal" = "PAY-FAILED-PAD-STRIPE-MODAL",
  "FailedAchStripeModal" = "PAY-FAILED-ACH-STRIPE-MODAL",
  "CloseAchStripeModal" = "CLOSE-ACH-STRIPE-MODAL",
  "OpenFormOnFundraisingParticipation" = "OPEN-FORM-ON-FUNDRAISING-PARTICIPATION",
  "EditFormOnFundraisingParticipation" = "EDIT-FORM-ON-FUNDRAISING-PARTICIPATION",
  // Dashboard
  "LoginOnV2" = "ORG-LOGIN-ON-V2",
  "ClickOnBankDetails" = "PAYOUT_DETAILS-CLICK-BANK_DETAILS",
  "OpenDonationFormEdition" = "ORG-DF-EDITION",
  "OpenDonationFormShare" = "ORG-DF-SHARE",
  "OpenDonationForm" = "ORG-DF-OPEN",
  "OpenTicketingEdition" = "ORG-TICK-EDITION",
  "OpenTicketingShare" = "ORG-TICK-SHARE",
  "OpenTicketing" = "ORG-TICK-OPEN",
  "SubmitAdministrativesInformations" = "ORG-NEW-DF-ADMINISTRATIVES_INFORMATIONS-SUBMIT",
  "SubmitAdvancedParameters" = "ORG-NEW-DF-ADVANCED_PARAMETERS-SUBMIT",
  "SubmitFormCustomization" = "ORG-NEW-DF-FORM_CUSTOMIZATION-SUBMIT",
  "SubmitFormCustomizationOnEdit" = "ORG-EDIT-DF-FORM_CUSTOMIZATION-SUBMIT",
  "OpenAdministrativesInformations" = "ORG-NEW-DF-ADMINISTRATIVES_INFORMATIONS-OPEN",
  "SubmitGeneralInformations" = "ORG-NEW-DF-GENERAL_INFORMATIONS-SUBMIT",
  "SubmitGeneralInformationsOnEdit" = "ORG-EDIT-DF-GENERAL_INFORMATIONS-SUBMIT",
  "ClickOnBankAccountShareOnDonationForm" = "ORG-NEW-DF-SHARE-CLICK_ON_BANK_ACCOUNT",
  "ClickOnBankAccountShareOnTicketingForm" = "ORG-NEW-TICK-SHARE-CLICK_ON_BANK_ACCOUNT",
  "SubmitTranslations" = "ORG-NEW-DF-TRANSLATIONS-SUBMIT",
  "SubmitGeneralInformationsTicketing" = "ORG-NEW-TICKETING-GENERAL_INFORMATIONS-SUBMIT",
  "SubmitRatesTicketing" = "ORG-NEW-TICKETING-RATES-SUBMIT",
  "SubmitEventCustomizationTicketing" = "ORG-NEW-TICKETING-EVENT_CUSTOMIZATION-SUBMIT",
  "SubmitAdvancedParametersTicketing" = "ORG-NEW-TICKETING-PAYMENT_PARAMETERS-SUBMIT",
  "ClickOnKnowMoreConversionRateOnSavedFeeInfoPage" = "ORG-SAVED-FEE-INFO-KNOW_MORE-CONVERSION_RATE-CLICK",
  "ClickOnKnowMoreFeesOnSavedFeeInfoPage" = "ORG-SAVED-FEE-INFO-KNOW_MORE-FEES-CLICK",
  "CopyLinkInSavedFeeInfoPage" = "ORG-SAVED-FEE-INFO-PAGE-COPY-LINK",
  "ClickOnKnowMoreConversionRateOnSavedFeeCongratulationPage" = "ORG-SAVED-FEE-CONGRATS-KNOW_MORE-CONVERSION_RATE-CLICK",
  "ClickOnKnowMoreFeesOnSavedFeeCongratulationPage" = "ORG-SAVED-FEE-CONGRATS-KNOW_MORE-FEES-CLICK",
  "OpenSavedFeeCongratulationPage" = "ORG-SAVED-FEE-CONGRATS-PAGE-MOUNT",
  "CopyLinkInSavedFeeCongratulationPage" = "ORG-SAVED-FEE-CONGRATS-PAGE-COPY-LINK",
  "SubmitTicketingTranslations" = "ORG-NEW-TICK-TRANSLATIONS-SUBMIT",
  "FailOrderOnFullTicketingManualDonation" = "TICK-FAIL-ORDER-FULL-TICKETING-MANUAL-DONATION",
  "SuccessOrderManualDonation" = "TICK-SUCCESS-ORDER-MANUAL-DONATION",
  "ClickOnUploadSignature" = "TICK-SHARE-CLICK_ON_UPLOAD_SIGNATURE",
  "OpenMenuDonations" = "MENU-OPEN-DONATIONS",
  "OpenMenTickets" = "MENU-OPEN-TICKETS",
  "OpenSubMenuDonations" = "SUB-MENU-OPEN-DONATIONS",
  "OpenSubMenuDonationForms" = "SUB-MENU-OPEN-DONATION-FORMS",
  "OpenSubMenuTicketingForm" = "SUB-MENU-OPEN-TICKETING-FORMS",
  "OpenSubMenuTickets" = "SUB-MENU-OPEN-TICKETS",
  "OpenSubMenuBank" = "SUB-MENU-OPEN-BANK",
  "OpenSubMenuPayouts" = "SUB-MENU-OPEN-PAYOUTS",
  "OpenSubMenuTransactions" = "SUB-MENU-OPEN-TRANSACTIONS",
  "OpenSubMenuBalance" = "SUB-MENU-OPEN-BALANCE",
  "OpenMenuReceipts" = "MENU-OPEN-RECEIPTS",
  "MenuHover" = "MENU_HOVER",
  "MenuClickCreateYourFirstForm" = "MENU_CLICK_CREATE-YOUR-FIRST-FORM",
  "BankSetupStarted" = "Bank Setup Started",
  "MenuClickAcceptPayments" = "MENU_CLICK_ACCEPT-YOUR-PAYMENTS",
  "MenuClickReceivePayouts" = "MENU_CLICK_RECEIVE-YOUR-FIRST-PAYOUT",
  "MenuClickAttendDemo" = "MENU-CLICK-ATTEND_DEMO",
  "OpenSubMenuReferral" = "NAVBAR-CLICK-REFERRALS",
  "OpenSubMenuFundraising" = "NAVBAR-CLICK-FUNDRAISING",
  "OpenSubMenuForms" = "NAVBAR-CLICK-FORMS",
  "OpenSubMenuHomePage" = "NAVBAR-CLICK-HOME-PAGE",
  "OpenSubMenuPayments" = "NAVBAR-CLICK-PAYMENTS",
  "GenerateTicketsExport" = "TICKETS-GENERATE-EXPORTS",
  "GenerateDonationsExport" = "DONATIONS-GENERATE-EXPORTS",
  "OpenManualFromVolume" = "MANUAL-OPEN-MODAL-FROM-VOLUME",
  "SaveManualDonation" = "MANUAL-DONATION-SUBMIT",
  "OpenManualOrder" = "MANUAL-ORDER-OPEN-MODAL",
  "OpenManualCommandFromFormsTable" = "MANUAL-COMMAND-OPEN-DRAWER-FROM-FORM-TABLE",
  "SaveManualOrder" = "MANUAL-ORDER-SUBMIT",
  "OpenManualContact" = "MANUAL-CONTACT-OPEN-MODAL",
  "SaveManualContact" = "MANUAL-CONTACT-SUBMIT",
  "SendTestEmailInModal" = "SEND-EMAIL-TEST-IN-MODAL",
  "SendEmailToParticipantInModal" = "SEND-EMAIL-TO-PARTICIPANT-IN-MODAL",
  "OpenSendEmailModalFromTicketTable" = "OPEN-SEND-EMAIL-MODAL-FROM-TICKET-TABLE",
  "OpenSendEmailModalFromContactTable" = "OPEN-SEND-EMAIL-MODAL-FROM-CONTACT-TABLE",
  "OpenSendEmailModalFromUserBoard" = "OPEN-SEND-EMAIL-MODAL-FROM-USER-BOARD",
  "OpenModalPickDonationFormForMagicalLink" = "OPEN-MODAL-MAGICAL-LINK-PICK-DONATION-FORM",
  "OpenPageGenerateReceiptFromUserReceiptTable" = "OPEN-RECEIPT-PAGE-FROM-USER-RECEIPT-TABLE",
  "ExportContactList" = "CONTACT-LIST-EXPORT",
  "FilterContactList" = "CONTACT-LIST-FILTER",
  "ExportPaymentList" = "PAYMENT-LIST-EXPORT",
  "FilterPaymentList" = "PAYMENT-LIST-FILTER",
  "ExportEventGuestList" = "EVENT-GUEST-LIST-EXPORT",
  "PaymentListFocusSearch" = "PAYMENT_LIST-FOCUS-SEARCH",
  "ContactListFocusSearch" = "CONTACT_LIST-FOCUS-SEARCH",
  "FormListFocusSearch" = "FORM_LIST-FOCUS-SEARCH",
  "PaymentListApplyDateRange" = "PAYMENT_LIST-APPLY-DATE_RANGE",
  "PaymentListFocusDateRange" = "PAYMENT_LIST-FOCUS-DATE_RANGE",
  "PaymentListApplyFormList" = "PAYMENT_LIST-APPLY-FORM_LIST",
  "PaymentListFocusFormList" = "PAYMENT_LIST-FOCUS-FORM_LIST",
  "PaymentListApplyEventDate" = "PAYMENT_LIST-APPLY-EVENT_DATE",
  "FormListFocusDateRange" = "FORM_LIST-FOCUS-DATE_RANGE",
  "FormListApplyDateRange" = "FORM_LIST-APPLY-DATE_RANGE",
  "PaymentListFocusEventDate" = "PAYMENT_LIST-FOCUS-EVENT_DATE",
  "HomePageFocusFormFilter" = "HOME-PAGE-FOCUS-FORM_LIST",
  "HomePageApplyFormFilter" = "HOME-PAGE-LIST-FORM_LIST",
  "HomePageFocusDateFilter" = "HOME-PAGE-FOCUS-DATE_RANGE",
  "HomePageApplyDateFilter" = "HOME-PAGE-LIST-DATE_RANGE",
  "HomePageClickExport" = "HOME-PAGE-CLICK-EXPORT",
  "HomePageClickFrequency" = "HOME-PAGE-CLICK-FREQUENCY",
  "HomePageClickSuggestedFollowUp" = "HOME-CLICK-SUGGESTED_FOLLOW_UP-VIEW_ALL",
  "OpenDrawerDetailOrder" = "OPEN-DRAWER-DETAIL-ORDER",
  "CopyMagicalLinkOnVisitorPage" = "COPY-MAGICAL-LINK-ON-VISITOR-PAGE",
  "OpenEmailModalOnVisitorPage" = "OPEN-EMAIL-MODAL-ON-VISITOR-PAGE",
  "OpenEmailModalOnHomePage" = "OPEN-EMAIL-MODAL-ON-HOME-PAGE",
  "OpenEmailModalOnContactPage" = "OPEN-EMAIL-MODAL-ON-CONTACT-PAGE",
  "OpenManualOrderDrawer" = "OPEN-MANUAL-ORDER-DRAWER",
  "OpenDrawerDetailDonation" = "OPEN-DRAWER-DETAIL-DONATION",
  "SignInPageSwitchToSignUp" = "SIGN-IN-PAGE-SWITCH-TO-SIGN-UP",
  "SignInCheckEmail" = "SIGN-IN-CHECK-EMAIL",
  "SignInUserIsUnknown" = "SIGN-IN-USER-IS-UNKNOWN",
  "SignInUserIsRegistered" = "SIGN-IN-USER-IS-REGISTERED",
  "SignInUserIsFirstConnectedDonor" = "SIGN-IN-USER-IS-FIRST-CONNECTED-DONOR",
  "SignInUserIsFirstConnectedUserAfterDonationWithoutV1Password" = "SIGN-IN-USER-IS-FIRST-CONNECTED-USER-AFTER-DONATION-WITHOUT-V1-PASSWORD",
  "SignInUserIsFirstConnectedUserAfterDonationWithV1Password" = "SIGN-IN-USER-IS-FIRST-CONNECTED-USER-AFTER-DONATION-WITH-V1-PASSWORD",
  "SignInTryConnection" = "SIGN-IN-TRY-CONNECTION",
  "SignInConnectionSuccessful" = "SIGN-IN-CONNECTION-SUCCESSFUL",
  "SignInConnectionFailed" = "SIGN-IN-CONNECTION-FAILED",
  "SignInSendResetPasswordRequest" = "SIGN-IN-SEND-RESET-PASSWORD-REQUEST",
  "SignInSendResetPasswordRequestAgain" = "SIGN-IN-SEND-RESET-PASSWORD-REQUEST-AGAIN",
  "SignInSendResetPasswordOnDonorFirstConnection" = "SIGN-IN-SEND-RESET-PASSWORD-ON-DONOR-FIRST-CONNECTION",
  "SignInSendResetPasswordOnUserMigrated" = "SIGN-IN-SEND-RESET-PASSWORD-ON-USER-MIGRATED",
  "SignUpFailed" = "SIGN-UP-FAILED",
  "SignUpClickLogin" = "SIGN_UP-CLICK-LOGIN",
  "SignUpBackLanding" = "SIGN_UP-BACK-LANDING",
  "SignUpClickCapterraReviews" = "SIGN_UP-CLICK-CAPTERRA_REVIEWS",
  "SignUpFocusPhone" = "SIGN_UP-FOCUS-PHONE",
  "SignUpFocusOrgName" = "SIGN_UP-FOCUS-ORGNAME",
  "SignUpClickSearchAddEinManually" = "SIGN_UP-CLICK-SEARCH-ADD_EIN_MANUALLY",
  "AdministrativeInformationClickEditEin" = "ADMINISTRATIVE_INFORMATION-CLICK-EDIT_EIN",
  "AdministrativeInformationClickEditAddress" = "ADMINISTRATIVE_INFORMATION-CLICK-EDIT_ADDRESS",
  "SignUpFocusWebsite" = "SIGN_UP-FOCUS-WEBSITE",
  "SignUpClickCountry" = "SIGN_UP-CLICK-COUNTRY",
  "SignUpClickShowPassword" = "SIGN_UP-CLICK-SHOW_PASSWORD",
  "OrganizationSignUpInputChanged" = "Organization Sign Up Input Changed",
  "OrganizationSignUpPageViewed" = "Organization Sign Up Page Viewed",
  "OrganizationSignUpSubmitted" = "Organization Sign Up Submitted",
  "SignUpCompleted" = "Sign Up Completed",
  "ResetPasswordTry" = "RESET-PASSWORD-TRY",
  "ResetPasswordFailed" = "RESET-PASSWORD-FAILED",
  "ResetPasswordSuccessful" = "RESET-PASSWORD-SUCCESSFUL",
  "ResetPasswordTryAgain" = "RESET-PASSWORD-TRY-AGAIN",
  "DashboardClickHelp" = "Help Center Viewed",
  "DashboardClickFeatureRequest" = "Feature Request Started",
  "DashboardOpenSettingSelect" = "DASHBOARD-OPEN-SETTING_SELECT",
  "DashboardClickSettings" = "DASHBOARD-CLICK-SETTING",
  "DashboardClickInviteUsers" = "DASHBOARD-CLICK-INVITE_USERS",
  "DashboardClickSwitchProfiles" = "DASHBOARD-CLICK-SWITCH_PROFILE",
  "DashboardClickCreateNewOrganization" = "DASHBOARD-CLICK-CREATE_A_NEW_ORGANIZATION",
  "SettingsClickCreateNewOrganization" = "SETTINGS-CLICK_CREATE_A_NEW_ORGANIZATION",
  "SettingsClickMyPersonalInformation" = "SETTINGS-CLICK-MY_PERSONAL_INFO",
  "SettingsClickChangeMyPassword" = "SETTINGS-CLICK-CHANGE_MY_PASSWORD",
  "SettingsClickOrganizationInformation" = "SETTINGS-CLICK-ORGANIZATION_INFORMATION",
  "SettingsClickOrganizationLogo" = "SETTINGS-CLICK-ORGNIZATION_LOGO",
  "SettingsClickUsersManagement" = "SETTINGS-CLICK-USERS_MANAGEMENT",
  "SettingsClickBankIdentity" = "SETTINGS-CLICK-BANK_IDENTITY",
  "SettingsClickBankAccount" = "SETTINGS-CLICK-BANK_ACCOUNT",
  "SettingsClickPayoutSchedule" = "SETTINGS-CLICK-PAYOUT_SCHEDULE",
  "SettingsClickAddMyAccountant" = "SETTINGS-CLICK-ADD_MY_ACCOUNTANT",
  "BankClickBankSettings" = "BANK-CLICK-BANK_SETTING",
  "ClickOnMyReferrals" = "REFER-CLICK-MY_REFERRALS",
  "ClickOnMyRefer" = "REFERRAL-CLICK-REFER",
  "ClickLearnMoreReferralSignUp" = "REFERRAL_SIGN_UP-CLICK-LEARN_MORE",
  "LearnMoreSignUpReferralClickOnOutStory" = "LEARN_MORE_SIGN_UP_REFERRAL-CLICK-OUR_STORY",
  "LearnMoreSignUpReferralClickOnHowWeMakeMoney" = "LEARN_MORE_SIGN_UP_REFERRAL-CLICK-HOW_MAKE_MONEY",
  "LearnMoreSignUpReferralClickOnOurGoal" = "LEARN_MORE_SIGN_UP_REFERRAL-CLICK-OUR_GOALS",
  "LearnMoreSignUpClickOnOutStory" = "LEARN_MORE_SIGN_UP-CLICK-OUR_STORY",
  "LearnMoreSignUpClickOnHowWeMakeMoney" = "LEARN_MORE_SIGN_UP-CLICK-HOW_MAKE_MONEY",
  "LearnMoreSignUpClickOnOurGoal" = "LEARN_MORE_SIGN_UP-CLICK-OUR_GOALS",
  "LearnMoreTipClickOnOutStory" = "LEARN_MORE_TIPS-CLICK-OUR_STORY",
  "LearnMoreTipClickOnHowContributionHelp" = "LEARN_MORE_TIPS-CLICK-HOW_CONTRIBUTION_HELP",
  "LearnMoreTipClickOnHowWeMakeMoney" = "LEARN_MORE_TIPS-CLICK-HOW_MAKE_MONEY",
  "LearnMoreTipClickOnOurGoal" = "LEARN_MORE_TIPS-CLICK-OUR_GOALS",
  "ClickOnGenerateReceiptPage" = " RECEIPTS_PAGE-CLICK-NEW_RECEIPT",
  "BankingInformationClickSkipForNow" = "BANKING_INFORMATION-CLICK-SKIP_FOR_NOW",
  "BankSetupStepSubmitted" = "Bank Setup Step Submitted",
  "BankSetupInputChanged" = "Bank Setup Input Changed",
  "BankSetupPageFAQExpanded" = "Bank Setup Page FAQ Expanded",
  "BankSetupPageViewed" = "Bank Setup Page Viewed",
  "BankSetupBannerClicked" = "Bank Setup Banner Clicked",
  "BankSetupOrganizationInfo1Viewed" = "Bank Setup Organization Info 1 Viewed",
  "BankSetupOrganizationInfo2Viewed" = "Bank Setup Organization Info 2 Viewed",
  "BankSetupBankingInfo1Viewed" = "Bank Setup Banking Info 1 Viewed",
  "DonorClickDashboard" = "DONOR-CLICK-DASHBOARD",
  "DonorClickCreateNewOrganization" = "DONOR-CLICK-CREATE_NEW_ORGANIZATION",
  "DonorClickSwitchProfile" = "DONOR-CLICK-SWITCH_PROFILE",
  "ClickOnActivesDropdown" = "DONATION_FORMS-CLICK-ACTIVES_DROPDOWN",
  "ClickOnArchiveDropdown" = "DONATION_FORMS-CLICK-ARCHIVES_DROPDOWN",
  "SharePageCopyFormLink" = "SHARE_PAGE-COPY-FORM_LINK",
  "SharePageP2PCopyIndividualCampaignLink" = "SHARE_PAGE_P2P-COPY-INDIVIDUAL_CAMPAIGN_LINK",
  "SharePageP2PCopyTeamLink" = "SHARE_PAGE_P2P-COPY-TEAM_LINK",
  "SharePageCopyFormEmbedLink" = "SHARE_PAGE-COPY-FORM_EMBED_LINK",
  "SharePageCopyFormEmbedPopupLink" = "SHARE_PAGE-COPY-FORM_EMBED_POPUP_LINK",
  "SharePageCopyFormEmbedPopupScript" = "SHARE_PAGE-COPY-FORM_EMBED_POPUP_SCRIPT",
  "SharePageCopyThermometerLink" = "SHARE_PAGE-COPY-THERMOMETER_LINK",
  "SharePageCopyLeaderboardLink" = "SHARE_PAGE-COPY-LEADERBOARD_LINK",
  "SharePageCopyQRCode" = "SHARE_PAGE-COPY-QR_CODE",
  "SharePageClickCompose" = "SHARE_PAGE-CLICK-COMPOSE",
  "SharePageClickOpenTapToPay" = "SHARE_PAGE-CLICK-OPEN_TAP_TO_PAY",
  "SharePageClickOpenEmbed" = "SHARE_PAGE-CLICK-OPEN_EMBED",
  "SharePageClickOpenEmbedPopup" = "SHARE_PAGE-CLICK-OPEN_EMBED-POP-UP",
  "SharePageClickOpenQrCode" = "SHARE_PAGE-CLICK-OPEN_QR-CODE",
  "ThreeServicesClickCreateDonation" = "THREE_SERVICES-CLICK-CREATE_DONATION",
  "ThreeServicesClickCreateTicketing" = "THREE_SERVICES-CLICK-CREATE_TICKETING",
  "ThreeServicesClickLogo" = "THREE_SERVICES-CLICK-LOGO",
  "GeneralInformationClickDonationCampaign" = "GENERAL_INFORMATION-CLICK-DONATION_CAMPAIGN",
  "GeneralInformationClickPeerToPeer" = "GENERAL_INFORMATION-CLICK-PEER_TO_PEER",
  "GeneralInformationClickEvent" = "GENERAL_INFORMATION-CLICK-EVENT",
  "GeneralInformationClickMembership" = "GENERAL_INFORMATION-CLICK-MEMBERSHIP",
  "GeneralInformationClickLottery" = "GENERAL_INFORMATION-CLICK-LOTTERY",
  "GeneralInformationClickECommerce" = "GENERAL_INFORMATION-CLICK-E_COMMERCE",
  "GeneralInformationClickCustom" = "GENERAL_INFORMATION-CLICK-CUSTOM_FORM",
  "FormInputChanged" = "Form Input Changed",
  "GeneralInformationFocusTarget" = "GENERAL_INFORMATION-FOCUS-TARGET",
  "FormEventDateAdded" = "Form Event Date Added",
  "FormEventRecurrenceChanged" = "Form Event Recurrence Changed",
  "GeneralInformationClickAuction" = "GENERAL_INFORMATION-CLICK-AUCTION",
  "GeneralInformationClickAddATarget" = "GENERAL_INFORMATION-CLICK-ADD_A_TARGET",
  "CustomizationClickOneTimeSwitch" = "CUSTOMIZATION-CLICK-ONE_TIME_SWITCH",
  "CustomizationClickMonthlySwitch" = "CUSTOMIZATION-CLICK-MONTHLY_SWITCH",
  "CustomizationClickYearlySwitch" = "CUSTOMIZATION-CLICK-YEARLY_SWITCH",
  "CustomizationFocusAmount" = "CUSTOMIZATION-FOCUS-AMOUNT",
  "CustomizationFocusAmountDescription" = "CUSTOMIZATION-FOCUS-AMOUNT_DESCRIPTION",
  "CustomizationClickGenerateReceipts" = "CUSTOMIZATION-CLICK-GENERATE_RECEIPTS",
  "CustomizationClickFormColor" = "CUSTOMIZATION-CLICK-FORM_COLOR",
  "CustomizationClickFormBanner" = "CUSTOMIZATION-CLICK-FORM_BANNER",
  "CustomizationClickOrgLogo" = "CUSTOMIZATION-CLICK-LOGO_ORGANIZATION",
  "CustomizationFocusYoutubeVideo" = "CUSTOMIZATION-FOCUS-YOUTUBE_VIDEO",
  "CustomizationClickAddQuestion" = "CUSTOMIZATION-CLICK-ADD_QUESTION",
  "CustomizationFocusLoserEmailSubject" = "CUSTOMIZATION-FOCUS-LOSER-EMAIL_SUBJECT",
  "CustomizationFocusLoserEmailDescription" = "CUSTOMIZATION-FOCUS-LOSER-EMAIL_DESCRIPTION",
  "CustomizationFocusCampaignEmailDescription" = "CUSTOMIZATION-FOCUS-CAMPAIGN_EMAIL_DESCRIPTION",
  "CustomizationFocusThankYouMessage" = "CUSTOMIZATION-FOCUS-THANK_YOU_MESSAGE",
  "CustomizationClickGenerateETicket" = "CUSTOMIZATION-CLICK-GENERATE_E_TICKETS",
  "CustomizationClickTicketBanner" = "CUSTOMIZATION-CLICK-TICKET_BANNER",
  "TicketsFocusBidIncrement" = "TICKETS-FOCUS-BID_INCREMENT",
  "TicketsClickTicketOptions" = "TICKETS-CLICK-TICKET_OPTIONS",
  "TicketsFocusTicketNumber" = "TICKETS-FOCUS-TICKET_NUMBER",
  "TicketsFocusMinimumNumber" = "TICKETS-FOCUS-TICKET_MINIMUM_NUMBER",
  "TicketsFocusMaximumNumber" = "TICKETS-FOCUS-TICKET_MAXIMUM_NUMBER",
  "TicketsClickGenerateReceipts" = "TICKETS-CLICK-GENERATE_RECEIPTS",
  "TicketsClickGroupTicket" = "TICKETS-CLICK-GROUP_TICKET",
  "FormTicketAdded" = "Form Ticket Added",
  "FormBuyerQuestionAdded" = "Form Buyer Question Added",
  "FormTicketQuestionAdded" = "Form Ticket Question Added",
  "TicketsClickChangeTicketQuestionType" = "TICKETS-CLICK-CHANGE_TICKET_QUESTION_TYPE",
  "TicketsClickChangeBuyerQuestionType" = "TICKETS-CLICK-CHANGE_BUYER_QUESTION_TYPE",
  "TicketsClickRateImage" = "TICKETS-CLICK-RATE_IMAGE",
  "FormExtraDonationAdded" = "Form Extra Donation Added",
  "TicketsClickQuestionMaximumChoice" = "TICKETS-CLICK-QUESTION_MAXIMUM_CHOICE",
  "TicketsClickEarlyBird" = "TICKETS-CLICK-EARLY_BIRD",
  "TicketsClickPreviewReceipt" = "TICKETS-CLICK-PREVIEW_RECEIPT",
  "FormPreviewClickNext" = "FORM_PREVIEW-CLICK-NAVBAR_NEXT",
  "FormSaveModalViewed" = "Form Save Modal Viewed",
  "FormSaveModalDiscarded" = "Form Save Modal Discarded",
  "FormSaveModalSaved" = "Form Save Modal Saved",
  "DonorFormDescriptionOpened" = "Donor Form Description Opened",
  "DonorFormDonationChanged" = "Donor Form Donation Changed",
  "DonationFocusGeneralInput" = "DONATION-FOCUS-GENERAL_INPUT",
  "ManualDonationDrawerCheckInHonour" = "MANUAL_DONATION_DRAWER-CHECK-IN_HONOUR",
  "ManualDonationDrawerFocusPaymentMethod" = "MANUAL_DONATION_DRAWER-FOCUS-PAYMENT_METHOD",
  "ManualDonationDrawerSelectPaymentMethod" = "MANUAL_DONATION_DRAWER-SELECT-PAYMENT_METHOD",
  "ManualOrderDrawerFocusPaymentMethod" = "MANUAL_ORDER_DRAWER-FOCUS-PAYMENT_METHOD",
  "ManualOrderDrawerClickDiscountCode" = "MANUAL_ORDER_DRAWER-CLICK-DISCOUNT_CODE",
  "ManualOrderDrawerSelectPaymentMethod" = "MANUAL_ORDER_DRAWER-SELECT-PAYMENT_METHOD",
  "DonorFormInputChanged" = "Donor Form Input Changed",
  "DonorFormTicketsCountChanged" = "Donor Form Tickets Count Changed",
  "DonorFormExtraDonationChanged" = "Donor Form Extra Donation Changed",
  "DonorFormDiscountCodeChecked" = "Donor Form Discount Code Checked",
  "TicketClickGeneralInput" = "TICKET-CLICK-GENERAL_INPUT",
  "TicketClickDateSelect" = "TICKET-CLICK-DATE_SELECT",
  "FormFocusCustomInput" = "FORM-FOCUS-CUSTOM_INPUT",
  "FormClickCustomInput" = "FORM-CLICK-CUSTOM_INPUT",
  "FormClickTipDropdown" = "Donor Form Tip Dropdown Opened",
  "DonorFormTipChanged" = "Donor Form Tip Changed",
  "FormClickTipKnowMore" = "FORM-CLICK-TIP_KNOW_MORE",
  "DonorFormPaymentMethodChanged" = "Donor Form Payment Method Changed",
  "DonorFormSubmitted" = "Donor Form Submitted",
  "FormClickShowOtherPaymentMethods" = "FORM-CLICK-SHOW_OTHER_PAYMENT_METHODS",
  "FormFocusCardInput" = "FORM-FOCUS-CARD_INPUT",
  "PaymentListNewMessage" = "PAYMENT_LIST-CLICK-NEW_MESSAGE",
  "ContactListTableMessage" = "CONTACT_LIST_TABLE-CLICK-MESSAGE",
  "EmailEditorClickClose" = "EMAIL_EDITOR-CLICK-CLOSE",
  "EmailEditorClickContactList" = "EMAIL_EDITOR-CLICK-CONTACT_LIST",
  "EmailEditorAddVariableClicked" = "Email Editor Add Variable Clicked",
  "EmailEditorClickAddTemplate" = "EMAIL_EDITOR-CLICK-ADD_TEMPLATE",
  "EmailEditorClickAddAttachment" = "EMAIL_EDITOR-CLICK-ADD_ATTACHMENT",
  "EmailEditorTemplateClickNew" = "EMAIL_EDITOR_TEMPLATE-CLICK-NEW",
  "EmailEditorTemplateClickSave" = "EMAIL_EDITOR_TEMPLATE-CLICK-SAVE",
  "EmailEditorTemplateClickSaveAsNew" = "EMAIL_EDITOR_TEMPLATE-CLICK-SAVE_AS_NEW",
  "EmailEditorTemplateClickDelete" = "EMAIL_EDITOR_TEMPLATE-CLICK-DELETE",
  "EmailEditorTemplateClickSelectATemplate" = "EMAIL_EDITOR_TEMPLATE-CLICK-SELECT_A_TEMPLATE",
  "EmailEditorClickTest" = "EMAIL_EDITOR-CLICK-TEST",
  "EmailEditorClickSave" = "EMAIL_EDITOR-CLICK-SAVE",
  "EmailEditorPrefillTextClicked" = "Email Editor Prefill Text Clicked",
  "EmailSendConfirmationClickCancel" = "EMAIL_SEND_CONFIRMATION-CLICK-CANCEL",
  "EmailSent" = "Email Sent",
  "CopyEmailInfoboxCloseClicked" = "Copy Email Infobox Close Clicked",
  "CopyEmailInfoboxDismissClicked" = "Copy Email Infobox Dismiss Clicked",
  "CopyEmailInfoboxOpenEmailingClicked" = "Copy Email Infobox OpenEmailing Clicked",
  "ContactPageClickNewMessage" = "CONTACT_PAGE-CLICK-NEW_MESSAGE",
  "VisitorListClickNewMessage" = "VISITOR_LIST-CLICK-NEW_MESSAGE",
  "VisitorListTableClickNewMessage" = "VISITOR_LIST_TABLE-CLICK-NEW_MESSAGE",
  "VisitorListTableClickStatusFilter" = "VISITOR_LIST-CLICK-STATUS_FILTER",
  "TicketsListClickNewMessage" = "TICKETS_LIST-CLICK-NEW_MESSAGE",
  "VariableListClickDate" = "VARIABLE_LIST-CLICK-DATE",
  "VariableListClickLocation" = "VARIABLE_LIST-CLICK-LOCATION",
  "EmailEditorLinkVariableAdded" = "Email Editor Link Variable Added",
  "VariableListClickMagicalLink" = "VARIABLE_LIST-CLICK-MAGICAL_LINK",
  "VariableListClickETicketUrl" = "VARIABLE_LIST-CLICK-E_TICKET_URL",
  "ContactSelectorClickClose" = "CONTACT_SELECTOR-CLICK-CLOSE",
  "ContactSelectorClickOpen" = "CONTACT_SELECTOR-CLICK-OPEN",
  "ContactSelectorClickSelect" = "CONTACT_SELECTOR-CLICK-SELECT",
  "ContactSelectorEmailEditorClickFilter" = "CONTACT_SELECTOR_EMAIL_EDITOR-CLICK-FILTER",
  "ContactListMessageAll" = "CONTACT_LIST-MESSAGE-ALL",
  "ContactListClickNewContact" = "CONTACT_LIST-CLICK-NEW_CONTACT",
  "ContactListSelectContact" = "CONTACT_LIST-SELECT-CONTACT",
  "ContactListUnSelectContact" = "CONTACT_LIST-UNSELECT-CONTACT",
  "ContactListBulkSelectContact" = "CONTACT_LIST-BULK_SELECT-CONTACT",
  "ContactListBulkUnSelectContact" = "CONTACT_LIST-BULK_UNSELECT-CONTACT",
  "ContactListBulkExport" = "CONTACT_LIST-BULK-EXPORT",
  "ContactListBulkTag" = "CONTACT_LIST-BULK-TAG",
  "ContactListBulkMessage" = "CONTACT_LIST-BULK-MESSAGE",
  "ContactListAddTagClicked" = "CONTACT_LIST-ADD-TAG-CLICKED",
  "EventGuestListBulkExport" = "EVENT_GUEST_LIST-BULK-EXPORT",
  "EventGuestListBulkMessage" = "EVENT_GUEST_LIST-BULK-MESSAGE",
  "EventGuestListTableMessage" = "EVENT_GUEST_LIST_TABLE-CLICK-MESSAGE",
  "EventGuestListClickOpenBulkCheckIn" = "EVENT_GUEST_LIST-CLICK-OPEN-BULK-CHECK_IN",
  "EventGuestListBulkCheckInCommandTickets" = "EVENT_GUEST_LIST-BULK-CHECK_IN_COMMAND_TICKETS",
  "EventGuestListClickCancelCommandTickets" = "EVENT_GUEST_LIST-CLICK-CANCEL_COMMAND_TICKETS",
  "EventGuestListSubmitCancelCommandTickets" = "EVENT_GUEST_LIST-SUBMIT-CANCEL_COMMAND_TICKETS",
  "EventGuestListClickCheckInCommandTickets" = "EVENT_GUEST_LIST-CLICK-CHECK_IN_COMMAND_TICKETS",
  "EventGuestListSubmitCheckInCommandTickets" = "EVENT_GUEST_LIST-SUBMIT-CHECK_IN_COMMAND_TICKETS",
  "EventGuestListClickUncheckInCommandTickets" = "EVENT_GUEST_LIST-CLICK-UNCHECK_IN_COMMAND_TICKETS",
  "EventGuestListSubmitUncheckInCommandTickets" = "EVENT_GUEST_LIST-SUBMIT-UNCHECK_IN_COMMAND_TICKETS",
  "EventGuestListClickCancelTicket" = "EVENT_GUEST_LIST-CLICK-CANCEL_TICKET",
  "EventGuestListSubmitCancelTicket" = "EVENT_GUEST_LIST-SUBMIT-CANCEL_TICKET",
  "EventGuestListSubmitCheckInTicket" = "EVENT_GUEST_LIST-SUBMIT-CHECK_IN_TICKET",
  "EventGuestListSubmitUncheckInTicket" = "EVENT_GUEST_LIST-SUBMIT-UNCHECK_IN_TICKET",
  "EventGuestListSelectCommand" = "EVENT_GUEST_LIST-SELECT-COMMAND",
  "BulkAddCancelClicked" = "BULK_ADD_TAG-CANCEL-CLIKED",
  "BulkRemoveCancelClicked" = "BULK_REMOVE_TAG-CANCEL-CLIKED",
  "BulkAddTagCreateNewTag" = "BULK_ADD_TAG-CREATE-NEW-TAG",
  "BulkRemoveTagRemoved" = "BULK_REMOVE_TAG-REMOVED",
  "BulkAddTagAdded" = "BULK_ADD_TAG-ADDED",
  "ContactListRemoveTagClicked" = "CONTACT_LIST-REMOVE-TAG-CLICKED",
  "EmailListClickSend" = "EMAIL_LIST-CLICK-SEND",
  "EmailListClickDraft" = "EMAIL_LIST-CLICK-DRAFT",
  "EmailListClickScheduled" = "EMAIL_LIST-CLICK-SCHEDULED",
  "EmailTableCellRowClickEmailEditor" = "EMAIL_TABLE_CELL_ROW-CLICK-EMAIL_EDITOR",
  "EmailEditorContactListClickEdit" = "EMAIL_EDITOR_CONTACT_LIST-CLICK-EDIT",
  "DetailDonationActionListClickAnnotate" = "DETAIL_DONATION_ACTION_LIST-CLICK-ANNOTATE",
  "DetailDonationActionListClickEditDonationAmount" = "DETAIL_DONATION_ACTION_LIST-CLICK-EDIT_DONATION_AMOUNT",
  "DetailDonationActionListClickStopTheDonation" = "DETAIL_DONATION_ACTION_LIST-CLICK-STOP_THE_DONATION",
  "DetailDonationActionListClickArchiveDonation" = "DETAIL_DONATION_ACTION_LIST-CLICK-ARCHIVE_DONATION",
  "DetailDonationActionListClickRefundDonation" = "DETAIL_DONATION_ACTION_LIST-CLICK-REFUND_DONATION",
  "DetailDonationActionListClickResendTheTransactionReceipt" = "DETAIL_DONATION_ACTION_LIST-CLICK-RESEND_THE_TRANSACTION_RECEIPT",
  "DetailDonationActionListClickMoveDonation" = "DETAIL_DONATION_ACTION_LIST-CLICK-MOVE_DONATION",
  "DetailPaymentActionListClickResendTheTransactionReceipt" = "DETAIL_PAYMENT_ACTION_LIST-CLICK-RESEND_THE_TRANSACTION_RECEIPT",
  "AdvancedParametersClickEncourageMonthlyDonations" = "ADVANCED_PARAMETERS-CLICK-ENCOURAGE_MONTHLY_DONATIONS",
  "ThankYouPageClick" = "THANK_YOU_PAGE-CLICK",
  "OnboardingProductStep2ClickSmall" = "ONBOARDING_PRODUCT_STEP_2-CLICK-SMALL",
  "OnboardingProductStep2ClickMedium" = "ONBOARDING_PRODUCT_STEP_2-CLICK-MEDIUM",
  "OnboardingProductStep2ClickLarge" = "ONBOARDING_PRODUCT_STEP_2-CLICK-LARGE",
  "OnboardingProductStep2ClickOther" = "ONBOARDING_PRODUCT_STEP_2-CLICK-OTHER",
  "BankClickInviteYourTreasurer" = "BANK-CLICK-INVITE_YOUR_TREASURER",
  "BankConnectionStarted" = "Bank Connection Started",
  "BankConnectionResumed" = "Bank Connection Resumed",
  "BankConnectionOnlineHelpedViewed" = "Bank Connection Online Helped Viewed",
  "ContactPageNewAddNotes" = "CONTACT_PAGE-NEW_ADD_NOTES",
  "ContactPageNoteClickEdit" = "CONTACT_PAGE_NOTE-CLICK-EDIT",
  "ContactPageNoteClickDelete" = "CONTACT_PAGE_NOTE-CLICK-DELETE",
  "ContactPageTagRemoved" = "CONTACT_PAGE-TAG-REMOVED",
  "ContactPageTagAdded" = "CONTACT_PAGE-TAG-ADDED",
  "ContactPageTagDeleted" = "CONTACT_PAGE-TAG-DELETED",
  "ContactPageTagEdited" = "CONTACT_PAGE-TAG-EDITED",
  "ContactPageEditTagsClicked" = "CONTACT_PAGE-EDIT-TAGS-CLICKED",
  "PreviewTooltipClickGoToDashboard" = "PREVIEW_TOOLTIP-CLICK-GO_TO_DASHBOARD",
  "DonationDrawerClickGenerateReceipt" = "DONATION_DRAWER-CLICK-GENERATE_RECEIPT",
  "GenerateReceiptTicketingFocusAmount" = "GENERATE_RECEIPT_TICKETING-FOCUS-AMOUNT",
  "GenerateDonationReceiptUncheckSendEmail" = "GENERATE_DONATION_RECEIPT-UNCHECK-SEND_EMAIL",
  "BalanceClickPayoutSchedule" = "BALANCE-CLICK-PAYOUT_SCHEDULE",
  "OrganizationInformationClickAddSignature" = "ORGANIZATION_INFORMATION-CLICK-ADD_SIGNATURE",
  "FormReminderEmailChanged" = "Form Reminder Email Changed",
  "AdvancedParametersClickReminderEmailEdit" = "ADVANCED_PARAMETERS-CLICK-REMINDER_EMAIL_EDIT",
  "EmailDetailDrawerClickSeeMore" = "EMAIL_DETAILS_DRAWER-CLICK-SEE_MORE",
  "EmailDetailDrawerClickSeeLess" = "EMAIL_DETAILS_DRAWER-CLICK-SEE_LESS",
  "DashboardClickMyTransactions" = "DASHBOARD-CLICK-MY_TRANSACTIONS",
  "DashboardClickMyCampaigns" = "DASHBOARD-CLICK-MY_CAMPAIGNS",
  "DonorTransactionClickEdit" = "DONOR_TRANSACTIONS-CLICK-EDIT",
  "DonorTransactionClickSeeMore" = "DONOR_TRANSACTIONS-CLICK-SEE_MORE",
  "DonorTransactionDrawerClickEditTheAmount" = "DONOR_TRANSACTION_DRAWER-CLICK-EDIT_THE_AMOUNT",
  "DonorTransactionDrawerClickStopDonation" = "DONOR_TRANSACTION_DRAWER-CLICK-STOP_DONATION",
  "DonorTransactionDrawerClickPaymentMethodUpdate" = "DONOR_TRANSACTION_DRAWER-CLICK-PAYMENT_METHOD_UPDATE",
  "DonorTransactionDrawerClickDownloadReceipt" = "DONOR_TRANSACTION_DRAWER-CLICK-DOWNLOAD_RECEIPT",
  "DonorTransactionDrawerClickDownloadETicket" = "DONOR_TRANSACTION_DRAWER-CLICK-DOWNLOAD_E_TICKET",
  "DonorCampaignsClickEdit" = "DONOR_CAMPAIGNS-CLICK-EDIT",
  "EmbedPopupLoaded" = "EMBED_POPUP-LOADED",
  "EmbedPopupClick" = "EMBED_POPUP-CLICK",
  "AdministrativeInformationFocusOrganizationName" = "ADMINISTRATIVE_INFORMATION-FOCUS-ORGANIZATION_NAME",
  "AdministrativeInformationFocusBankDescriptor" = "ADMINISTRATIVE_INFORMATION-FOCUS-BANK_DESCRIPTOR",
  "AdministrativeInformationClickOrganizationType" = "ADMINISTRATIVE_INFORMATION-CLICK-ORGANIZATION_TYPE",
  "AdministrativeInformationFocusReferralQuestion" = "ADMINISTRATIVE_INFORMATION-FOCUS-REFERRAL_QUESTION_OTHER",
  "CopyEmailClicked" = "Copy Email Clicked",
  "PhoneNumberClickCopy" = "PHONE_NUMBER-CLICK-COPY",
  "TicketCheckAddress" = "TICKETS-CHECK-ADDRESS",
  "CustomizationCheckAddress" = "CUSTOMIZATION-CHECK-ADDRESS",
  "SettingsClickDeleteOrganization" = "SETTINGS-CLICK-DELETE_ORGANIZATION",
  "SettingsClickDeleteUserAccount" = "SETTINGS-CLICK-DELETE_USER_ACCOUNT",
  "SettingsClickTwoFactorAuth" = "SETTINGS-CLICK-TWO_FACTOR_AUTH",
  "SettingsTwoFactorAuthEnable" = "SETTINGS-TWO_FACTOR_AUTH_ENABLE",
  "SettingsTwoFactorAuthDisable" = "SETTINGS-TWO_FACTOR_AUTH_DISABLE",
  "SettingsTwoFactorAuthConfirmDisableByClick" = "SETTINGS-TWO_FACTOR_AUTH_CONFIRM_DISABLE_BY_CLICK",
  "SettingsTwoFactorAuthConfirmDisableByEnterKeyPress" = "SETTINGS-TWO_FACTOR_AUTH_CONFIRM_DISABLE_BY_ENTER_KEY_PRESS",
  "SettingsTwoFactorAuthFocusPasswordToDisable" = "SETTINGS-TWO_FACTOR_AUTH_FOCUS_PASSWORD_TO_DISABLE",
  "SettingsTwoFactorAuthFocusPasswordToDisableAfterError" = "SETTINGS-TWO_FACTOR_AUTH_FOCUS_PASSWORD_TO_DISABLE_AFTER_ERROR",
  "SettingsTwoFactorAuthSendVerifyEmail" = "SETTINGS-TWO_FACTOR_AUTH_SEND_VERIFY_EMAIL",
  "SettingsCloseDeleteUserAccountModal" = "SETTINGS-CLOSE-DELETE_USER_ACCOUNT_MODAL",
  "SettingsCloseTwoFactorAuthModal" = "SETTINGS-CLOSE-TWO_FACTOR_AUTH_MODAL",
  "SettingsClickConfirmDeleteOrganization" = "SETTINGS-CLICK_CONFIRM_DELETE_ORGANIZATION",
  "SettingsClickConfirmDeleteUserAccount" = "SETTINGS-CLICK_CONFIRM_DELETE_USER_ACCOUNT",
  "DetailTicketActionListClickCancelTickets" = "DETAIL_TICKET_ACTION_LIST-CLICK-CANCEL_TICKETS",
  "DetailTicketActionListClickResendTransactionReceipt" = "DETAIL_TICKET_ACTION_LIST-CLICK-RESEND_TRANSACTION_RECEIPT",
  "DetailTicketActionListClickRefundTicket" = "DETAIL_TICKET_ACTION_LIST-CLICK-REFUND_TICKET",
  "DetailTicketActionListClickGenerateReceipt" = "DETAIL_TICKET_ACTION_LIST-CLICK-GENERATE_RECEIPT",
  "PayoutAddAccountantButtonClick" = "PAYOUTS-CLICK-ADD_MY_ACCOUNTANT",
  "CustomizationClickVideo" = "CUSTOMIZATION-CLICK-VIDEO",
  "CustomizationClickBannerUnsplash" = "CUSTOMIZATION-CLICK-BANNER_UNSPLASH",
  "CustomizationClickPreviewReceipt" = "CUSTOMIZATION-CLICK-PREVIEW_RECEIPT",
  "FormTicketPreviewed" = "Form Ticket Previewed",
  "ClickPreview" = "STEP_0-CLICK-CARD",
  "FormSharingClicked" = "Form Sharing Clicked",
  "ThankYouPageSharingClicked" = "Thank You Page Sharing Clicked",
  "DaschboardClickTiperAlert" = "MENU-OPEN-NEGATIVE_TIP",
  "CostIndicatorClickMoreInfo" = "NEGATIVE_TIP-CLICK-MORE_INFO",
  "FormsListP2pClickExport" = "FORMS_LIST-P2P-CLICK-EXPORT",
  "FormsListP2pClickNewMessage" = "FORMS_LIST-P2P-CLICK-NEW_MESSAGE",
  "MapClickTicketing" = "MAP-CLICK-TICKETING",
  "MapClickOrganization" = "MAP-CLICK-ORGANIZATION",
  "MapClickLogin" = "MAP-CLICK-LOGIN",
  "MapClickSignup" = "MAP-CLICK-SIGN_UP",
  "AdvancedSettingsClickTranslate" = "ADVANCED_SETTINGS-CLICK-TRANSLATE",
  "AdvancedSettingsClickRemoveTranslations" = "ADVANCED_SETTINGS-CLICK-REMOVE-TRANSLATIONS",
  "GeneralInformationClickAddRegistrationProcess" = "GENERAL_INFORMATION-CLICK-ADD_REGISTRATION_PROCESS",
  "SubmitOrgCategoryPopup" = "SUBMIT-ORG_CATEGORY_POP_UP",
  "FormClickPaymentMethodCoverFees" = "FORM-CLICK-PAYMENT_METHOD-COVER_FEES",
  "FormClickPaymentMethodSplitFees" = "FORM-CLICK-PAYMENT_METHOD-SPLIT_FEES",
  "FormClickPaymentMethodNextTime" = "FORM-CLICK-PAYMENT_METHOD-NEXT-TIME",
  "FormShowSplitOrCoverFees" = "FORM-SHOW-SPLIT_OR_COVER_FEES",
  "FormClickCloseSplitOrCoverFees" = "FORM-CLICK-CLOSE-SPLIT_OR_COVER_FEES",
  "TicketClickFundraiserIndividual" = "TICKET-CLICK-FUNDRAISER_INDIVIDUAL",
  "TicketClickFundraiserNewTeam" = "TICKET-CLICK-FUNDRAISER_NEW_TEAM",
  "TicketClickFundraiserJoinTeam" = "TICKET-CLICK-FUNDRAISER_JOIN_TEAM",
  "FormClickSameAsBuyer" = "FORM-CLICK-SAME_AS_BUYER",
  "TicketClickFundraiserPic" = "TICKET-CLICK-FUNDRAISER_PIC",
  "TicketSelectTeam" = "TICKET-SELECT-TEAM",
  "ReferralTabClickSocialCopy" = "REFERRAL_TAB-CLICK-SOCIAL_COPY",
  "ReferralTabClickSocialFacebook" = "REFERRAL_TAB-CLICK-SOCIAL_FACEBOOK",
  "ReferralTabClickSocialX" = "REFERRAL_TAB-CLICK-SOCIAL_X",
  "ReferralTabClickSocialMessenger" = "REFERRAL_TAB-CLICK-SOCIAL_MESSENGER",
  "ReferralTabClickSocialEmail" = "REFERRAL_TAB-CLICK-SOCIAL_EMAIL",
  "ContactListImport" = "CONTACT-LIST-IMPORT",
  "ContactListClickImport" = "CONTACT_LIST-CLICK-IMPORT",
  "PaymentListClickImport" = "PAYMENT_LIST-CLICK-IMPORT",
  "PaymentListImport" = "PAYMENT-LIST-IMPORT",
  "ImportClickLearnMore" = "IMPORT-CLICK-LEARN_MORE",
  "ImportClickStartImport" = "IMPORT-CLICK-START-IMPORT",
  "ImportClickContact" = "IMPORT-CLICK-CONTACTS",
  "ImportClickTransaction" = "IMPORT-CLICK-TRANSACTIONS",
  "ImportClickDelete" = "IMPORT-CLICK-DELETE",
  "ImportClickFileUpload" = "IMPORT-CLICK-FILE_UPLOAD",
  "ImportClickReplaceFile" = "IMPORT-CLICK-REPLACE_FILE",
  "ImportClickLaunchImport" = "IMPORT-CLICK-LAUNCH_IMPORT",
  "ImportClickSuccessfulPayment" = "IMPORT-CLICK-SUCCESSFUL-PAYMENT",
  "ImportDisplayFailedLaunch" = "IMPORT-DISPLAY-FAILED_LAUNCH",
  "ImportTemplateAccessedContact" = "IMPORT-TEMPLATE-ACCESSED-CONTACT",
  "ImportTemplateAccessedPayment" = "IMPORT-TEMPLATE-ACCESSED-PAYMENT",
  "ImportHelpCenterAccessed" = "IMPORT-HELPCENTER-ACCESSED",
  "ImportErrorShownEmpty" = "IMPORT-ERROR-SHOWN-EMPTY",
  "ImportErrorShownInvalid" = "IMPORT-ERROR-SHOWN-INVALID",
  "ImportErrorShownEmptyAndInvalid" = "IMPORT-ERROR-SHOWN-EMPTY-AND-INVALID",
  "ImportErrorRequirementClicked" = "IMPORT-ERROR-REQUIREMENT-CLICKED",
  "ReceiptsClickCancelReceipt" = "RECEIPTS-CLICK-CANCEL_RECEIPT",
  "ContactPageClickCancelReceipt" = "CONTACT_PAGE-CLICK-CANCEL_RECEIPT",
  "ManualCommandClickViewDetails" = "MANUAL_COMMAND-CLICK-VIEW_DETAILS",
  "ManualCommandClickAddMorePayments" = "MANUAL_COMMAND-CLICK-ADD_MORE_PAYMENTS",
  "ManualCommandDrawerClosed" = "MANUAL-COMMAND-DRAWER-CLOSED",
  "ManualCommandToggleContactInformation" = "MANUAL-COMMAND-TOGGLE-CONTACT_INFORMATION",
  "FocusSearchReceiptPage" = "RECEIPT_LIST-FOCUS-SEARCH",
  "FocusDateFilterReceiptPage" = "RECEIPT_LIST-FOCUS-DATE_RANGE",
  "ApplyDateFilterReceiptPage" = "RECEIPT_LIST-SELECT-DATE_RANGE",
  "ClickExportAllReceiptPage" = "RECEIPT_LIST-CLICK-EXPORT_ALL",
  "ClickExportReceiptPage" = "RECEIPT_LIST-CLICK-EXPORT_TAX_REPORT",
  "ClickContactReceiptTable" = "RECEIPT_LIST-CLICK-CONTACT",
  "ClickReceiptLink" = "CLICK-RECEIPT_LINK",
  "FocusDateFilterDonorReport" = "CONTACT_LIST-EXPORT_MODAL-FOCUS-DATE_RANGE",
  "ApplyDateFilterDonorReport" = "CONTACT_LIST-EXPORT_MODAL-DATE_FILTER",
  "ClickExportDonorReport" = "CONTACT_LIST-CLICK-EXPORT_DONOR_REPORT",
  "ClickExportAllContact" = "CONTACT_LIST-CLICK-EXPORT_ALL_CONTACTS ",
  "ClickExportDonorReportSuccessful" = "CONTACT_LIST-EXPORT_DONOR_REPORT-SUCCESSFUL",
  "LoginFocusEmail" = "LOGIN-FOCUS-EMAIL",
  "LoginFocusPassword" = "LOGIN-FOCUS-PASSWORD ",
  "LoginFocusTwoFactorCode" = "LOGIN-FOCUS-TWO_FACTOR_CODE",
  "LoginFocusTwoFactorCodeAfterError" = "LOGIN-FOCUS-TWO_FACTOR_CODE_AFTER_ERROR",
  "LoginClickLoginTwoFactorCode" = "LOGIN-CLICK-LOGIN_TWO_FACTOR_CODE",
  "UserSignIn2FACodeResent" = "User Sign In 2FA Code Resent",
  "LoginClickShowPassword" = "LOGIN-CLICK-SHOW_PASSWORD ",
  "UserForgotPasswordClicked" = "User Forgot Password Clicked",
  "UserSignUpInputChanged" = "User Sign Up Input Changed",
  "UserSignUpPageViewed" = "User Sign Up Page Viewed",
  "UserSignUpSubmitted" = "User Sign Up Submitted",
  "UserSignInInputChanged" = "User Sign In Input Changed",
  "UserSignInSSOStarted" = "User Sign In SSO Started",
  "UserSignInSSOCompleted" = "User Sign In SSO Completed",
  "UserSignUpSSOStarted" = "User Sign Up SSO Started",
  "UserSignUpSSOCompleted" = "User Sign Up SSO Completed",
  "UserSignInPageViewed" = "User Sign In Page Viewed",
  "UserSignInSubmitted" = "User Sign In Submitted",
  "UserSignIn2FAStarted" = "User Sign In 2FA Started",
  "UserSignedIn" = "User Signed In",
  "SignInCompleted" = "Sign In Completed",
  "LoginClickBack" = "LOGIN-CLICK-BACK",
  "LoginFocusWrongEmailEmail" = "LOGIN-FOCUS-WRONG_EMAIL-EMAIL ",
  "LoginFocusPassword2" = "LOGIN-FOCUS-PASSWORD_2",
  "LoginClickLoginResetPassword" = "LOGIN-CLICK-LOGIN_RESET_PASSWORD",
  "LoginClickLoginCheckEmail" = "LOGIN-CLICK-LOGIN_CHECK_EMAIL",
  "LoginClickLoginCheckEmail2" = "LOGIN-CLICK-LOGIN_CHECK_EMAIL_2",
  "LoginDonorClickBackToLogin" = "LOGIN-DONOR-CLICK-BACK_TO_LOGIN",
  "LoginDonorClickResendLink" = "LOGIN-DONOR-CLICK-RESEND_LINK",
  "LoginClickEditEmail" = "LOGIN-CLICK-EDIT_EMAIL",
  "FormInsertAmountZeffyModelMessage" = "FORM-INSERT_AMOUNT-ZEFFY_MODEL_MESSAGE",
  // Duplicate event requested by Product team
  "FormClickSplitOrCoverFees" = "FORM-CLICK-SPLIT_OR_COVER_FEES",
  "FormClickSubmitPaymentError" = "Donor Form Payment Error",
  "FormClicked" = "Form Clicked",
  "FormOpened" = "Form Opened",
  "FormArchived" = "Form Archived",
  "FormEditClicked" = "Form Edit Clicked",
  "FormDeleted" = "Form Deleted",
  "FormCloned" = "Form Cloned",
  "FormSalesClosed" = "Form Sales Closed",
  "FormSalesOpened" = "Form Sales Opened",
  "FormFundraiserListDownloaded" = "Form Fundraiser List Downloaded",
  "FormGuestListClicked" = "Form Guest List Clicked",
  "FormPaymentListClicked" = "Form Payment List Clicked",
  "EventManagementGuestListTable" = "EVENT-MANAGEMENT-GUEST-LIST-TABLE",
  "EventManagementGuestListClickManageCheckins" = "EVENT-MANAGEMENT-GUEST-LIST-CLICK-MANAGE-CHECKINS",
  "EventManagementGuestListAccessToCheckinManagerFromTour" = "EVENT-MANAGEMENT-GUEST-LIST-ACCESS-TO-CHECKIN-MANAGER-FROM-TOUR",
  "EventManagementGuestListClickOpenCheckinManager" = "EVENT-MANAGEMENT-GUEST-LIST-CLICK-OPEN-CHECKIN-MANAGER",
  "EventManagementGuestListClickShareCheckinManager" = "EVENT-MANAGEMENT-GUEST-LIST-CLICK-SHARE-CHECKIN-MANAGER",
  "EventManagementGuestListClickCopyCheckinManagerLink" = "EVENT-MANAGEMENT-GUEST-LIST-CLICK-COPY-CHECKIN-MANAGER-LINK",
  "EventManagementGuestListClickLinkToLearnMore" = "EVENT-MANAGEMENT-GUEST-LIST-CLICK-LINK-TO-LEARN-MORE",
  "EventManagementCheckinManagerCheckCommand" = "EVENT-MANAGEMENT-CHECKIN-MANAGER-CHECK-COMMAND",
  "EventManagementCheckinManagerUnCheckCommand" = "EVENT-MANAGEMENT-CHECKIN-MANAGER-UNCHECK-COMMAND",
  "EventManagementCheckinManagerCheckTicket" = "EVENT-MANAGEMENT-CHECKIN-MANAGER-CHECK-TICKET",
  "EventManagementCheckinManagerUnCheckTicket" = "EVENT-MANAGEMENT-CHECKIN-MANAGER-UNCHECK-TICKET",
  "EventManagementCheckinManagerClickOpenDetailPanel" = "EVENT-MANAGEMENT-CHECKIN-MANAGER-CLICK-OPEN-DETAIL-PANEL",
  "EventManagementCheckinManagerFocusSearch" = "EVENT-MANAGEMENT-CHECKIN-MANAGER-FOCUS-SEARCH",
  "EventManagementCheckinManagerSearch" = "EVENT-MANAGEMENT-CHECKIN-MANAGER-SEARCH",
  "EventManagementCheckinManagerTourClickNext" = "EVENT-MANAGEMENT-CHECKIN-MANAGER-TOUR-CLICK-NEXT",
  "EventManagementCheckinManagerTourClickBack" = "EVENT-MANAGEMENT-CHECKIN-MANAGER-TOUR-CLICK-BACK",
  "EventManagementCheckinManagerTourClickCloseModal" = "EVENT-MANAGEMENT-CHECKIN-MANAGER-TOUR-CLICK-CLOSE-MODAL",
  "EventManagementCheckinManagerTourClickAccess" = "EVENT-MANAGEMENT-CHECKIN-MANAGER-TOUR-CLICK-ACCESS",
  "PaymentTableClickFormOptions" = "PAYMENT-TABLE-CLICK-FORM_OPTIONS",
  "PaymentTableClickTab" = "PAYMENT_TABLE-CLICK-TAB",
  "PaymentTableClickShare" = "PAYMENT-TABLE-CLICK-SHARE",
  "PaymentTableClickOpenForm" = "PAYMENT-TABLE-CLICK-OPEN_FORM",
  "PaymentTableClickEditForm" = "PAYMENT-TABLE-CLICK-EDIT_FORM",
  "GuestListTableFocusRateFilter" = "GUEST-LIST-TABLE-FOCUS-RATE_FILTER",
  "GuestListTableApplyRateFilter" = "GUEST-LIST-TABLE-APPLY-RATE_FILTER",
  "GuestListTableFocusStatusFilter" = "GUEST-LIST-TABLE-FOCUS-STATUS_FILTER",
  "GuestListTableApplyStatusFilter" = "GUEST-LIST-TABLE-APPLY-STATUS_FILTER",
  "PaymentExpressCheckoutReloadFailed" = "PAYMENT_EXPRESS-CHECKOUT_RELOAD_FAILED",
  "IssuingClickOrderSpendingCard" = "ISSUING-CLICK-ORDER-SPENDING-CARD",
  "IssuingAutomaticRedirectionToSpendingCard" = "Issuing Automatic Redirection To Spending Card",
  "IssuingOnboardingClickSubmitShippingInfos" = "ISSUING-ONBOARDING-CLICK-SUBMIT-SHIPPING-INFOS",
  "IssuingOnboardingClickDisplaySuccessModal" = "ISSUING-ONBOARDING-CLICK-DISPLAY-SUCCESS-MODAL",
  "IssuingClickActivateSpendingCard" = "ISSUING-CLICK-ACTIVATE-SPENDING-CARD",
  "IssuingClickShowPin" = "ISSUING-CLICK-SHOW-PIN",
  "IssuingActivateCardModalClickComeBackLater" = "ISSUING-ACTIVATE-CARD-MODAL-CLICK-COME-BACK-LATER",
  "IssuingActivateCardModalClickActivateCard" = "ISSUING-ACTIVATE-CARD-MODAL-CLICK-ACTIVATE-CARD",
  "IssuingHeaderCardClickOnSchedulePayout" = "ISSUING-HEADER-CARD-CLICK-ON-SCHEDULE-PAYOUT",
  "IssuingSchedulePayoutModalClickSubmitPayout" = "ISSUING-SCHEDULE-PAYOUT-MODAL-CLICK-SUBMIT-PAYOUT",
  "IssuingTopupOpen" = "ISSUING-TOPUP-OPEN",
  "IssuingTopupSubmit" = "ISSUING-TOPUP-SUBMIT",
  "ShowCrossSellInfoBox" = "SHOW-CROSS_SELL_INFO_BOX",
  "ClickCloseCrossSellInfoBox" = "CLICK-CLOSE-CROSS_SELL_INFO_BOX",
  "ClickCTACrossSellInfoBox" = "CLICK-CTA-CROSS_SELL_INFO_BOX",
  "ContactManualCloseClicked" = "Contact Manual Close Clicked",
  "ContactManualAdditionalInformationShowTagClicked" = "Contact Manual AdditionalInformation Show Tag Clicked",
  "ContactManualAdditionalInformationShowNoteClicked" = "Contact Manual AdditionalInformation Show Note Clicked",
  "ContactManualAdditionalInformationToggleClicked" = "Contact Manual AdditionalInformation Toggle Clicked",
  "ContactManualSaveClicked" = "Contact Manual Save Clicked",
  "ContactManualSaveAddAnotherClicked" = "Contact Manual Save AddAnother Clicked",
  "ContactManualDetailsNavigateClicked" = "Contact Manual Details Navigate Clicked",
  "ContactManualAddAnotherClicked" = "Contact Manual AddAnother Clicked",
  "AuctionClickNext" = "AUCTION-CLICK-NEXT",
  "TicketFocusBidAmount" = "TICKET-FOCUS-BID_AMOUNT",
  "ShareFormModalOpen" = "Form Sharing Started",
  "ShareFormModalClickBackBase" = "SHARE_FORM_MODAL-CLICK-BACK_BASE",
  "ShareFormModalClickSeeForm" = "SHARE_FORM_MODAL-CLICK-SEE_FORM",
  "ShareFormModalClickEmbed" = "SHARE_FORM_MODAL-CLICK-EMBED",
  "ShareFormModalClickTab" = "SHARE_FORM_MODAL-CLICK-TAB",
  "ShareFormModalClickChoose" = "SHARE_FORM_MODAL-CLICK-CHOOSE",
  "ShareFormModalClickBackChoose" = "SHARE_FORM_MODAL-CLICK-BACK_CHOOSE",
  "ShareFormModalClickSharingQrCode" = "Form Sharing QR Clicked",
  "ShareFormModalClickCopyLink" = "SHARE_FORM_MODAL-CLICK-COPY_LINK",
  "ShareFormModalClickCopyEmbed" = "Form Sharing Embed Clicked",
  "ShareFormModalClickCopyLinkedButton" = "Form Sharing Linked Button Clicked",
  "PaymentPageNewPostEventThankYouEmail" = "PAYMENT_PAGE-NEW_POST_EVENT_THANK_YOU_EMAIL",
  "PostEventThankYouEmailModalClickClose" = "POST_EVENT_THANK_YOU_EMAIL_MODAL-CLOSED",
  "PostEventThankYouEmailModalClickDismiss" = "POST_EVENT_THANK_YOU_EMAIL_MODAL-DISMISSED",
  "PostEventThankYouEmailModalClickSendEmail" = "POST_EVENT_THANK_YOU_EMAIL_MODAL-EMAIL-SENT",
  "PaymentNoteAdded" = "Payment Note Added",
  "NewUserInvited" = "New User Invited",
  "ExistingUserEdited" = "Existing user Edited",
  "ExistingUserMakeAdmin" = "Existing user make admin",
  "ExistingUserSendEvent" = "Existing user send event",
  "ExistingUserRemove" = "Existing user remove",
  "ManagerUsersLearnMoreClicked" = "Manager Users Learn More Clicked",
  "FormChoiceViewed" = "Service Entry Page Viewed",
  "ServiceEntryFormClicked" = "Service Entry Form Clicked",
  "FormStepChange" = "Form Step Changed",
  "FormStepSubmitted" = "Form Step Submitted",
  "FormImageDeleted" = "Form Image Deleted",
  "FormImageRotated" = "Form Image Rotated",
  "FormImageScaled" = "Form Image Scaled",
  "FormDraftCreated" = "Form Draft Created",
  "FormCreated" = "Form Created",
  "FormImageUploadStarted" = "Form Image Upload Started",
  "FormImageUploadCancelled" = "Form Image Upload Cancelled",
  "FormImageUploadSubmitted" = "Form Image Upload Submitted",
  "FormImageCropped" = "Form Image Cropped",
  "StepperClickBack" = "STEPPER-CLICK-BACK",
  "ChecklistCreateFormClicked" = "Checklist Create Form Clicked",
  "ChecklistPreviewClicked" = "Checklist Preview Clicked",
  "ChecklistLinkBankClicked" = "Checklist Link Bank Clicked",
  "ChecklistShareFormClicked" = "Checklist Share Form Clicked",
  "ChecklistViewPayoutsClicked" = "Checklist View Payouts Clicked",
  "ReferralStarted" = "Referral Started",
  "ReferralSharingClicked" = "Referral Sharing Clicked",
  "ReferralOriginSelected" = "Referral Origin Selected",
  "EmailSectionNewMessageClicked" = "Email Section New Message Clicked",
  "FormUnarchived" = "Form Unarchived",
  "FormSuccessModalClicked" = "Form Success Modal Clicked",
  "EmailSectionMessageSent" = "Email Section Message Sent",
  "FormEditionStarted" = "Form Edition Started",
  "EmailRecipientRemoved" = "Email Recipient Removed",
  "EmailRecipientAddfromContactsCompleted" = "Email Recipient Add from Contacts Completed",
  "EmailRecipientPasteAttemptedwithError" = "Email Recipient Paste Attempted with Error",
  "EligibilityPageViewed" = "Eligibility Page Viewed",
  "EmailDeliveryPageOpenExistingDelivery" = "Email Delivery Page Open Existing Delivery",
  "NewEmailClicked" = "New Email Clicked",
  "EmailPageDuplicateCreated" = "Email Page Duplicate Created",
  "EmailDrawerDuplicateCreated" = "Email Drawer Duplicate Created",
  "EmailPageEmailResultsViewed" = "Email Page Email Results Viewed",
  "ActivationChecklistViewFormClicked" = "Activation Checklist View Form Clicked",
  "ActivationChecklistShareFormClicked" = "Activation Checklist Share Form Clicked",
  "ActivationChecklistWatchDemo" = "Activation Checklist Watch Demo",
  "ActivationChecklistBookDemo" = "Activation Checklist Book Demo",
  "ActivationChecklistLinkBankClicked" = "Activation Checklist Link Bank Clicked",
  "ActivationChecklistSuccessGotIt" = "Activation Checklist Link Payout Success Got It",
  "TwoFAPrompted" = "2FA Prompted",
  "TwoFASuccessful" = "2FA Successful",
  "TwoFAConfigurationPrompted" = "2FA Configuration Prompted",
  "TwoFAConfigurationCompleted" = "2FA Configuration Completed",
  "TwoFAConfigurationSkipped" = "2FA Configuration Skipped",
  "NewZeffyTemplatesClicked" = "New Zeffy Templates Clicked",
  "ThankYouEmailTemplateClicked" = "Thank you email template Clicked",
  "NewsletterTemplateClicked" = "Newsletter template Clicked",
  "EventInvitationTemplateClicked" = "Event invitation template Clicked",
  "DonationInvitationTemplateClicked" = "Donation invitation template Clicked",
  "FollowupInvitationTemplateClicked" = "Followup invitation template Clicked",
  "FundraiserAppealTemplateClicked" = "Fundraiser appeal template Clicked",
  "EventReminderTemplateClicked" = "Event reminder template Clicked",
  "EngagementChecklistViewSampleContactClicked" = "Engagement Checklist View Sample Contact Clicked",
  "EngagementChecklistViewContactInfoMarkedAsDone" = "Engagement Checklist View Contact Info Marked as Done",
  "DiscoverContactOptionsClicked" = "Discover Contact Options Clicked",
  "DiscoverContactOptionsMarkedAsDone" = "Discover Contact Options Marked as Done",
  "StartEmailCampaignClicked" = "Start Email Campaign Clicked",
  "StartEmailCampaignMarkedAsDone" = "Start Email Campaign Marked as Done",
  "VerifyEmailClicked" = "Verify Email Clicked",
  "VerifyEmailMarkedAsDone" = "Verify Email Marked as Done",
  "ViewSamplePaymentClicked" = "View Sample Payment Clicked",
  "ViewSamplePaymentMarkedAsDone" = "View Sample Payment Marked as Done",
  "EngagementChecklistAddPaymentClicked" = "Engagement Checklist Add Payment Clicked",
  "EngagementChecklistAddPaymentClickedMarkedAsDone" = "Engagement Checklist Add Payment Clicked Marked as Done",
  "EngagementChecklistAddTeammatesClicked" = "Engagement Checklist Add Teammates Clicked",
  "EngagementChecklistAddTeammatesMarkedAsDone" = "Engagement Checklist Add Teammates Marked as Done",
  "ActivationChecklistNextStepEngageClicked" = "Activation Checklist Next Step Engage Clicked",
  "ActivationChecklistViewPayoutsClicked" = "Activation Checklist View Payouts Clicked",
  "EmailSectionTemplateSelected" = "Email Section Template Selected",
  "FormPreviewed" = "Form Previewed",
  "VisitorsReportUnsubscribed" = "Visitors Report Unsubscribed",
  "FormSharingSendInvitationClicked" = "Form Sharing Send Invitation Clicked",
  "AdvancedSettingsEmailSetupClicked" = "Advanced Settings Email Set Up Clicked",
  "EmailScheduleForLaterStarted" = "Email Schedule for Later Started",
  "EmailScheduleForLaterCompleted" = "Email Schedule for Later Completed",
  "EmailUnscheduled" = "Email Unscheduled",
  "EmailPreviewOpened" = "Email Preview Opened",
  "AddContactStartedFromQuickActions" = "Add Contact Started from Quick Actions",
  "AddPaymentStartedFromQuickActions" = "Add Payment Started from Quick Actions",
  "CreateFormStartedFromQuickActions" = "Create Form Started from Quick Actions",
  "FormsNudgeSetupUpEmailInvitationClicked" = "Forms Nudge Set Up Email Invitation Clicked",
  "FormsNudgeSetupUpEmailInvitationDismissed" = "Forms Nudge Set up Email Invitation Dismissed",
  "SuggestedActionDisplayed" = "Suggested Action Displayed",
  "SuggestedActionCTAClicked" = "Suggested Action CTA Clicked",
  "SuggestedActionSkipped" = "Suggested Action Skipped",
  "SuggestedActionSkippedSubmit" = "Suggested Action Skipped Submit",
  "SuggestedActionSkippedClosed" = "Suggested Action Skipped Close",
  "SuggestedFormCommunicationSetupClicked" = "Suggested Form Communication Setup Clicked",
  "ContactSubscriptionManually" = "Contact Subscription Manually",
  "NewBidSumitted" = "TODO",
  "FroalaOptionClicked" = "Froala Option Clicked",
  "TrackBidsClicked" = "Track Bids Clicked",
  "EmailConfirmingScheduledSendClicked" = "Email Confirming Scheduled Send Clicked",
  "OnboardingChecklistDismissed" = "Onboarding Checklist Dismissed",
  "GivingTuesdayPreMadeFormClicked" = "Giving Tuesday Pre Made Form Clicked",
  "CommunicationCardEditClicked" = "Communication Card Edit Clicked",
  "CommunicationCardEmailClicked" = "Communication Card Email Clicked",
  "ContactsAddedtoRecipientListStarted" = "Contacts Added to Recipient List Started",
  "ContactsAddedToRecipientListCompleted" = "Contacts Added to Recipient List Completed",
  "CommunicationsClickedFromAdvancedSettings" = "Communications Clicked from Advanced Settings",
  "EventCommunicationShortcutClicked" = "Event Communication Shortcut Clicked",
  "LearnMoreAboutZeffyViewed" = "Learn More About Zeffy Viewed",
  "FormBackButtonClicked" = "Form Back Button Clicked",
  "FormAddressAdded" = "Form Address Added",
  "FormDescriptionAdded" = "Form Description Added",
  "FormColorChanged" = "Form Color Changed",
  "FormDiscountCodeAdded" = "Form Discount Code Added",
  "FormExtraDonationRemoved" = "Form Extra Donation Removed",
  "FormEticketRemoved" = "Form Eticket Removed",
  "FormEticketAdded" = "Form Eticket Added",
  "FormViewButtonClicked" = "Form View Button Clicked",
  "FormLinkBankButtonClicked" = "Form Link Bank Button Clicked",
  "FormShareButtonClicked" = "Form Share Button Clicked",
  "FormCommunicationClicked" = "Form Communication Clicked",
  "FormThermometerAdded" = "Form Thermometer Added",
  "FormThermometerRemoved" = "Form Thermometer Removed",
  "FormChequePaymentAdded" = "Form Cheque Payment Added",
  "FormChequePaymentRemoved" = "Form Cheque Payment Removed",
  "FormTranslationStarted" = "Form Translation Started",
  "FormTranslationAdded" = "Form Translation Added",
  "FormNavStepClicked" = "Form Nav Step Clicked",
  "FormSaveEditClicked" = "Form Save Edit Clicked",
  "FormSaveQuitClicked" = "Form Save Quit Clicked",
  "FormQuitClicked" = "Form Quit Clicked",
  "PaymentSelected" = "Payment Selected",
  "BulkExportCompletedOnCampaignHub" = "Bulk Export Completed on Campaign Hub",
  "BankSetupChoicesViewed" = "Bank Setup Choices Viewed",
  "BankAccountCreationAborted" = "Bank Account Creation Aborted",
  "BankAccountCreationPageViewed" = "Bank Account Creation Page Viewed",
  "BankAccountCreationStarted" = "Bank Account Creation Started",
  "CampaignToDoChecked" = "Campaign To-do Checked",
  "CampaignToDoUnchecked" = "Campaign To-do Unchecked",
  "CampaignToDoTotal" = "Campaign To-do Total",
  "CampaignToDoOpened" = "Campaign To-do Opened",
  "NpoStarterChecklistStepCompleted" = "NPO Starter Checklist Step Completed",
  "NpoStarterChecklistStepStarted" = "NPO Starter Checklist Step Started",
  "NpoStarterChecklistStepReset" = "NPO Starter Checklist Step Reset",
  "ViewAllPaymentsClicked" = "View All Payments Clicked",
  "TotalRaisedClicked" = "Total Raised Clicked",
  "UserEmailAttachmentTooLarge" = "User Email Attachment Too Large",
  "PayoutReportExported" = "Payout Report Exported",
  "FlatFileImportExtension" = "Flat File Import Extension",
  "AccountNumberCopied" = "Account Number Copied",
  "UserSignUpRedirectedToSignIn" = "User Sign Up Redirect to Sign In",
  "ContactImportStarted" = "Contact Import Started",
  "ContactImportCompleted" = "Contact Import Completed",
  "PaymentExportTypeChanged" = "Payment Export Type Changed",
  "PaymentExportColumnsChanged" = "Payment Export Columns Changed",
  "PaymentListExported" = "Payment List Exported",
  "PaymentListExportStarted" = "Payment List Export Started",
  "PaymentListExportClosed" = "Payment List Export Closed",
  "PinCopied" = "Pin Copied",
  "PaymentExportSelectAllColumns" = "Payment Export Select All Columns",
}

export type FormVisitParams = { [key: string]: unknown } & {
  has_receipt: boolean | null;
  has_receipt_message: boolean | null;
  has_banner: boolean;
  has_banner_video: boolean;
  has_logo: boolean;
  has_target: boolean | null;
  has_in_honour_option: boolean | null;
  has_a_linked_ticketing: boolean | null;
  has_check_option: boolean | null;
  encourage_monthly_donations: boolean | null;
  organization_country: OrganizationCountry | null;
  count_custom_questions: number | null;
  has_additional_donation: boolean | null;
  locale: Locales;
  form_type: DonationFormCategory | TicketingFormCategory | FormType | null;
  display_address_question: boolean | null;
  has_no_suggested_amount: boolean | null;
  is_primary_campaign: boolean | null;
  is_primary_team: boolean | null;
};

export const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY as string;
