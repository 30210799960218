export var TreasuryLedgerTransactionObjectType;
(function (TreasuryLedgerTransactionObjectType) {
    TreasuryLedgerTransactionObjectType["FundraisingPayment"] = "fundraising_payment";
    TreasuryLedgerTransactionObjectType["FundraisingRefund"] = "fundraising_refund";
    TreasuryLedgerTransactionObjectType["FundraisingDispute"] = "fundraising_dispute";
    TreasuryLedgerTransactionObjectType["ReferralPayment"] = "referral_payment";
    TreasuryLedgerTransactionObjectType["IssuingPayment"] = "issuing_payment";
    TreasuryLedgerTransactionObjectType["IssuingRefund"] = "issuing_refund";
    TreasuryLedgerTransactionObjectType["IssuingDispute"] = "issuing_dispute";
    TreasuryLedgerTransactionObjectType["IssuingReward"] = "issuing_reward";
    TreasuryLedgerTransactionObjectType["TreasuryOutboundPayment"] = "treasury_outbound_payment";
    TreasuryLedgerTransactionObjectType["TreasuryReceivedCredit"] = "treasury_received_credit";
    TreasuryLedgerTransactionObjectType["TreasuryReceivedDebit"] = "treasury_received_debit";
    TreasuryLedgerTransactionObjectType["TreasuryInterest"] = "treasury_interest";
})(TreasuryLedgerTransactionObjectType || (TreasuryLedgerTransactionObjectType = {}));
export var ListTreasuryLedgerTransactionEntryType;
(function (ListTreasuryLedgerTransactionEntryType) {
    ListTreasuryLedgerTransactionEntryType["Credit"] = "credit";
    ListTreasuryLedgerTransactionEntryType["Debit"] = "debit";
})(ListTreasuryLedgerTransactionEntryType || (ListTreasuryLedgerTransactionEntryType = {}));
