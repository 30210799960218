import * as React from "react";

interface SessionContextProps {
  sessionId: string;
  isFraudulent: boolean;
  setIsFraudulent: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAdminTheme: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SessionContext = React.createContext<SessionContextProps>({
  sessionId: "",
  isFraudulent: false,
  setIsFraudulent: () => {},
  setIsAdminTheme: () => {},
});
