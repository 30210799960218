import { useEffect, useState } from "react";

import { ApolloProvider } from "@apollo/client";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-license-pro";
import { NextPage } from "next";
import { AppProps } from "next/app";
import NextHead from "next/head";
import { usePathname } from "next/navigation";
// eslint-disable-next-line no-restricted-imports
import { appWithTranslation } from "next-i18next";
import { NuqsAdapter } from "nuqs/adapters/next/pages";
import { Provider as ReduxProvider } from "react-redux";

import { apolloClient } from "../apollo/client";
import { ErrorHandler } from "../components/ErrorHandler/ErrorHandler";
import { trpc } from "../helpers/trpc";
import { AmplitudeProvider } from "../providers/AmplitudeProvider";
import { ConsentModeProvider } from "../providers/ConsentModeProvider";
import { SessionContextProvider } from "../providers/SessionProvider";

import "../styles.css";
import "react-image-crop/dist/ReactCrop.css";
import { ZeffySnackbarProvider } from "@/design-system/Snackbar/ZeffySnackbarProvider";
import { store } from "@/redux/store";
import { zeffyTheme, superAdminTheme } from "@/theme/theme";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_X_LICENSE_KEY as string);

export enum WebVitals {
  "FCP" = "FCP",
  "FID" = "FID",
}

export function reportWebVitals(metric: { id: string; name: string; label: string; value: number; startTime: number }) {
  const { id, name, label, value } = metric;

  if (process.env.NODE_ENV !== "production") {
    // eslint-disable-next-line no-console
    console.log("Web vitals:", name, value);
    return;
  }

  if (!window.gtag) {
    // eslint-disable-next-line no-console
    console.error("window.gtag is not defined, verify that gtag script is imported via <Script> or via gTagManager");
    return;
  }

  window.gtag("event", name, {
    event_category: label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
    value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
    event_label: id, // id unique to current page load
    non_interaction: true, // avoids affecting bounce rate.
  });
}

interface MyAppProps extends AppProps {
  Component: NextPage & {
    getLayout?: (page: React.ReactElement<unknown>) => React.ReactNode;
  };
  pageProps: Record<string, unknown>;
}

const MyApp = (props: MyAppProps) => {
  const { Component, pageProps } = props;
  const [isAdminTheme, setIsAdminTheme] = useState(false);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles);
    }
  }, []);

  const pathname = usePathname();

  const frontendUrl = process.env.NEXT_PUBLIC_FRONTEND_URL as string;
  const canonicalUrl = `${frontendUrl}${pathname}`;

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page: React.ReactElement<unknown>) => page);

  return (
    <>
      <NextHead>
        <link rel="canonical" href={canonicalUrl} />
      </NextHead>
      <ApolloProvider client={apolloClient}>
        <ErrorHandler>
          <SessionContextProvider setIsAdminTheme={setIsAdminTheme}>
            <NuqsAdapter>
              <ReduxProvider store={store}>
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={isAdminTheme ? superAdminTheme : zeffyTheme}>
                    <ZeffySnackbarProvider>
                      <ConsentModeProvider>
                        <AmplitudeProvider>
                          <>{getLayout(<Component {...pageProps} />)}</>
                        </AmplitudeProvider>
                      </ConsentModeProvider>
                    </ZeffySnackbarProvider>
                  </ThemeProvider>
                </StyledEngineProvider>
              </ReduxProvider>
            </NuqsAdapter>
          </SessionContextProvider>
        </ErrorHandler>
      </ApolloProvider>
    </>
  );
};

export default trpc.withTRPC(appWithTranslation(MyApp));
