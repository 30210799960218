import { FunctionComponent, PropsWithChildren, useMemo, useState } from "react";

import { v4 } from "uuid";

import { SessionContext } from "../contexts/SessionContext";

export const SessionContextProvider: FunctionComponent<
  PropsWithChildren & { setIsAdminTheme: React.Dispatch<React.SetStateAction<boolean>> }
> = ({ children, setIsAdminTheme }) => {
  const sessionId = useMemo(() => v4(), []);
  const [isFraudulent, setIsFraudulent] = useState<boolean>(false);

  return (
    <SessionContext.Provider
      value={{
        sessionId,
        isFraudulent,
        setIsFraudulent,
        setIsAdminTheme,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
