export var DiscountType;
(function (DiscountType) {
    DiscountType["Dollars"] = "Dollars";
    DiscountType["Percentage"] = "Percentage";
})(DiscountType || (DiscountType = {}));
export var MembershipValidityPeriod;
(function (MembershipValidityPeriod) {
    MembershipValidityPeriod["OneYearAfterPayment"] = "OneYearAfterPayment";
    MembershipValidityPeriod["AtDate"] = "AtDate";
    MembershipValidityPeriod["NoExpiration"] = "NoExpiration";
    MembershipValidityPeriod["OneMonthAfterPayment"] = "OneMonthAfterPayment";
})(MembershipValidityPeriod || (MembershipValidityPeriod = {}));
export var TicketingFormCategory;
(function (TicketingFormCategory) {
    TicketingFormCategory["Event"] = "Event";
    TicketingFormCategory["Membership"] = "Membership";
    TicketingFormCategory["MembershipV2"] = "MembershipV2";
    TicketingFormCategory["Lottery"] = "Lottery";
    TicketingFormCategory["Shop"] = "Shop";
    TicketingFormCategory["Auction"] = "Auction";
    TicketingFormCategory["Custom"] = "Custom";
})(TicketingFormCategory || (TicketingFormCategory = {}));
export const LinkedP2PTicketingCategory = TicketingFormCategory.Event;
export var Column;
(function (Column) {
    Column["Fields"] = "fields";
    Column["Translations"] = "translations";
})(Column || (Column = {}));
