import Grid from "@mui/material/Grid";

import { ZeffyLoader } from "../design-system/ZeffyLoader/ZeffyLoader";

export interface ContainerLoaderProps {
  fullPage?: boolean;
}

export const ContainerLoader = ({ fullPage }: ContainerLoaderProps) => {
  return (
    <Grid container alignItems="center" justifyContent="center" sx={{ height: fullPage ? "100vh" : "100%" }}>
      <ZeffyLoader size="large" />
    </Grid>
  );
};
