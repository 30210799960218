export const canAccessClientStorage = () => {
  if (typeof window !== "undefined") {
    return window?.self === window?.top;
  }
  return false;
};

export const canAccessClientStorageWrapper = <T>(callback: () => T): T | undefined => {
  return canAccessClientStorage() ? callback() : undefined;
};
