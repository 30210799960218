/**
 * @deprecated - still used by old mobile app version
 */
export var TipModelExperience;
(function (TipModelExperience) {
    // ! DEPRECATED
    TipModelExperience["Model1"] = "Model1";
    // ! DEPRECATED
    TipModelExperience["ModelA"] = "ModelA";
    // ! DEPRECATED
    TipModelExperience["ModelB"] = "ModelB";
    TipModelExperience["ModelC"] = "ModelC";
    TipModelExperience["ModelD"] = "ModelD";
    TipModelExperience["ModelE"] = "ModelE";
})(TipModelExperience || (TipModelExperience = {}));
