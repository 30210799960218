export var FormErrors;
(function (FormErrors) {
    FormErrors["FormDeleted"] = "FormDeleted";
})(FormErrors || (FormErrors = {}));
export var CampaignTodoKeys;
(function (CampaignTodoKeys) {
    CampaignTodoKeys["Share"] = "share";
    CampaignTodoKeys["FirstPayments"] = "first_payments";
    CampaignTodoKeys["SmartInvite"] = "smart_invite";
    CampaignTodoKeys["TapToPay"] = "tap_to_pay";
    CampaignTodoKeys["IncompletePayments"] = "incomplete_payments";
    CampaignTodoKeys["FollowUp"] = "follow_up";
    CampaignTodoKeys["Blast"] = "blast";
    CampaignTodoKeys["CheckinTour"] = "checkin_tour";
    CampaignTodoKeys["PostEvent"] = "post_event";
})(CampaignTodoKeys || (CampaignTodoKeys = {}));
export var CampaignTodoDisabledBy;
(function (CampaignTodoDisabledBy) {
    CampaignTodoDisabledBy["AUTO"] = "auto";
    CampaignTodoDisabledBy["USER"] = "user";
})(CampaignTodoDisabledBy || (CampaignTodoDisabledBy = {}));
