import { isDevelopment, isStaging } from "../constants/env";
import { ConsentMode } from "../types/consent";

type WindowWithDataLayer = Window & {
  dataLayer?: Record<string, unknown>[];
};

declare const window: WindowWithDataLayer;

declare global {
  interface Window {
    gtag?: (
      event: "event" | "consent",
      name: string,
      data: { event_category: string; value: number; event_label: string; non_interaction: boolean } | ConsentMode
    ) => void;
  }
}

export const updateGtmConsent = (consent: ConsentMode) => {
  window.gtag?.("consent", "update", consent);
  const payload = {
    event: "consent_updated",
  };
  window.dataLayer?.push(payload);
  if (isStaging || isDevelopment) {
    // eslint-disable-next-line no-console
    console.log("GTM event", payload);
  }
};

export const isGtmBlocked = () => {
  return !window.dataLayer || !window.dataLayer.push || typeof window.dataLayer.push !== "function";
};
