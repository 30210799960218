import * as React from "react";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { grey } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as Bowser from "bowser";

import { ContainerLoader } from "@/components/Loaders/ContainerLoader";
import { isDevelopment, isTest } from "@/constants/env";
import { retryPageLoading } from "@/helpers/retryPageLoading";
import { trpc } from "@/helpers/trpc";

interface ErrorHandlerProps {
  children: React.ReactNode;
}

interface ErrorBoundaryProps {
  handleError: (error: unknown, info: unknown) => void;
  children?: React.ReactNode;
}

export const ErrorHandler: React.FunctionComponent<React.PropsWithChildren<ErrorHandlerProps>> = ({ children }) => {
  const { mutateAsync: warnOnFrontEndError } = trpc.warnOnFrontEndError.useMutation();

  const handleError = (error: unknown, info: unknown) => {
    const currentUrl = window.location.href;
    const browser = Bowser.getParser(window.navigator.userAgent);
    const browserInfo = browser.getBrowser();
    const osInfo = browser.getOS();
    const isMobile = browser.is("mobile");
    const device = browser.getPlatform().type || "unknown";
    warnOnFrontEndError({
      error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
      info: JSON.stringify(info),
      url: currentUrl,
      isMobile,
      browser: `${browserInfo.name} ${browserInfo.version}, ${osInfo.name} ${osInfo.version}`,
      device,
    });
  };

  return <ErrorBoundary handleError={handleError}>{children}</ErrorBoundary>;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, { hasError: boolean; isLoading: boolean }> {
  constructor(props: Readonly<ErrorBoundaryProps>) {
    super(props);
    this.state = { hasError: false, isLoading: false };
  }
  componentDidCatch(error: unknown, info: unknown) {
    // eslint-disable-next-line no-console
    console.error(error, info);
    if (isDevelopment || isTest) {
      return;
    }
    const { willRefresh } = retryPageLoading();
    if (willRefresh) {
      return this.setState({ hasError: false, isLoading: true });
    }
    this.setState({ hasError: true, isLoading: false });
    if (process.env.NODE_ENV === "production") {
      this.props.handleError(error, info);
    }
  }
  render() {
    if (this.state.isLoading) {
      return <ContainerLoader fullPage />;
    }
    if (this.state.hasError) {
      return (
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          direction="column"
          alignItems="center"
          style={{ height: "calc(100vh - 16px)", backgroundColor: "#f0f3ff", textAlign: "center" }}
        >
          <Grid item container direction="row">
            <Grid item xs={12}>
              <Typography variant="h6">
                <strong>We are very sorry, an error has occurred within the application. </strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                We have been notified by email of the error, and we are actively working to resolve it.
              </Typography>
            </Grid>

            <Grid item container justifyContent="center" style={{ marginTop: 10 }}>
              <Grid item xs={6}>
                <Typography variant="body1" style={{ color: grey[600] }}>
                  If this error is blocking you, please do not hesitate to email us at contact@zeffy.com.
                </Typography>
                <Typography variant="body1" style={{ color: grey[600] }}>
                  Any information you can provide will be useful to us:
                </Typography>
              </Grid>
            </Grid>
            <Grid item container justifyContent="center">
              <Grid item xs={4}>
                <Typography variant="body1" style={{ color: grey[600] }}>
                  <li>Action taken before the error</li>
                  <li>Page used</li>
                  <li>Error noticed on the page (display error, bad information, long loading time, etc...)</li>
                </Typography>
              </Grid>
            </Grid>

            <Grid item container justifyContent="center">
              <ErrorOutlineIcon color="primary" fontSize="large" style={{ margin: 20 }} />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">
                <strong>Nous sommes désolé, une erreur s&apos;est produite au sein de l&apos;application.</strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Nous avons été prévenu par courriel de l&apos;erreur, et nous travaillons activement à la résoudre.
              </Typography>
            </Grid>
            <Grid item container justifyContent="center" style={{ marginTop: 10 }}>
              <Grid item xs={6}>
                <Typography variant="body1" style={{ color: grey[600] }}>
                  Si cette erreur vous bloque, n&apos;hésitez pas à écrire à l&apos;addresse contact@zeffy.com.
                </Typography>
                <Typography variant="body1" style={{ color: grey[600] }}>
                  Toutes les informations que vous pourrez nous apporter nous seront utiles :
                </Typography>
              </Grid>
            </Grid>
            <Grid item container justifyContent="center">
              <Grid item xs={4}>
                <Typography variant="body1" style={{ color: grey[600] }}>
                  <li>Action réalisée avant l&apos;erreur </li>
                  <li>Page du site utilisée</li>
                  <li>
                    Erreur remarquée sur la page (erreur affichage, mauvaises informations, temps de chargement long,
                    etc...)
                  </li>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return this.props.children;
  }
}
