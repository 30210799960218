import { Theme } from "@mui/material/styles";
import { CSSProperties } from "@mui/styles/withStyles";
import { GetObjectKeysAsTemplateLiterals } from "@simplyk/common";

import { zeffyTheme } from "../../theme/theme";
import { ZeffyColorTokens, ZeffyColorTokenText } from "../../types/mui";

export type ZeffyColorTokenTextClasses = GetObjectKeysAsTemplateLiterals<Pick<ZeffyColorTokens, "text">>;
export type ZeffyColorTokenBorderClasses = GetObjectKeysAsTemplateLiterals<Pick<ZeffyColorTokens, "border">>;

export const generateZeffyColorTokenTextClasses = (
  theme: Theme,
  cssPalettePropName: "color" | "fill"
): Record<ZeffyColorTokenTextClasses, CSSProperties> =>
  Object.keys(theme.palette.text).reduce(
    (tokens: Record<ZeffyColorTokenTextClasses, CSSProperties>, token: string) => {
      if (typeof theme.palette.text[token as keyof ZeffyColorTokenText] === "string") {
        tokens[`text-${token}` as ZeffyColorTokenTextClasses] = {
          [cssPalettePropName]: theme.palette.text[token as keyof ZeffyColorTokenText] as string,
        };
        return tokens;
      }
      Object.keys(theme.palette.text[token as keyof ZeffyColorTokenText]).forEach((colorShade: string) => {
        tokens[`text-${token}-${colorShade}` as ZeffyColorTokenTextClasses] = {
          [cssPalettePropName]: (theme.palette.text[token as keyof ZeffyColorTokenText] as Record<string, string>)[
            colorShade
          ] as string,
        };
      });
      return tokens;
    },
    {} as Record<ZeffyColorTokenTextClasses, CSSProperties>
  );

export type PrefixedKey<Prefix extends string, Key extends string> = `${Prefix}-${Key}`;
export const getClassesKeyMap = <Prefix extends string, ClassKeys extends string>(
  prefix: Prefix,
  classes: Record<ClassKeys, CSSProperties>
) =>
  Object.keys(classes).reduce(
    (map, key) => {
      map[key as ClassKeys] = `${prefix}-${key as ClassKeys}`;
      return map;
    },
    {} as Record<ClassKeys, PrefixedKey<Prefix, ClassKeys>>
  );

export type InternalClasses<Prefix extends string, Classes extends string> = Record<
  Classes,
  PrefixedKey<Prefix, Classes>
>;

export const getZeffyColorClassesKeyMap = <Prefix extends string>(prefix: Prefix) => {
  return getClassesKeyMap(prefix, generateZeffyColorTokenTextClasses(zeffyTheme, "color"));
};

type StyleClassKey<Prefix extends string, ClassKey extends string> = `&.${PrefixedKey<Prefix, ClassKey>}`;
export const getRootStyleClassMap = <Prefix extends string, ClassKeys extends string>(
  prefix: Prefix,
  classes: Record<ClassKeys, CSSProperties>
) =>
  Object.keys(classes).reduce(
    (map, key) => {
      map[`&.${prefix}-${key as ClassKeys}`] = classes[key as ClassKeys];
      return map;
    },
    {} as Record<StyleClassKey<Prefix, ClassKeys>, CSSProperties>
  );

export const getZeffyColorTokenTextStyleMap = <Prefix extends string>(
  prefix: Prefix,
  theme: Theme,
  cssPalettePropName: "color" | "fill"
): Record<StyleClassKey<Prefix, ZeffyColorTokenTextClasses>, CSSProperties> => {
  const classes = generateZeffyColorTokenTextClasses(theme, cssPalettePropName);

  return getRootStyleClassMap(prefix, classes);
};
