export var I18nLocales;
(function (I18nLocales) {
    I18nLocales["FR"] = "fr";
    I18nLocales["EN"] = "en";
    I18nLocales["ES"] = "es";
})(I18nLocales || (I18nLocales = {}));
export var Locales;
(function (Locales) {
    Locales["FR"] = "FR";
    Locales["EN"] = "EN";
    Locales["ES"] = "ES";
})(Locales || (Locales = {}));
export var CadLocales;
(function (CadLocales) {
    CadLocales["FR"] = "FR";
    CadLocales["EN"] = "EN";
})(CadLocales || (CadLocales = {}));
export var UsaLocales;
(function (UsaLocales) {
    UsaLocales["EN"] = "EN";
    UsaLocales["ES"] = "ES";
})(UsaLocales || (UsaLocales = {}));
export var CadIsoLocale;
(function (CadIsoLocale) {
    CadIsoLocale["FR"] = "fr-CA";
    CadIsoLocale["EN"] = "en-CA";
})(CadIsoLocale || (CadIsoLocale = {}));
export var UsaIsoLocale;
(function (UsaIsoLocale) {
    UsaIsoLocale["EN"] = "en-US";
    UsaIsoLocale["ES"] = "es-US";
})(UsaIsoLocale || (UsaIsoLocale = {}));
export var GbIsoLocale;
(function (GbIsoLocale) {
    GbIsoLocale["EN"] = "en-GB";
})(GbIsoLocale || (GbIsoLocale = {}));
export var Day;
(function (Day) {
    Day["Monday"] = "monday";
    Day["Tuesday"] = "tuesday";
    Day["Wednesday"] = "wednesday";
    Day["Thursday"] = "thursday";
    Day["Friday"] = "friday";
    Day["Saturday"] = "saturday";
    Day["Sunday"] = "sunday";
})(Day || (Day = {}));
