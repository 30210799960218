import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { View } from "../../enums/view";
import { setLastUserView } from "../../helpers/localStorage";

interface ViewState {
  view: View | null;
}

const initialState = {
  view: null,
} as ViewState;

const viewSlice = createSlice({
  name: "view",
  initialState,
  reducers: {
    setView: (_state, action: PayloadAction<{ view: View; currenUserId?: string; currentOrganizationId?: string }>) => {
      const { view, currenUserId, currentOrganizationId } = action.payload;
      if (currenUserId) {
        setLastUserView(currenUserId, view, currentOrganizationId);
      }
      return {
        view,
      };
    },
  },
});

export const { setView } = viewSlice.actions;
export default viewSlice.reducer;
