export var GetEmailDeliveryError;
(function (GetEmailDeliveryError) {
    GetEmailDeliveryError["BelongToMyOtherOrganization"] = "BelongToMyOtherOrganization";
})(GetEmailDeliveryError || (GetEmailDeliveryError = {}));
export const RecaptchaGuardMessage = "InvalidRecaptchaToken";
export var DeleteUserError;
(function (DeleteUserError) {
    DeleteUserError["UserNotFound"] = "UserNotFound";
    DeleteUserError["UserIsOwnerOfOrganization"] = "UserIsOwnerOfOrganization";
    DeleteUserError["UserHasActiveSubscription"] = "UserHasActiveSubscription";
})(DeleteUserError || (DeleteUserError = {}));
export var DeactiveTwoFactorAuthError;
(function (DeactiveTwoFactorAuthError) {
    DeactiveTwoFactorAuthError["PasswordDoNotMatch"] = "PasswordDoNotMatch";
})(DeactiveTwoFactorAuthError || (DeactiveTwoFactorAuthError = {}));
export const ORGANIZATION_CANNOT_ACCEPT_PAYMENT_WITHOUT_BANK_ERROR_CODE = "organization_cannot_accept_payment_without_bank";
