export var UserRoles;
(function (UserRoles) {
    UserRoles["Owner"] = "Owner";
    UserRoles["Donor"] = "Donor";
    UserRoles["Member"] = "Member";
})(UserRoles || (UserRoles = {}));
export var UserPermissions;
(function (UserPermissions) {
    UserPermissions["Donation"] = "Donation";
    UserPermissions["Ticketing"] = "Ticketing";
    UserPermissions["Contacts"] = "Contacts";
    UserPermissions["UserManagement"] = "UserManagement";
    UserPermissions["Receipt"] = "Receipt";
    UserPermissions["Bank"] = "Bank";
})(UserPermissions || (UserPermissions = {}));
export const orderedProductPermissions = [
    UserPermissions.Donation,
    UserPermissions.Ticketing,
    UserPermissions.Contacts,
    UserPermissions.Receipt,
    UserPermissions.Bank,
];
export const orderedActionsPermissions = [UserPermissions.UserManagement];
export const orderedPermissions = [...orderedProductPermissions, ...orderedActionsPermissions];
