import { FontStyle, TypographyOptions } from "@mui/material/styles/createTypography";

const getGoogleFontFamily = (name: string): string => [name, "Helvetica", "Arial", "sans-serif"].join(",");

export const fontFamilyPoppins = getGoogleFontFamily("Poppins");
export const fontFamilyRoboto = getGoogleFontFamily("Roboto");

export const fontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
};

const fontStyle: FontStyle = {
  fontFamily: fontFamilyRoboto,
  fontSize: 14,
  htmlFontSize: 14,
  fontWeightLight: fontWeight.light,
  fontWeightRegular: fontWeight.regular,
  fontWeightMedium: fontWeight.medium,
  fontWeightSemiBold: fontWeight.semiBold,
  fontWeightBold: fontWeight.bold,
};

export const typography: TypographyOptions = {
  ...fontStyle,
  h1: {
    fontFamily: fontFamilyPoppins,
    fontWeight: fontWeight.medium,
    fontSize: "58px",
    lineHeight: "64px",
    letterSpacing: "-0.25px",
  },
  h2: {
    fontFamily: fontFamilyPoppins,
    fontWeight: fontWeight.medium,
    fontSize: "46px",
    lineHeight: "52px",
    letterSpacing: 0,
  },
  h3: {
    fontFamily: fontFamilyPoppins,
    fontWeight: fontWeight.medium,
    fontSize: "32px",
    lineHeight: "40px",
    letterSpacing: "0.25px",
  },
  h4: {
    fontFamily: fontFamilyPoppins,
    fontWeight: fontWeight.medium,
    fontSize: "28px",
    lineHeight: "36px",
    letterSpacing: 0,
  },
  h5: {
    fontFamily: fontFamilyPoppins,
    fontWeight: fontWeight.semiBold,
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: 0,
  },
  h6: {
    fontFamily: fontFamilyRoboto,
    fontWeight: fontWeight.semiBold,
    fontSize: "20px",
    lineHeight: "28px",
    letterSpacing: "0.15px",
  },
  subtitle1: {
    fontFamily: fontFamilyRoboto,
    fontWeight: fontWeight.semiBold,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
  subtitle2: {
    fontFamily: fontFamilyRoboto,
    fontWeight: fontWeight.semiBold,
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.15px",
  },
  body1: {
    fontFamily: fontFamilyRoboto,
    fontWeight: fontWeight.regular,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.5px",
  },
  body2: {
    fontFamily: fontFamilyRoboto,
    fontWeight: fontWeight.regular,
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
  },
  caption: {
    fontFamily: fontFamilyRoboto,
    fontWeight: fontWeight.regular,
    fontSize: "13px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
  },
  button1: {
    fontFamily: fontFamilyRoboto,
    fontWeight: fontWeight.medium,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: 0,
  },
  button2: {
    fontFamily: fontFamilyRoboto,
    fontWeight: fontWeight.medium,
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: 0,
  },
  button3: {
    fontFamily: fontFamilyRoboto,
    fontWeight: fontWeight.medium,
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: 0,
  },
};

export const smTypography: TypographyOptions = {
  ...typography,
  h1: typography.h2,
  h2: typography.h3,
  h3: typography.h4,
  h4: typography.h5,
  h5: typography.h6,
  h6: {
    ...typography.h6,
    fontSize: "18px",
    lineHeight: "26px",
  },
};
