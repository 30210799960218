export var NewServiceEntry;
(function (NewServiceEntry) {
    NewServiceEntry["Donation"] = "Donation";
    NewServiceEntry["Event"] = "Event";
    NewServiceEntry["P2P"] = "P2P";
    NewServiceEntry["Membership"] = "Membership";
    NewServiceEntry["Raffle"] = "Raffle";
    NewServiceEntry["Other"] = "Other";
})(NewServiceEntry || (NewServiceEntry = {}));
export var OnboardingQualification;
(function (OnboardingQualification) {
    OnboardingQualification["Small"] = "Small";
    OnboardingQualification["Medium"] = "Medium";
    OnboardingQualification["Large"] = "Large";
    OnboardingQualification["Other"] = "Other";
})(OnboardingQualification || (OnboardingQualification = {}));
export var HubspotStatus;
(function (HubspotStatus) {
    HubspotStatus[HubspotStatus["ExistingContact"] = 409] = "ExistingContact";
    HubspotStatus[HubspotStatus["UpdateSucceeded"] = 204] = "UpdateSucceeded";
})(HubspotStatus || (HubspotStatus = {}));
export var HubspotApiAction;
(function (HubspotApiAction) {
    HubspotApiAction["Create"] = "Create";
    HubspotApiAction["Update"] = "Update";
    HubspotApiAction["Merge"] = "Merge";
    HubspotApiAction["Get"] = "Get";
})(HubspotApiAction || (HubspotApiAction = {}));
export var HubspotLastPaymentCategory;
(function (HubspotLastPaymentCategory) {
    HubspotLastPaymentCategory["DonationForm"] = "DonationForm";
    HubspotLastPaymentCategory["PeerToPeer"] = "PeerToPeer";
    HubspotLastPaymentCategory["Membership"] = "Membership";
    HubspotLastPaymentCategory["Raffle"] = "Raffle";
    HubspotLastPaymentCategory["Ticketing"] = "Ticketing";
    HubspotLastPaymentCategory["Auction"] = "Auction";
})(HubspotLastPaymentCategory || (HubspotLastPaymentCategory = {}));
export var HubspotUserStatus;
(function (HubspotUserStatus) {
    HubspotUserStatus["Creator"] = "Creator";
    HubspotUserStatus["Member"] = "Member";
})(HubspotUserStatus || (HubspotUserStatus = {}));
export var HubspotAccountOwner;
(function (HubspotAccountOwner) {
    HubspotAccountOwner["Admin"] = "Admin";
    HubspotAccountOwner["Member"] = "Member";
})(HubspotAccountOwner || (HubspotAccountOwner = {}));
