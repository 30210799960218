import { createContext } from "react";

import { ConsentMode } from "../types/consent";

interface ConsentModeContextProps {
  hasConsentMode: boolean;
  hasFunctionalityConsentMode: boolean;
  handleSetConsentMode: (consentMode: ConsentMode) => void;
  isUserInQuebec: boolean | null;
  isUserInQuebecLoading: boolean;
}

export const ConsentModeContext = createContext<ConsentModeContextProps>({
  hasConsentMode: false,
  hasFunctionalityConsentMode: false,
  handleSetConsentMode: (_consentMode) => {},
  isUserInQuebec: null,
  isUserInQuebecLoading: false,
});
