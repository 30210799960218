import { ConsentModeValue, ConsentMode, ConsentModeFormFields } from "../types/consent";

export const getDefaultConsentMode = (value: ConsentModeValue): ConsentMode => ({
  ad_storage: value,
  analytics_storage: value,
  functionality_storage: value,
  personalization_storage: value,
  // We always grant security_storage
  security_storage: "granted",
});

export const parseConsentModeFormFields = (data: ConsentModeFormFields): ConsentMode => ({
  ad_storage: data.marketing ? "granted" : "denied",
  analytics_storage: data.analytical ? "granted" : "denied",
  functionality_storage: data.functional ? "granted" : "denied",
  personalization_storage: data.functional ? "granted" : "denied",
  // We always grant security_storage
  security_storage: "granted",
});

const hasConsent = (value: ConsentModeValue | null | undefined): boolean => value === "granted";

export const hasFunctionalityConsent = (consent: ConsentMode | null | undefined): boolean =>
  hasConsent(consent?.functionality_storage);
