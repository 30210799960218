import { reactLocalStorage } from "reactjs-localstorage";

import { View } from "../enums/view";
import { ConsentMode } from "../types/consent";

import { canAccessClientStorageWrapper } from "./embed";

export enum LocalStorageKey {
  OrderTableFilters = "orderTableFilters",
  LastUserViews = "lastUserViews",
  ConsentMode = "consentMode",
  HasPreviewForm = "hasPreviewForm",
}

export type LastUserView = {
  view: View;
  organizationId?: string;
};

export const removeFromLocalStorage = canAccessClientStorageWrapper(
  () => (key: string) => reactLocalStorage.remove(key)
);

export const getFromLocalStorage = canAccessClientStorageWrapper(
  () =>
    <T = string | number | boolean>(key: string, defaultValue?: T, silent?: boolean) =>
      reactLocalStorage.get<T>(key, defaultValue, silent)
);

export const getObjectFromLocalStorage = canAccessClientStorageWrapper(
  () =>
    <T extends object>(key: string, defaultValue?: object, silent?: boolean) =>
      reactLocalStorage.getObject(key, defaultValue, silent) as T | undefined
);

export const setInLocalStorage = canAccessClientStorageWrapper(
  () => (key: string, value: string | number | boolean) => reactLocalStorage.set(key, value)
);

export const setObjectInLocalStorage = canAccessClientStorageWrapper(
  () => (key: string, value: object) => reactLocalStorage.setObject(key, value)
);

const getLastUserViews = (): Record<string, LastUserView> | undefined => {
  return getObjectFromLocalStorage?.<Record<string, LastUserView>>(LocalStorageKey.LastUserViews);
};

export const getLastUserView = (userId?: string): LastUserView | undefined => {
  if (!userId) {
    return undefined;
  }
  const lastUserViews = getLastUserViews();
  return lastUserViews?.[userId];
};

export const setLastUserView = (userId: string, view: View, organizationId?: string) => {
  if (userId) {
    const lastUserViews = getLastUserViews();
    setObjectInLocalStorage?.(LocalStorageKey.LastUserViews, {
      ...lastUserViews,
      [userId]: { view, organizationId } as LastUserView,
    } as Record<string, LastUserView>);
  }
};

export const clearStoredFilters = () => {
  [LocalStorageKey.OrderTableFilters].map((key) => {
    removeFromLocalStorage?.(key);
  });
};

export const getConsentModeFromLocalStorage = (): ConsentMode | undefined => {
  const consentMode = getFromLocalStorage?.(LocalStorageKey.ConsentMode);
  return consentMode ? JSON.parse(consentMode as string) : undefined;
};

export const setConsentModeInLocalStorage = (consentMode: ConsentMode) =>
  setObjectInLocalStorage?.(LocalStorageKey.ConsentMode, consentMode);
