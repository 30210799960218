import { stringify } from "query-string";
import { DonationFormCategory, FormCategory, FormType, Locales } from "../enums";
export var RoutePrefix;
(function (RoutePrefix) {
    RoutePrefix["Organization"] = "/o";
    RoutePrefix["Donor"] = "/d";
})(RoutePrefix || (RoutePrefix = {}));
export var RoutesParams;
(function (RoutesParams) {
    RoutesParams["RelaunchStripeOnboarding"] = "relaunchStripeOnboarding";
    RoutesParams["StripeOnboardingSucceeded"] = "stripeOnboardingSucceeded";
})(RoutesParams || (RoutesParams = {}));
export const HomePageFreePlatformUrl = {
    [Locales.EN]: "https://www.zeffy.com/home/100-free-for-donors",
    [Locales.FR]: "https://fr.zeffy.com/home/100-free-for-donors",
    [Locales.ES]: "https://www.zeffy.com/home/100-free-for-donors",
};
export class BuiltRoute {
    path;
    constructor(path) {
        this.path = path;
    }
}
export const buildPathFromTemplate = (template, params) => {
    let output = template;
    for (const [key, value] of Object.entries(params)) {
        if (value) {
            output = output.replace(new RegExp(`\\[(.*?):${key}(.*?)\\]`, "gi"), `$1${value}$2`); // Replace optional parameter
            output = output.replace(new RegExp(`:${key}`, "gi"), value); // Replace required parameter
        }
        else {
            output = output.replace(new RegExp(`(\\[.*?:${key}.*?\\])`, "gi"), ""); // Remove optional parameter
        }
    }
    return new BuiltRoute(output);
};
export const RouteBuilders = {
    buildPeerToPeerV2CampaignLink: (params) => {
        const { type, ...rest } = params;
        if (type === "general") {
            return RouteBuilders.buildPeerToPeerV2GeneralCampaignLink(rest);
        }
        if (type === "team") {
            return RouteBuilders.buildPeerToPeerV2TeamCampaignLink(rest);
        }
        return RouteBuilders.buildPeerToPeerV2IndividualCampaignLink(rest);
    },
    buildPeerToPeerV2GeneralCampaignLink: (params) => buildPathFromTemplate("[/:isoLocale]/peer-to-peer/:path", params),
    buildPeerToPeerV2TeamCampaignLink: (params) => buildPathFromTemplate(`[/:isoLocale]/team/:path`, params),
    buildPeerToPeerV2IndividualCampaignLink: (params) => buildPathFromTemplate(`[/:isoLocale]/fundraising/:path`, params),
    buildDonationCampaignLink: (params) => buildPathFromTemplate(`[/:isoLocale]/fundraising/:path`, params),
    buildDonationFormLink: (params) => buildPathFromTemplate(`[/:isoLocale]/donation-form/:path`, params),
    buildEmbedDonationFormLink: (params) => buildPathFromTemplate(`[/:isoLocale]/embed/donation-form/:path`, params),
};
export var FrontendRoutes;
(function (FrontendRoutes) {
    FrontendRoutes["DonationFormPage"] = "/donation-form/";
    FrontendRoutes["Landing"] = "/landing";
    FrontendRoutes["LeaderBoard"] = "/leaderboard/";
    FrontendRoutes["NotFound"] = "/404";
    FrontendRoutes["Gone"] = "/410";
    FrontendRoutes["PeerToPeerSuccess"] = "/peer-to-peer-success";
    FrontendRoutes["TeamPage"] = "/team/";
    FrontendRoutes["ThankYou"] = "/thank-you";
    FrontendRoutes["Thermometer"] = "/thermometer/";
    FrontendRoutes["Ticket"] = "/ticket/";
    FrontendRoutes["TicketingPage"] = "/ticketing/";
    FrontendRoutes["ThreeDSecureSuccess"] = "/three-d-secure-succeed";
    FrontendRoutes["NotFoundBug"] = "/404bug";
    FrontendRoutes["PostFormSubmit"] = "/post-form-submit";
})(FrontendRoutes || (FrontendRoutes = {}));
export var DashboardRoutes;
(function (DashboardRoutes) {
    DashboardRoutes["DonationFormsNew"] = "/donations/forms/new";
    DashboardRoutes["DonationFormsEdit"] = "/donations/forms/edit?donationFormId=:id";
    DashboardRoutes["FundraiserFormsEdit"] = "/fundraiser/forms/edit?donationFormId=:id";
    DashboardRoutes["ReceiptGeneration"] = "/receipts/new";
    DashboardRoutes["NotFound"] = "/404";
    DashboardRoutes["Bank"] = "/bank";
    DashboardRoutes["Payouts"] = "/bank/payouts";
    DashboardRoutes["Transactions"] = "/bank/transactions";
    DashboardRoutes["PayoutTransactions"] = "/bank/payouts/transactions?payoutId=:id";
    DashboardRoutes["TicketingNew"] = "/tickets/forms/new";
    DashboardRoutes["TicketingEdit"] = "/tickets/forms/edit?ticketingId=:id";
    DashboardRoutes["CheckinManager"] = "/checkin-manager/:token";
    DashboardRoutes["SavedFeeInfo"] = "/savings/info";
    DashboardRoutes["MFALogin"] = "/mfa-login";
    DashboardRoutes["SavedFeeCongratulation"] = "/savings/congratulations";
    DashboardRoutes["TicketScanned"] = "/ticket/:id";
    DashboardRoutes["FormTable"] = "/fundraising/forms";
    DashboardRoutes["PaymentTable"] = "/fundraising/payments";
    DashboardRoutes["DonorPaymentTable"] = "/payments";
    DashboardRoutes["User"] = "/user?contactId=:id";
    DashboardRoutes["Contacts"] = "/contacts/contacts";
    DashboardRoutes["Visitors"] = "/contacts/visitors";
    DashboardRoutes["EmailDeliveries"] = "/contacts/emails";
    DashboardRoutes["SignIn"] = "/login";
    DashboardRoutes["SignOut"] = "/logout";
    DashboardRoutes["SignUp"] = "/register";
    DashboardRoutes["SignUpPostDemo"] = "/register-post-demo";
    DashboardRoutes["ResetPassword"] = "/reset-password";
    DashboardRoutes["CreatePassword"] = "/create-password";
    DashboardRoutes["CreateNewOrganization"] = "/organization/new";
    DashboardRoutes["CreateBankAccount"] = "/bank/account/create";
    DashboardRoutes["MergeUsers"] = "/merge-users";
    DashboardRoutes["VerifyEmail"] = "/verify-email";
    DashboardRoutes["UpdateBankAccount"] = "/bank/account/update";
    DashboardRoutes["Referral"] = "/referral";
    DashboardRoutes["Settings"] = "/settings";
    DashboardRoutes["DonorCampaigns"] = "/campaigns";
    DashboardRoutes["FormsNew"] = "/forms/new";
    DashboardRoutes["Commands"] = "/commands";
    DashboardRoutes["CostsIndicator"] = "/indicator/costs";
    DashboardRoutes["SwitchOrgAdmin"] = "/admin/switch-org";
    DashboardRoutes["SignInAdmin"] = "/admin/login";
    DashboardRoutes["Metrics"] = "/admin/metrics";
    DashboardRoutes["Import"] = "/import";
    DashboardRoutes["ImportLoading"] = "/import/loading";
    DashboardRoutes["Unsubscribe"] = "/unsubscribe";
    DashboardRoutes["UnsubscribeOrganizationUser"] = "/unsubscribe-organization-user";
    DashboardRoutes["StripeOnboarding"] = "/stripe-onboarding";
    DashboardRoutes["Home"] = "/home";
    DashboardRoutes["Sandbox"] = "/sandbox";
    DashboardRoutes["Qa"] = "/qa";
    DashboardRoutes["IssuingOnboarding"] = "/issuing-onboarding";
    DashboardRoutes["ExpiredBidderLink"] = "/auction/expired-bidder-link";
    DashboardRoutes["ImportGMailContacts"] = "/sso/import-gmail-contacts";
    DashboardRoutes["ImportMailChimpContacts"] = "/sso/import-mailchimp-contacts";
    DashboardRoutes["GivingTuesdayMap"] = "/giving-tuesday/map";
    DashboardRoutes["FullScreenEmailEditor"] = "/emailing/full-screen-email-editor";
    DashboardRoutes["TicketingV2New"] = "/tickets/formsV2/new";
    DashboardRoutes["Accounts"] = "/bank/accounts";
    DashboardRoutes["CampaignHub"] = "/campaigns/forms";
    DashboardRoutes["CampaignPayments"] = "/campaigns/forms/hub";
    DashboardRoutes["Support"] = "/admin/support";
    DashboardRoutes["AllPayments"] = "/campaigns/all-payments";
    DashboardRoutes["ContactsReceipts"] = "/contacts/receipts";
    DashboardRoutes["BankReceipts"] = "/bank/receipts";
})(DashboardRoutes || (DashboardRoutes = {}));
export var MobileRoutes;
(function (MobileRoutes) {
    MobileRoutes["ResetPassword"] = "ResetPassword";
})(MobileRoutes || (MobileRoutes = {}));
export var FormQueryParams;
(function (FormQueryParams) {
    FormQueryParams["Modal"] = "modal";
})(FormQueryParams || (FormQueryParams = {}));
export var PeerToPeerQueryParams;
(function (PeerToPeerQueryParams) {
    PeerToPeerQueryParams["OpenFundraiser"] = "openFundraiser";
    PeerToPeerQueryParams["OpenTeamCreation"] = "openTeamCreation";
    PeerToPeerQueryParams["OpenTeamJoin"] = "openTeamJoin";
})(PeerToPeerQueryParams || (PeerToPeerQueryParams = {}));
const getPathWithLocale = (path, isoLocale) => {
    return isoLocale ? `/${isoLocale}${path}` : path;
};
export const getFormLink = (donationForm, isoLocale) => {
    if (donationForm.category === DonationFormCategory.PeerToPeerCampaign) {
        return RouteBuilders.buildPeerToPeerV2CampaignLink({
            type: donationForm.isPrimaryCampaign ? "general" : donationForm.isPrimaryTeam ? "team" : "individual",
            path: donationForm.path,
            isoLocale,
        }).path;
    }
    if (donationForm.category === DonationFormCategory.DonationCampaign) {
        return RouteBuilders.buildDonationCampaignLink({
            path: donationForm.path,
            isoLocale,
        }).path;
    }
    return RouteBuilders.buildDonationFormLink({
        path: donationForm.path,
        isoLocale,
    }).path;
};
export const getDonationOrTicketingFormLink = ({ form, isoLocale }) => {
    if (form.formType === FormType.Ticketing) {
        return getTicketingLink(form.path, isoLocale);
    }
    return getFormLink({
        ...form,
        category: form.formCategory === FormCategory.DonationForm
            ? DonationFormCategory.DonationForm
            : form.formCategory === FormCategory.DonationCampaign
                ? DonationFormCategory.DonationCampaign
                : DonationFormCategory.PeerToPeerCampaign,
    }, isoLocale);
};
export const getDonationOrTicketingEmbedLink = ({ form, isoLocale }) => {
    if (form.formType === FormType.Ticketing) {
        return getEmbedTicketingLink(form.path, isoLocale);
    }
    return getEmbedFormLink({
        ...form,
        category: form.formCategory === FormCategory.DonationForm
            ? DonationFormCategory.DonationForm
            : form.formCategory === FormCategory.DonationCampaign
                ? DonationFormCategory.DonationCampaign
                : DonationFormCategory.PeerToPeerCampaign,
    }, isoLocale);
};
export const getEmbedFormLink = (donationForm, isoLocale) => {
    if (donationForm.isPrimaryCampaign) {
        return RouteBuilders.buildPeerToPeerV2GeneralCampaignLink({
            isoLocale,
            path: donationForm.path,
        }).path;
    }
    if (donationForm.isPrimaryTeam) {
        return RouteBuilders.buildPeerToPeerV2TeamCampaignLink({
            isoLocale,
            path: donationForm.path,
        }).path;
    }
    return RouteBuilders.buildEmbedDonationFormLink({
        isoLocale,
        path: donationForm.path,
    }).path;
};
export const getEmbedThermometerLink = (path, isoLocale) => {
    return getPathWithLocale(`/embed${FrontendRoutes.Thermometer}${path}`, isoLocale);
};
export const getEmbedLeaderboardLink = (path, isoLocale) => {
    return getPathWithLocale(`/embed${FrontendRoutes.LeaderBoard}${path}`, isoLocale);
};
export const getTicketingLink = (path, isoLocale) => {
    return getPathWithLocale(`${FrontendRoutes.TicketingPage}${path}`, isoLocale);
};
export const getEmbedTicketingLink = (path, isoLocale, params) => {
    const queryParams = params ? `?${stringify(params || {})}` : "";
    return getPathWithLocale(`/embed${FrontendRoutes.TicketingPage}${path}${queryParams}`, isoLocale);
};
export const getTeamLink = (path, isoLocale) => {
    if (!path) {
        return null;
    }
    return `/${isoLocale}${FrontendRoutes.TeamPage}${path}`;
};
export const getIndividualFundraisingLink = (path, isoLocale) => {
    if (!path) {
        return null;
    }
    return RouteBuilders.buildDonationCampaignLink({
        isoLocale,
        path,
    }).path;
};
export const SHOW_SUCCESS_BANK_MODALE_QUERY_PARAM = "showSuccessBankModal";
export const IS_SUPER_ADMIN_QUERY_PARAM = "isSuperAdmin";
