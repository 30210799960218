import { alpha } from "@mui/material/styles";
import { BoxShadow } from "@mui/material/styles/createTheme";

import { palette } from "./palette";

export const boxShadow: BoxShadow = {
  base: {
    lightest: `0px 2px 4px ${alpha(palette.neutral[0], 0.08)};`,
    light: `0px 4px 8px ${alpha(palette.neutral[0], 0.1)};`,
    mid: `0px 8px 16px ${alpha(palette.neutral[0], 0.12)};`,
    strong: `0px 16px 32px ${alpha(palette.neutral[0], 0.12)};`,
  },
  button: {
    default: (color) => `0px 1px 2px ${alpha(color, 0.12)};`,
    hover: (color) => `0px 2px 8px ${alpha(color, 0.2)};`,
  },
  palette: (color) => ({
    lightest: `0px 2px 4px ${alpha(color, 0.12)};`,
    light: `0px 4px 8px ${alpha(color, 0.15)};`,
    mid: `0px 8px 16px ${alpha(color, 0.2)};`,
    strong: `0px 16px 32px ${alpha(color, 0.2)};`,
  }),
};
