export var StripeAccountAction;
(function (StripeAccountAction) {
    StripeAccountAction["AccountOnboarding"] = "account_onboarding";
    StripeAccountAction["AccountUpdate"] = "account_update";
})(StripeAccountAction || (StripeAccountAction = {}));
export var StripeCapabilityStatus;
(function (StripeCapabilityStatus) {
    StripeCapabilityStatus["Active"] = "active";
    StripeCapabilityStatus["Inactive"] = "inactive";
    StripeCapabilityStatus["Pending"] = "pending";
})(StripeCapabilityStatus || (StripeCapabilityStatus = {}));
export var StripePayoutStatus;
(function (StripePayoutStatus) {
    StripePayoutStatus["paid"] = "paid";
    StripePayoutStatus["pending"] = "pending";
    StripePayoutStatus["inTransit"] = "in_transit";
    StripePayoutStatus["canceled"] = "canceled";
    StripePayoutStatus["failed"] = "failed";
})(StripePayoutStatus || (StripePayoutStatus = {}));
export var PayoutInterval;
(function (PayoutInterval) {
    PayoutInterval["Monthly"] = "Monthly";
    PayoutInterval["Weekly"] = "Weekly";
    PayoutInterval["Daily"] = "Daily";
    PayoutInterval["Manual"] = "Manual";
})(PayoutInterval || (PayoutInterval = {}));
export var StripePayoutInterval;
(function (StripePayoutInterval) {
    StripePayoutInterval["monthly"] = "monthly";
    StripePayoutInterval["weekly"] = "weekly";
    StripePayoutInterval["daily"] = "daily";
    StripePayoutInterval["manual"] = "manual";
})(StripePayoutInterval || (StripePayoutInterval = {}));
export var BankAccountStatus;
(function (BankAccountStatus) {
    BankAccountStatus["Errored"] = "Errored";
    BankAccountStatus["Verified"] = "Verified";
})(BankAccountStatus || (BankAccountStatus = {}));
export var CompleteStripeAuthError;
(function (CompleteStripeAuthError) {
    CompleteStripeAuthError["ImpossibleToCreateStripeAccount"] = "ImpossibleToCreateStripeAccount";
})(CompleteStripeAuthError || (CompleteStripeAuthError = {}));
