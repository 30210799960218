import { forwardRef, useMemo } from "react";

// eslint-disable-next-line no-restricted-imports
import { Box, CircularProgress } from "@mui/material";
import classNames from "classnames";

import { NextLink } from "../../../components/NextLink/NextLink";
import { icon } from "../../../theme/icon";
import { Icon } from "../Icon";
import { useMergeClasses } from "../useMergeClasses";

import { zeffyButtonClasses, StyledButtonBase } from "./styles";
import { AllButtonVibesClasses, ButtonProps, ButtonVibe } from "./types";

export const DEFAULT_BUTTON_SIZE = "medium";
export const DEFAULT_BUTTON_VARIANT = "text";
export const DEFAULT_BUTTON_VIBE = "brand";

const ButtonComponent = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      classes: externalClasses,
      className,
      children,
      disabled,
      endIcon,
      fullWidth,
      iconButton = false,
      inline = false,
      isLoading = false,
      size = DEFAULT_BUTTON_SIZE,
      startIcon,
      variant = DEFAULT_BUTTON_VARIANT,
      vibe: inputVibe = DEFAULT_BUTTON_VIBE,
      ...rest
    },
    ref
  ) => {
    // Capturing stray "default" & "inherit" that are passed to Button from parent components
    // Our designs explicitly define which palette should be used
    // "inherit" does not cover our designs accurately
    const vibe: ButtonVibe = useMemo(
      () =>
        (inputVibe as ButtonVibe | "default") === "default" || inputVibe === ("inherit" as ButtonVibe | "inherit")
          ? DEFAULT_BUTTON_VIBE
          : (inputVibe as ButtonVibe),
      [inputVibe]
    );

    const classes = useMergeClasses(zeffyButtonClasses, externalClasses);

    const hasStartIcon = Boolean(startIcon);

    return (
      <StyledButtonBase
        {...rest}
        classes={{
          root: classNames(className, classes.root, classes[`${vibe}-${variant}` as AllButtonVibesClasses], {
            [classes.inherit]: inputVibe === "inherit",
            [classes.inline]: inline,
            [classes.rootWithStartIcon]: hasStartIcon,
            [classes.rootWithEndIcon]: Boolean(endIcon),
            [classes.fullWidth]: fullWidth,
            [classes[size as "large" | "small"]]: size !== "medium",
            [classes[`iconButton-${size}`]]: iconButton,
            [classes[`iconButton-inline-${size}`]]: iconButton && inline,
          }),
          focusVisible: classes.focusVisible,
        }}
        disabled={isLoading || disabled}
        data-test={rest["data-test"] || "button"}
        disableRipple
        ref={ref}
      >
        {isLoading && (
          <Box sx={{ position: "absolute" }}>
            <CircularProgress
              className={classes.progress}
              size={icon.size.default[size === "large" ? "small" : "extraSmall"]}
            />
          </Box>
        )}
        {hasStartIcon && (
          <Icon
            className={classNames(classes.startIcon, {
              [classes.progressWrapper]: isLoading,
              [classes.hideContent]: isLoading,
            })}
            vibe="inherit"
            inline={inline}
            size={size === "large" ? "medium" : "small"}
          >
            {startIcon}
          </Icon>
        )}
        {iconButton ? (
          <Icon
            className={classNames({
              [classes.hideContent]: isLoading,
            })}
            vibe="inherit"
            inline={inline}
            size="medium"
          >
            {children}
          </Icon>
        ) : (
          <Box
            className={classNames(classes.content, {
              [classes.hideContent]: isLoading,
            })}
            sx={{ display: "inline-flex", alignItems: "center", justifyContent: "center" }}
          >
            {children}
          </Box>
        )}
        {Boolean(endIcon) && (
          <Icon
            className={classNames(classes.endIcon, {
              [classes.hideContent]: isLoading,
            })}
            vibe="inherit"
            inline={inline}
            size={size === "large" ? "medium" : "small"}
          >
            {endIcon}
          </Icon>
        )}
      </StyledButtonBase>
    );
  }
);

ButtonComponent.displayName = "Button";

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { href, target, disableNextLinkLocale, ...rest } = props;
  if (href && target !== "_blank") {
    return (
      <NextLink href={href} target={target} locale={disableNextLinkLocale ? false : undefined}>
        <ButtonComponent {...rest} ref={ref} />
      </NextLink>
    );
  } else {
    return <ButtonComponent href={href} target={target} {...rest} ref={ref} />;
  }
});

Button.displayName = "Button";
