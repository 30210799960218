export var CreateOrderResponseError;
(function (CreateOrderResponseError) {
    CreateOrderResponseError["TicketingIsFull"] = "TicketingIsFull";
    CreateOrderResponseError["TicketingIsClosed"] = "TicketingIsClosed";
    CreateOrderResponseError["OverpassedTicketingSeats"] = "OverpassedTicketingSeats";
    CreateOrderResponseError["RateIsFull"] = "RateIsFull";
    CreateOrderResponseError["NoTicketSelected"] = "NoTicketSelected";
    CreateOrderResponseError["ChoiceIsFull"] = "ChoiceIsFull";
    CreateOrderResponseError["ExpiredEarlyBirdRate"] = "ExpiredEarlyBirdRate";
    CreateOrderResponseError["TicketingOccurrenceNotFound"] = "TicketingOccurrenceNotFound";
    CreateOrderResponseError["ShouldSpecifyATicketingOccurrence"] = "ShouldSpecifyATicketingOccurrence";
    CreateOrderResponseError["NoTicketNorExtraDonation"] = "TicketingCommandShouldHaveProductTicketOrExtraDonation";
    CreateOrderResponseError["UnknownError"] = "UnknownError";
})(CreateOrderResponseError || (CreateOrderResponseError = {}));
