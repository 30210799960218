import {
  chipClasses,
  createTheme,
  menuItemClasses,
  stepConnectorClasses,
  stepLabelClasses,
  svgIconClasses,
  ThemeOptions,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { CSSProperties } from "@mui/styles/withStyles";

import { boxShadow } from "./box-shadow";
import { palette } from "./palette";
import { spacing } from "./spacing";
import { typography } from "./typography";

const { breakpoints } = createTheme();

const muiMenuItemHover: CSSProperties = {
  background: palette.neutralVariant[99],
  backgroundColor: palette.neutralVariant[99],
};

export const components: ThemeOptions["components"] = {
  MuiCssBaseline: {
    styleOverrides: {
      body: typography.body2,
    },
  },
  MuiStepConnector: {
    styleOverrides: {
      root: {
        [breakpoints.down("sm")]: {
          marginLeft: -10,
          marginRight: -10,
        },
        [`&.${stepConnectorClasses.active}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            borderColor: palette.primary.main,
          },
          backgroundColor: palette.primary.main,
        },
        [`&.${stepConnectorClasses.completed}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            borderColor: palette.primary.main,
          },
          backgroundColor: palette.primary.main,
        },
      },
      alternativeLabel: {
        top: 18,
        [breakpoints.down("sm")]: {
          top: 10,
        },
        height: 5,
        border: 0,
        backgroundColor: grey[100],
        borderRadius: 1,
      },
      line: {
        borderColor: "#eaeaf0",
        borderTopWidth: 3,
        borderRadius: 1,
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        backgroundColor: grey[100],
      },
      input: {
        padding: "23px 12px 8px",
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: 3,
        [chipClasses.sizeSmall]: {
          height: 20,
        },
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        color: palette.border.neutral.quiet,
        ...(ownerState.orientation === "vertical" &&
          ownerState.variant === "middle" && {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
          }),
      }),
    },
  },
  MuiMenu: {
    styleOverrides: {
      list: {
        padding: 0,
      },
      paper: {
        padding: "8px 0",
        boxShadow: boxShadow.base.mid,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        ...typography.body1,
        // Bug in override that prevents me from setting the padding in one line.
        paddingTop: 8,
        paddingRight: 16,
        paddingBottom: 8,
        paddingLeft: 16,
        color: palette.neutralVariant[20],
        fill: palette.neutralVariant[20],
        [`&.${menuItemClasses.selected}`]: {
          ...typography.subtitle1,
          background: palette.primary.lightest,
          "&:hover": muiMenuItemHover,
        },
        "&:hover": muiMenuItemHover,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: typography.body2,
      head: typography.subtitle2,
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: 13,
        textAlign: "justify",
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      root: typography.caption,
      displayedRows: typography.caption,
      selectLabel: typography.caption,
    },
  },
  MuiStepLabel: {
    styleOverrides: {
      root: {
        [`&.${stepLabelClasses.active}`]: {
          color: palette.text.primary,
        },
      },
      label: {
        color: palette.text.secondary,
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        display: "flex",
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        paddingTop: spacing,
        paddingBottom: spacing,
        ...typography.body2,
      },
    },
  },
  MuiButtonGroup: {
    styleOverrides: {
      root: {
        boxShadow: "none",
      },
    },
  },
  MuiDateRangeCalendar: {
    styleOverrides: {
      monthContainer: { ":not(:last-of-type)": { borderRight: "0px" } },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        [`&.${svgIconClasses.fontSizeSmall}`]: {
          fontSize: "1.25rem",
        },
      },
    },
  },
  MuiMultiSectionDigitalClock: {
    styleOverrides: {
      root: {
        [`& ul::after`]: {
          height: "unset",
        },
      },
    },
  },
};
