import { FC, PropsWithChildren, useContext, useEffect, useState } from "react";

import * as amplitude from "@amplitude/analytics-browser";
import { Experiment, ExperimentClient } from "@amplitude/experiment-js-client";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import { AMPLITUDE_EXPERIMENT_CLIENT_CONFIG, DashboardRoutes, FrontendRoutes } from "@simplyk/common";
import { useRouter } from "next/router";

import { AMPLITUDE_API_KEY } from "../constants/amplitude";
import { AmplitudeContext } from "../contexts/AmplitudeContext";
import { ConsentModeContext } from "../contexts/ConsentModeContext";

const removeSearch = (route: string) => route.split("?")[0];

const FORM_ROUTES = Object.values(FrontendRoutes).map(removeSearch);

const DASHBOARD_ROUTES = Object.values(DashboardRoutes).map(removeSearch);

export const AmplitudeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [experimentClient, setExperimentClient] = useState<ExperimentClient | null>(null);
  const [isAmplitudeLoading, setIsAmplitudeLoading] = useState<boolean>(true);
  const { hasFunctionalityConsentMode } = useContext(ConsentModeContext);
  const router = useRouter();

  useEffect(() => {
    const initAmplitude = async () => {
      try {
        if (hasFunctionalityConsentMode && AMPLITUDE_API_KEY) {
          setIsAmplitudeLoading(true);
          const sampleRate = FORM_ROUTES.find((route) => router.asPath.startsWith(route))
            ? parseFloat(process.env.NEXT_PUBLIC_AMPLITUDE_SESSION_REPLAY_FORM_RATE || "0")
            : DASHBOARD_ROUTES.find((route) => router.asPath.includes(route))
              ? parseFloat(process.env.NEXT_PUBLIC_AMPLITUDE_SESSION_REPLAY_DASHBOARD_RATE || "0")
              : 0;
          const sessionReplayTracking = sessionReplayPlugin({
            sampleRate,
          });
          amplitude.add(sessionReplayTracking);
          amplitude.init(AMPLITUDE_API_KEY, {
            defaultTracking: {
              pageViews: false,
              formInteractions: false,
              fileDownloads: false,
              sessions: true,
            },
          });
          const experiment = Experiment.initializeWithAmplitudeAnalytics(AMPLITUDE_API_KEY, {
            fetchOnStart: true,
            automaticFetchOnAmplitudeIdentityChange: true,
            ...AMPLITUDE_EXPERIMENT_CLIENT_CONFIG({ fetchTimeoutMillis: 4000 }),
          });
          await experiment.start();
          amplitude.flush();
          setExperimentClient(experiment);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error initializing Amplitude", error);
      } finally {
        setIsAmplitudeLoading(false);
      }
    };
    initAmplitude();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFunctionalityConsentMode]);

  return (
    <AmplitudeContext.Provider value={{ experimentClient, isAmplitudeLoading }}>{children}</AmplitudeContext.Provider>
  );
};
