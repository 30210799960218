import { memo } from "react";

import { Box } from "@mui/material";
import { styled, Theme } from "@mui/material/styles";
import { CSSProperties } from "@mui/styles/withStyles";

import { LegacyZeffyCorePalette, IconSize } from "../../../types/mui";
import {
  ZeffyColorTokenTextClasses,
  getZeffyColorClassesKeyMap,
  getZeffyColorTokenTextStyleMap,
  InternalClasses,
} from "../helpers";

export type IconBaseClasses =
  | LegacyZeffyCorePalette
  | ZeffyColorTokenTextClasses
  | IconSize
  | "pointer"
  | "inherit"
  | "root"
  | `inline-${IconSize}`;

const getPaletteStyles = (theme: Theme, color: LegacyZeffyCorePalette): CSSProperties => ({
  fill: theme.palette[color].main,
});

const getSizeStyles = (theme: Theme, size: IconSize): CSSProperties => ({
  fontSize: theme.icon.size.default[size],
  height: theme.icon.size.default[size],
  lineHeight: `${theme.icon.size.default[size]}px`,
  width: theme.icon.size.default[size],
});

const getInlineSizeStyles = (theme: Theme, size: IconSize): CSSProperties => ({
  fontSize: theme.icon.size.inline[size],
  height: theme.icon.size.inline[size],
  lineHeight: `${theme.icon.size.inline[size]}px`,
  width: theme.icon.size.inline[size],
});

const PREFIX = "Icon";

export const zeffyIconClasses: InternalClasses<typeof PREFIX, IconBaseClasses> = {
  root: `${PREFIX}-root`,
  pointer: `${PREFIX}-pointer`,
  inherit: `${PREFIX}-inherit`,
  primary: `${PREFIX}-primary`,
  secondary: `${PREFIX}-secondary`,
  tertiary: `${PREFIX}-tertiary`,
  info: `${PREFIX}-info`,
  success: `${PREFIX}-success`,
  error: `${PREFIX}-error`,
  warning: `${PREFIX}-warning`,
  extraSmall: `${PREFIX}-extraSmall`,
  small: `${PREFIX}-small`,
  medium: `${PREFIX}-medium`,
  large: `${PREFIX}-large`,
  ["inline-extraSmall"]: `${PREFIX}-inline-extraSmall`,
  ["inline-small"]: `${PREFIX}-inline-small`,
  ["inline-medium"]: `${PREFIX}-inline-medium`,
  ["inline-large"]: `${PREFIX}-inline-large`,
  ...getZeffyColorClassesKeyMap(PREFIX),
};

export const Root = memo(
  styled(Box)(({ theme }: { theme: Theme }) => ({
    [`&.${zeffyIconClasses.root}`]: {
      display: "inline-flex",
    },

    [`&.${zeffyIconClasses.pointer}`]: {
      cursor: "pointer",
    },

    [`&.${zeffyIconClasses.inherit}`]: {
      fill: "inherit",
    },

    [`&.${zeffyIconClasses.primary}`]: getPaletteStyles(theme, "primary"),
    [`&.${zeffyIconClasses.secondary}`]: getPaletteStyles(theme, "secondary"),
    [`&.${zeffyIconClasses.tertiary}`]: getPaletteStyles(theme, "tertiary"),
    [`&.${zeffyIconClasses.info}`]: getPaletteStyles(theme, "info"),
    [`&.${zeffyIconClasses.success}`]: getPaletteStyles(theme, "success"),
    [`&.${zeffyIconClasses.error}`]: getPaletteStyles(theme, "error"),
    [`&.${zeffyIconClasses.warning}`]: getPaletteStyles(theme, "warning"),
    [`&.${zeffyIconClasses.extraSmall}`]: getSizeStyles(theme, "extraSmall"),
    [`&.${zeffyIconClasses.small}`]: getSizeStyles(theme, "small"),
    [`&.${zeffyIconClasses.medium}`]: getSizeStyles(theme, "medium"),
    [`&.${zeffyIconClasses.large}`]: getSizeStyles(theme, "large"),

    [`&.${zeffyIconClasses["inline-extraSmall"]}`]: getInlineSizeStyles(theme, "extraSmall"),
    [`&.${zeffyIconClasses["inline-small"]}`]: getInlineSizeStyles(theme, "small"),
    [`&.${zeffyIconClasses["inline-medium"]}`]: getInlineSizeStyles(theme, "medium"),
    [`&.${zeffyIconClasses["inline-large"]}`]: getInlineSizeStyles(theme, "large"),

    ...getZeffyColorTokenTextStyleMap(PREFIX, theme, "fill"),
  }))
);
