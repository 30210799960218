export var StaticVariableFormatKey;
(function (StaticVariableFormatKey) {
    StaticVariableFormatKey["FirstName"] = "common.firstName";
    StaticVariableFormatKey["LastName"] = "common.lastName";
})(StaticVariableFormatKey || (StaticVariableFormatKey = {}));
export var StaticVariableTag;
(function (StaticVariableTag) {
    StaticVariableTag["FirstName"] = "{{firstname}}";
    StaticVariableTag["LastName"] = "{{lastname}}";
    StaticVariableTag["Date"] = "{{date}}";
    StaticVariableTag["Location"] = "{{location}}";
})(StaticVariableTag || (StaticVariableTag = {}));
export var CustomVariableType;
(function (CustomVariableType) {
    CustomVariableType["MagicalLink"] = "MagicalLink";
    CustomVariableType["PrefilledForm"] = "PrefilledForm";
    CustomVariableType["ETicketUrl"] = "ETicketUrl";
    CustomVariableType["Date"] = "Date";
    CustomVariableType["Location"] = "Location";
})(CustomVariableType || (CustomVariableType = {}));
export var CustomButtonType;
(function (CustomButtonType) {
    CustomButtonType["FormLink"] = "FormLink";
    CustomButtonType["CustomLink"] = "CustomLink";
})(CustomButtonType || (CustomButtonType = {}));
