export var CommandSource;
(function (CommandSource) {
    CommandSource["FormSubmission"] = "FormSubmission";
    CommandSource["ManualEntry"] = "ManualEntry";
    CommandSource["Import"] = "Import";
})(CommandSource || (CommandSource = {}));
export var CommandStatus;
(function (CommandStatus) {
    CommandStatus["Open"] = "open";
    CommandStatus["Submitted"] = "submitted";
    CommandStatus["Complete"] = "complete";
    CommandStatus["Archived"] = "archived";
    CommandStatus["Expired"] = "expired";
})(CommandStatus || (CommandStatus = {}));
export var CommandLang;
(function (CommandLang) {
    CommandLang["EN"] = "EN";
    CommandLang["FR"] = "FR";
    CommandLang["ES"] = "ES";
})(CommandLang || (CommandLang = {}));
