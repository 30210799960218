import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(
  {
    nextLink: {
      textDecoration: "none",
      color: "inherit",
    },
  },
  { index: 10 }
);
