import { forwardRef } from "react";

import classnames from "classnames";

import { useMergeClasses } from "../useMergeClasses";

import { zeffyTypographyClasses, StyledTypography } from "./styles";
import { TypographyProps } from "./types";

export const Typography = forwardRef<HTMLParagraphElement, TypographyProps>(
  ({ classes: externalClasses, className, vibe, ...rest }, ref) => {
    const classes = useMergeClasses(zeffyTypographyClasses, externalClasses);
    return <StyledTypography {...rest} className={classnames(className, vibe && classes[vibe])} ref={ref} />;
  }
);

Typography.displayName = "Typography";
