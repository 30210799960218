import { Button } from "@mui/material/styles/createTheme";

export const button: Button = {
  size: {
    default: {
      small: 30,
      medium: 36,
      large: 42,
    },
  },
};
