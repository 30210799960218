import { FC, forwardRef } from "react";

import { IconButtonProps } from "./type";

import { Button } from "@/design-system/Button";

export const IconButton: FC<React.PropsWithChildren<IconButtonProps>> = forwardRef((props, ref) => {
  return <Button {...props} iconButton ref={ref} />;
});

IconButton.displayName = "IconButton";
