export var UserRegistrationStatus;
(function (UserRegistrationStatus) {
    UserRegistrationStatus["Registered"] = "Registered";
    UserRegistrationStatus["Unknown"] = "Unknown";
})(UserRegistrationStatus || (UserRegistrationStatus = {}));
export var AccessTokenError;
(function (AccessTokenError) {
    AccessTokenError["PasswordDoNotMatch"] = "PasswordDoNotMatch";
    AccessTokenError["UserAlreadyExistsWithOrganization"] = "UserAlreadyExistsWithOrganization";
    AccessTokenError["ResetTokenDoNotMatch"] = "ResetTokenDoNotMatch";
    AccessTokenError["NoDonorAllowedOnMobileApp"] = "NoDonorAllowedOnMobileApp";
    AccessTokenError["EmailDoesNotExist"] = "EmailDoesNotExist";
    AccessTokenError["UrlInvalid"] = "UrlInvalid";
    AccessTokenError["StytchOrganizationIdNotFound"] = "StytchOrganizationIdNotFound";
    AccessTokenError["EmailOrPasswordNotSent"] = "EmailOrPasswordNotSent";
    AccessTokenError["ErrorCreatingAccessToken"] = "error_creating_access_token";
    AccessTokenError["ErrorOnOrganizationCreation"] = "error_on_organization_creation";
    AccessTokenError["UserNotFoundWithOrganization"] = "user_not_found_with_organization";
    AccessTokenError["TokenDoesNotMatch"] = "TokenDoesNotMatch";
    AccessTokenError["EmailNotSent"] = "EmailNotSent";
    AccessTokenError["InvalidCharityNumber"] = "InvalidCharityNumber";
    AccessTokenError["TwoFactorCodeExpired"] = "TwoFactorCodeExpired";
    AccessTokenError["TwoFactorCodeDoesNotMatch"] = "TwoFactorCodeDoesNotMatch";
    AccessTokenError["GoogleTokenInvalid"] = "GoogleTokenInvalid";
    AccessTokenError["UnauthorizedToken"] = "UnauthorizedToken";
    AccessTokenError["InvalidOrganizationName"] = "InvalidOrganizationName";
})(AccessTokenError || (AccessTokenError = {}));
export var EditPasswordError;
(function (EditPasswordError) {
    EditPasswordError["UserNotFound"] = "UserNotFound";
    EditPasswordError["UserEmailNotFound"] = "UserEmailNotFound";
    EditPasswordError["WrongOldPassword"] = "WrongOldPassword";
})(EditPasswordError || (EditPasswordError = {}));
export var ResetPasswordLinkError;
(function (ResetPasswordLinkError) {
    ResetPasswordLinkError["EmailDoesNotExist"] = "EmailDoesNotExist";
})(ResetPasswordLinkError || (ResetPasswordLinkError = {}));
export var PaymentIntentError;
(function (PaymentIntentError) {
    PaymentIntentError["OwnerNotFound"] = "OwnerNotFound";
    PaymentIntentError["UnknownError"] = "UnknownError";
    PaymentIntentError["FormNotFound"] = "FormNotFound";
    PaymentIntentError["CardDeclined"] = "CardDeclined";
    PaymentIntentError["InsufficientCapabilitiesForTransfer"] = "InsufficientCapabilitiesForTransfer";
    PaymentIntentError["InsufficientFunds"] = "InsufficientFunds";
})(PaymentIntentError || (PaymentIntentError = {}));
export var EditMyProfileError;
(function (EditMyProfileError) {
    EditMyProfileError["EmailAlreadyUsed"] = "EmailAlreadyUsed";
})(EditMyProfileError || (EditMyProfileError = {}));
export var MergeUsersError;
(function (MergeUsersError) {
    MergeUsersError["UserNotFound"] = "UserNotFoundToMerge";
    MergeUsersError["TokenDoesNotMatch"] = "TokenDoesNotMatch";
    MergeUsersError["ErrorCreatingAccessToken"] = "error_creating_access_token";
})(MergeUsersError || (MergeUsersError = {}));
