import { memo } from "react";

import { Theme, styled } from "@mui/material/styles";
import { CSSProperties } from "@mui/styles/withStyles";
import { SnackbarContent } from "notistack";

import { InternalClasses } from "../helpers";

import { SnackbarBaseClasses, SnackbarVibe } from "./types";

const getVibeStyles = (theme: Theme, vibe: SnackbarVibe): CSSProperties => {
  const surfaceVibe = theme.palette.surface[vibe].quiet;
  return {
    background: surfaceVibe,
    boxShadow: theme.boxShadow.base.mid,
  };
};

const PREFIX = "ZeffySnackbar";

export const zeffySnackbarClasses: InternalClasses<typeof PREFIX, SnackbarBaseClasses> = {
  root: `${PREFIX}-root`,
  brand: `${PREFIX}-brand`,
  warning: `${PREFIX}-warning`,
  danger: `${PREFIX}-danger`,
  positive: `${PREFIX}-positive`,
  messageSection: `${PREFIX}-messageSection`,
  icon: `${PREFIX}-icon`,
  actionsSection: `${PREFIX}-actionsSection`,
  action: `${PREFIX}-action`,
  close: `${PREFIX}-close`,
};

export const StyledSnackbarContent = memo(
  styled(SnackbarContent)(({ theme }: { theme: Theme }) => ({
    [`&.${zeffySnackbarClasses.root}`]: {
      borderRadius: theme.radius(0.5),
      minHeight: 54,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      // Necessary override needed because of the way notistack injects its styles
      flexWrap: "nowrap !important" as CSSProperties["flexWrap"],
      padding: theme.spacing(1, 2),
      marginRight: theme.spacing(12),
    },

    [`&.${zeffySnackbarClasses.brand}`]: getVibeStyles(theme, "brand"),
    [`&.${zeffySnackbarClasses.warning}`]: getVibeStyles(theme, "warning"),
    [`&.${zeffySnackbarClasses.danger}`]: getVibeStyles(theme, "danger"),
    [`&.${zeffySnackbarClasses.positive}`]: getVibeStyles(theme, "positive"),

    [`& .${zeffySnackbarClasses.messageSection}`]: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },

    [`&& .${zeffySnackbarClasses.icon}`]: {
      marginRight: theme.spacing(1),
    },

    [`& .${zeffySnackbarClasses.actionsSection}`]: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },

    [`&& .${zeffySnackbarClasses.action}`]: {
      marginLeft: theme.spacing(1),
    },

    [`&& .${zeffySnackbarClasses.close}`]: {
      marginLeft: theme.spacing(1),
    },
  }))
);
