import { ForwardedRef, forwardRef } from "react";

import { SnackbarProviderProps, SnackbarProvider } from "notistack";

import { Snackbar } from "./Snackbar";

export const defaultSnackbarAnchorOrigin: NonNullable<SnackbarProviderProps["anchorOrigin"]> = {
  vertical: "bottom",
  horizontal: "left",
};

export const SnackBarComponents: SnackbarProviderProps["Components"] = {
  default: Snackbar,
};

export const ZeffySnackbarProvider = forwardRef(
  (
    {
      maxSnack = 3,
      anchorOrigin = defaultSnackbarAnchorOrigin,
      ...rest
    }: Omit<SnackbarProviderProps, "action" | "Components" | "iconVariant">,
    ref: ForwardedRef<SnackbarProvider>
  ) => {
    return (
      <>
        <SnackbarProvider
          anchorOrigin={anchorOrigin}
          Components={SnackBarComponents}
          maxSnack={maxSnack}
          ref={ref}
          {...rest}
        />
      </>
    );
  }
);

ZeffySnackbarProvider.displayName = "ZeffySnackbarProvider";
