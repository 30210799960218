import { Constants } from "@mui/material/styles/createTheme";

import { button } from "./button";
import { spacing } from "./spacing";

export const constants: Constants = {
  formHeaderHeight: 64,
  hubspotChatMargin: 100,
  notificationBannerHeight: 40,
  poweredByZeffyFooterHeight: 64,
  freeFormsButtonHeightMobile: button.size.default.small + 2 + 4 * spacing,
  freeFormsButtonHeightDesktop: button.size.default.small + 2 + 5 * spacing,
  formStepperFooterHeight: 75,
  stepperFooterAdditionalReviewBannerHeightMobile: 40,
  isFormV2: false,
  mainContainerContentPadding: {
    xs: 3 * spacing,
    md: 2 * spacing,
  },
};

export const SUPER_ADMIN_BACKGROUND_COLOR = "#4CC9F0";
