export const paymentExportColumnNames = [
    "createdAt",
    "totalAmount",
    "paymentMethod",
    "status",
    "payoutDate",
    "extraDonation",
    "refundedAmount",
    "recurringStatus",
    "discount",
    "firstName",
    "lastName",
    "email",
    "companyName",
    "address",
    "city",
    "postalCode",
    "region",
    "country",
    "locale",
    "receiptNumber",
    "receiptUrl",
    "eligibleAmount",
    "details",
    "rateTitle",
    "bidStatus",
    "itemAmount",
    "ticketNumber",
    "eTicketUrl",
    "canceled",
    "occurrence",
    "expirationDate",
    "scans",
    "lastScan",
    "formTitle",
    "formLink",
    "campaignTitle",
    "teamTitle",
    "teamCreationDate",
    "note",
    "inHonourName",
    "customQuestions",
];
export var ExportPaymentsType;
(function (ExportPaymentsType) {
    ExportPaymentsType["ItemizedPayments"] = "ItemizedPayments";
    ExportPaymentsType["Payments"] = "Payments";
})(ExportPaymentsType || (ExportPaymentsType = {}));
export var ExportFormType;
(function (ExportFormType) {
    ExportFormType["AllForms"] = "AllForms";
    ExportFormType["DonationForm"] = "DonationForm";
    ExportFormType["PeerToPeer"] = "PeerToPeer";
    ExportFormType["Event"] = "Event";
    ExportFormType["Raffle"] = "Raffle";
    ExportFormType["Custom"] = "Custom";
    ExportFormType["Membership"] = "Membership";
    ExportFormType["Auction"] = "Auction";
    ExportFormType["Shop"] = "Shop";
})(ExportFormType || (ExportFormType = {}));
