import { FC, PropsWithChildren, useCallback, useEffect, useState } from "react";

import { ConsentModeContext } from "../contexts/ConsentModeContext";
import { hasFunctionalityConsent } from "../helpers/consent";
import { updateGtmConsent } from "../helpers/gtm";
import { getConsentModeFromLocalStorage, setConsentModeInLocalStorage } from "../helpers/localStorage";
import { ConsentMode } from "../types/consent";

type WindowWithZeffyHspManager = Window & {
  zeffyHspManager?: {
    applyHubspotConsent?: () => void;
  };
};

declare const window: WindowWithZeffyHspManager;

const updateGtmAndLocalStorage = (consentMode: ConsentMode) => {
  updateGtmConsent(consentMode);
  setConsentModeInLocalStorage(consentMode);
  if (window.zeffyHspManager) {
    window.zeffyHspManager.applyHubspotConsent?.();
  }
};

export const ConsentModeProvider: FC<PropsWithChildren> = ({ children }) => {
  // Used to trigger events in the application when the user has selected a consent mode
  const [consentMode, setConsentMode] = useState(getConsentModeFromLocalStorage());

  const [isUserInQuebec, setIsUserInQuebec] = useState<boolean | null>(null);

  // Set to true by default since the fetch will be triggered on mount
  const [isUserInQuebecLoading, setIsUserInQuebecLoading] = useState(true);

  useEffect(() => {
    fetch(process.env.NEXT_PUBLIC_API_URL + "/application/quebec-user", { method: "GET" })
      .then(async (response) => {
        const result: boolean = await response.json();
        setIsUserInQuebec(result);
      })
      .finally(() => {
        setIsUserInQuebecLoading(false);
      });
  }, []);

  const handleSetConsentMode = useCallback((consentMode: ConsentMode) => {
    updateGtmAndLocalStorage(consentMode);
    setConsentMode(consentMode);
  }, []);

  return (
    <ConsentModeContext.Provider
      value={{
        hasConsentMode: Boolean(consentMode),
        hasFunctionalityConsentMode: hasFunctionalityConsent(consentMode),
        handleSetConsentMode,
        isUserInQuebec,
        isUserInQuebecLoading,
      }}
    >
      {children}
    </ConsentModeContext.Provider>
  );
};
