import { Avatar } from "@mui/material/styles/createTheme";

export const avatar: Avatar = {
  size: {
    extraSmall: 20,
    small: 24,
    medium: 32,
    large: 40,
  },
};
