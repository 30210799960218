/* eslint-disable sonarjs/no-nested-template-literals */
/* eslint-disable sonarjs/no-duplicate-string */

import { memo } from "react";

import ButtonBase from "@mui/material/ButtonBase";
import { darken, Theme, styled } from "@mui/material/styles";
import { CSSProperties } from "@mui/styles/withStyles";

import { getTransitions } from "../css";
import { InternalClasses } from "../helpers";

import { ButtonBaseClasses, ButtonVariant, ButtonVibe, FilledButtonVibes, OtherButtonVibes } from "./types";

const focusVisibleClass = "focusVisible";

const PREFIX = "ZeffyButton";

const filledVibes: FilledButtonVibes[] = ["brand", "alternate", "danger", "warning", "positive"];
const tonalVibes: OtherButtonVibes[] = ["brand", "neutral", "danger", "warning", "positive"];
const outlinedVibes: OtherButtonVibes[] = ["brand", "neutral", "danger", "warning", "positive"];
const textVibes: OtherButtonVibes[] = ["brand", "neutral", "danger", "warning", "positive"];

type VariantStyleKey<Vibe extends ButtonVibe, Variant extends ButtonVariant> = `${Vibe}-${Variant}`;
const getVariantStyleKey = <Vibe extends ButtonVibe, Variant extends ButtonVariant>(
  vibe: Vibe,
  variant: Variant
): VariantStyleKey<Vibe, Variant> => `${vibe}-${variant}`;

type PrefixedClassKey<Key extends string> = `${typeof PREFIX}-${Key}`;
const getPrefixedClassKey = <Vibe extends ButtonVibe, Variant extends ButtonVariant>(
  vibe: Vibe,
  variant: Variant
): PrefixedClassKey<VariantStyleKey<Vibe, Variant>> => `${PREFIX}-${getVariantStyleKey(vibe, variant)}`;

const getClassesVariantMap = <Vibe extends ButtonVibe, Variant extends ButtonVariant>(
  vibes: Vibe[],
  variant: Variant
): Record<VariantStyleKey<Vibe, Variant>, PrefixedClassKey<VariantStyleKey<Vibe, Variant>>> => {
  return vibes.reduce(
    (classesMap, vibe) => {
      classesMap[getVariantStyleKey(vibe, variant)] = getPrefixedClassKey(vibe, variant);
      return classesMap;
    },
    {} as Record<VariantStyleKey<Vibe, Variant>, PrefixedClassKey<VariantStyleKey<Vibe, Variant>>>
  );
};

export const zeffyButtonClasses: InternalClasses<typeof PREFIX, ButtonBaseClasses> = {
  [focusVisibleClass]: `${PREFIX}-${focusVisibleClass}`,
  content: `${PREFIX}-content`,
  root: `${PREFIX}-root`,
  small: `${PREFIX}-small`,
  large: `${PREFIX}-large`,
  rootWithStartIcon: `${PREFIX}-rootWithStartIcon`,
  rootWithEndIcon: `${PREFIX}-rootWithEndIcon`,
  inline: `${PREFIX}-inline`,
  inherit: `${PREFIX}-inherit`,
  fullWidth: `${PREFIX}-fullWidth`,
  progress: `${PREFIX}-progress`,
  progressWrapper: `${PREFIX}-progressWrapper`,
  startIcon: `${PREFIX}-startIcon`,
  endIcon: `${PREFIX}-endIcon`,
  hideContent: `${PREFIX}-hideContent`,
  ["iconButton-medium"]: `${PREFIX}-iconButton-medium`,
  ["iconButton-small"]: `${PREFIX}-iconButton-small`,
  ["iconButton-large"]: `${PREFIX}-iconButton-large`,
  ["iconButton-inline-medium"]: `${PREFIX}-iconButton-inline-medium`,
  ["iconButton-inline-small"]: `${PREFIX}-iconButton-inline-small`,
  ["iconButton-inline-large"]: `${PREFIX}-iconButton-inline-large`,
  ...getClassesVariantMap(filledVibes, "filled"),
  ...getClassesVariantMap(tonalVibes, "tonal"),
  ...getClassesVariantMap(outlinedVibes, "outlined"),
  ...getClassesVariantMap(textVibes, "text"),
};

const HOVER_PSUEDO_CLASS = "&:hover";
const FOCUS_PSUEDO_CLASSES = `&:active, &.${zeffyButtonClasses[focusVisibleClass]}`;

const getFilledStyles = (theme: Theme, vibeKey: FilledButtonVibes): CSSProperties => {
  const focusedOutlineVibe =
    vibeKey === "alternate" ? theme.palette.border.positive.moderate : theme.palette.border[vibeKey].moderate;

  const surfaceVibe =
    vibeKey === "alternate" ? theme.palette.surface.alternate : theme.palette.surface[vibeKey].intense;
  const hoveredSurfaceVibe = darken(surfaceVibe, 0.3);

  const textVibe = vibeKey === "alternate" ? theme.palette.text.alternate : theme.palette.text.reverse.intense;

  return {
    backgroundColor: surfaceVibe,
    color: textVibe,
    fill: textVibe,
    [HOVER_PSUEDO_CLASS]: {
      backgroundColor: hoveredSurfaceVibe,
    },
    [FOCUS_PSUEDO_CLASSES]: {
      outline: `3px solid ${focusedOutlineVibe}`,
    },
  };
};

const getAllFilledStyles = (theme: Theme): Record<string, CSSProperties> => {
  return filledVibes.reduce(
    (styles, vibe) => {
      styles[`&.${zeffyButtonClasses[`${vibe}-filled`]}`] = getFilledStyles(theme, vibe as FilledButtonVibes);
      return styles;
    },
    {} as Record<string, CSSProperties>
  );
};

export const getTonalStyles = (theme: Theme, vibeKey: OtherButtonVibes): CSSProperties => {
  const focusedOutlineVibe = theme.palette.border[vibeKey].moderate;

  const surfaceVibe = theme.palette.surface[vibeKey].moderate;
  const hoveredSurfaceVibe = darken(surfaceVibe, 0.1);

  const textVibe = theme.palette.text[vibeKey].intense;

  return {
    backgroundColor: surfaceVibe,
    color: textVibe,
    fill: textVibe,
    [HOVER_PSUEDO_CLASS]: {
      backgroundColor: hoveredSurfaceVibe,
    },
    [FOCUS_PSUEDO_CLASSES]: {
      outline: `3px solid ${focusedOutlineVibe}`,
    },
  };
};

const getAllTonalStyles = (theme: Theme): Record<string, CSSProperties> => {
  return tonalVibes.reduce(
    (styles, vibe) => {
      styles[`&.${zeffyButtonClasses[`${vibe}-tonal`]}`] = getTonalStyles(theme, vibe as OtherButtonVibes);
      return styles;
    },
    {} as Record<string, CSSProperties>
  );
};

const getOutlinedStyles = (theme: Theme, vibeKey: OtherButtonVibes): CSSProperties => {
  const borderColor = theme.palette.border[vibeKey].moderate;
  const focusedOutlineVibe = theme.palette.border[vibeKey].quiet;

  const hoveredSurfaceVibe = theme.palette.surface[vibeKey].quiet;

  const textVibe = theme.palette.text[vibeKey].intense;

  return {
    border: `1px solid ${borderColor}`,
    color: textVibe,
    fill: textVibe,
    [HOVER_PSUEDO_CLASS]: {
      backgroundColor: hoveredSurfaceVibe,
    },
    [FOCUS_PSUEDO_CLASSES]: {
      outline: `3px solid ${focusedOutlineVibe}`,
    },
  };
};

const getAllOutlinedStyles = (theme: Theme): Record<string, CSSProperties> => {
  return outlinedVibes.reduce(
    (styles, vibe) => {
      styles[`&.${zeffyButtonClasses[`${vibe}-outlined`]}`] = getOutlinedStyles(theme, vibe as OtherButtonVibes);
      return styles;
    },
    {} as Record<string, CSSProperties>
  );
};

const getTextStyles = (theme: Theme, vibeKey: OtherButtonVibes): CSSProperties => {
  const focusedOutlineVibe = theme.palette.border[vibeKey].quiet;

  const hoveredSurfaceVibe = theme.palette.surface[vibeKey].quiet;

  const textVibe = theme.palette.text[vibeKey].moderate;
  const hoveredTextVibe = theme.palette.text[vibeKey].intense;

  return {
    color: textVibe,
    fill: textVibe,
    [HOVER_PSUEDO_CLASS]: {
      backgroundColor: hoveredSurfaceVibe,
      color: hoveredTextVibe,
      fill: hoveredTextVibe,
    },
    [FOCUS_PSUEDO_CLASSES]: {
      outline: `3px solid ${focusedOutlineVibe}`,
    },
  };
};

const getAllTextStyles = (theme: Theme): Record<string, CSSProperties> => {
  return textVibes.reduce(
    (styles, vibe) => {
      styles[`&.${zeffyButtonClasses[`${vibe}-text`]}`] = getTextStyles(theme, vibe as OtherButtonVibes);
      return styles;
    },
    {} as Record<string, CSSProperties>
  );
};

const getIconButtonStyles = (theme: Theme, size: "small" | "large" | "medium", inline = false): CSSProperties => {
  const removeHeightStyles = { height: "unset", width: "unset", minHeight: "unset", minWidth: "unset" };
  switch (size) {
    case "small": {
      const heightAndWidth = theme.button.size.default.small;
      return {
        padding: theme.spacing(0.25),
        ...(inline ? removeHeightStyles : { minHeight: heightAndWidth, minWidth: heightAndWidth }),
      };
    }
    case "large": {
      const heightAndWidth = theme.button.size.default.large;
      return {
        padding: theme.spacing(inline ? 0.75 : 1),
        ...(inline ? removeHeightStyles : { minHeight: heightAndWidth, minWidth: heightAndWidth }),
      };
    }
    default: {
      const heightAndWidth = theme.button.size.default.medium;
      return {
        padding: theme.spacing(inline ? 0.5 : 0.75),
        ...(inline ? removeHeightStyles : { minHeight: heightAndWidth, minWidth: heightAndWidth }),
      };
    }
  }
};

export const getRootStyles = (theme: Theme): CSSProperties => ({
  alignItems: "center",
  borderRadius: theme.radius(1),
  boxSizing: "border-box",
  display: "inline-flex",
  opacity: 1,
  outline: `0 solid transparent`,
  minHeight: theme.button.size.default.medium,
  padding: theme.spacing(1, 2),
  ...theme.typography.button2,
  ...getTransitions(),
  "&:disabled": {
    opacity: 0.4,
  },
});

export const StyledButtonBase = memo(
  styled(ButtonBase)(({ theme }: { theme: Theme }) => ({
    [`&.${zeffyButtonClasses[focusVisibleClass]}`]: {},
    [`&.${zeffyButtonClasses.root}`]: getRootStyles(theme),

    [`&.${zeffyButtonClasses.small}`]: {
      minHeight: theme.button.size.default.small,
      padding: theme.spacing(0.5, 2),
    },

    [`&.${zeffyButtonClasses.large}`]: {
      minHeight: theme.button.size.default.large,
      ...theme.typography.button1,
    },

    [`&.${zeffyButtonClasses.rootWithStartIcon}`]: {
      paddingLeft: theme.spacing(1.5),
    },

    [`&.${zeffyButtonClasses.rootWithEndIcon}`]: {
      paddingRight: theme.spacing(1.5),
    },

    ...getAllFilledStyles(theme),
    ...getAllTonalStyles(theme),
    ...getAllOutlinedStyles(theme),
    ...getAllTextStyles(theme),

    [`&.${zeffyButtonClasses.inline}`]: {
      borderRadius: theme.radius(0.75),
    },

    [`&.${zeffyButtonClasses.inherit}`]: {
      fill: "inherit",
    },

    [`&.${zeffyButtonClasses.fullWidth}`]: {
      width: "100%",
    },

    [`&.${zeffyButtonClasses["iconButton-medium"]}`]: getIconButtonStyles(theme, "medium"),
    [`&.${zeffyButtonClasses["iconButton-small"]}`]: getIconButtonStyles(theme, "small"),
    [`&.${zeffyButtonClasses["iconButton-large"]}`]: getIconButtonStyles(theme, "large"),
    [`&.${zeffyButtonClasses["iconButton-inline-medium"]}`]: getIconButtonStyles(theme, "medium", true),
    [`&.${zeffyButtonClasses["iconButton-inline-small"]}`]: getIconButtonStyles(theme, "small", true),
    [`&.${zeffyButtonClasses["iconButton-inline-large"]}`]: getIconButtonStyles(theme, "large", true),

    [`& .${zeffyButtonClasses.startIcon}`]: {
      marginRight: theme.spacing(1),
    },

    [`& .${zeffyButtonClasses.endIcon}`]: {
      marginLeft: theme.spacing(1),
    },

    [`& .${zeffyButtonClasses.hideContent}`]: {
      opacity: 0,
    },

    [`& .${zeffyButtonClasses.content}`]: {},

    [`& .${zeffyButtonClasses.progress}`]: {
      color: "inherit",
    },

    [`& .${zeffyButtonClasses.progressWrapper}`]: {
      display: "inline-flex",
      alignItems: "center",
    },
  }))
);
