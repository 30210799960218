import { createTheme } from "@mui/material/styles";

import { avatar } from "./avatar";
import { boxShadow } from "./box-shadow";
import { button } from "./button";
import { components } from "./components";
import { constants } from "./constants";
import { icon } from "./icon";
import { legacySpacing } from "./legacySpacing";
import { mixins } from "./mixins";
import { palette } from "./palette";
import { radius } from "./radius";
import { spacing } from "./spacing";
import { typography, smTypography } from "./typography";
import { zIndex } from "./zIndex";

const baseTheme = {
  avatar,
  constants,
  spacing,
  typography,
  palette,
  mixins,
  icon,
  legacySpacing,
  radius,
  shape: {
    borderRadius: legacySpacing(1),
  },
  boxShadow,
  button,
  zIndex,
  components,
};

export const zeffyTheme = createTheme(baseTheme);

zeffyTheme.typography.h1 = {
  ...zeffyTheme.typography.h1,
  [zeffyTheme.breakpoints.down("sm")]: {
    ...smTypography.h1,
  },
};

zeffyTheme.typography.h2 = {
  ...zeffyTheme.typography.h2,
  [zeffyTheme.breakpoints.down("sm")]: {
    ...smTypography.h2,
  },
};

zeffyTheme.typography.h3 = {
  ...zeffyTheme.typography.h3,
  [zeffyTheme.breakpoints.down("sm")]: {
    ...smTypography.h3,
  },
};

zeffyTheme.typography.h4 = {
  ...zeffyTheme.typography.h4,
  [zeffyTheme.breakpoints.down("sm")]: {
    ...smTypography.h4,
  },
};

zeffyTheme.typography.h5 = {
  ...zeffyTheme.typography.h5,
  [zeffyTheme.breakpoints.down("sm")]: {
    ...smTypography.h5,
  },
};

zeffyTheme.typography.h6 = {
  ...zeffyTheme.typography.h6,
  [zeffyTheme.breakpoints.down("sm")]: {
    ...smTypography.h6,
  },
};

export const transparentBackgroundTheme = createTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    background: {
      default: "transparent",
    },
  },
});

export const superAdminTheme = createTheme({
  ...zeffyTheme,
  palette: {
    ...palette,
    primary: {
      ...palette.primary,
      main: "#8338EC",
    },
    secondary: {
      ...palette.secondary,
      main: "#4CC9F0",
    },
    tertiary: {
      ...palette.tertiary,
      main: "#293241",
    },
    error: {
      ...palette.error,
      main: "#EA3546",
    },
    warning: {
      ...palette.warning,
      main: "#F9C80E",
    },
  },
});
