import { Box, ButtonProps } from "@mui/material";
import dynamic from "next/dynamic";
const Lottie = dynamic(() => import("react-lottie"), { ssr: false });

import zeffyLoader from "./zeffy_loader.json";

interface ZeffyLoaderProps {
  size?: "small" | "medium" | "large";
  buttonSize?: ButtonProps["size"];
}

const HEIGHT_BY_SIZE = {
  small: 28,
  medium: 36,
  large: 100,
};

const HEIGHT_BY_BUTTON_SIZE = {
  small: 16,
  medium: 20,
  large: 20,
};

export const ZeffyLoader = ({ size = "medium", buttonSize }: ZeffyLoaderProps) => {
  return (
    <Box
      sx={{
        height: buttonSize ? HEIGHT_BY_BUTTON_SIZE[buttonSize] : HEIGHT_BY_SIZE[size],
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "& svg": { width: "unset !important" },
      }}
    >
      <Lottie
        options={{ animationData: zeffyLoader }}
        style={{ height: "inherit" }}
        ariaRole="loader"
        isClickToPauseDisabled={true}
      />
    </Box>
  );
};
