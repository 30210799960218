export var SendinblueAllowedExtensions;
(function (SendinblueAllowedExtensions) {
    SendinblueAllowedExtensions["PDF"] = "pdf";
    SendinblueAllowedExtensions["TXT"] = "txt";
    SendinblueAllowedExtensions["DOCX"] = "docx";
    SendinblueAllowedExtensions["PPTX"] = "pptx";
    SendinblueAllowedExtensions["XLSX"] = "xlsx";
    SendinblueAllowedExtensions["GIF"] = "gif";
    SendinblueAllowedExtensions["JPG"] = "jpg";
    SendinblueAllowedExtensions["JPEG"] = "jpeg";
    SendinblueAllowedExtensions["PNG"] = "png";
    SendinblueAllowedExtensions["TIF"] = "tif";
    SendinblueAllowedExtensions["TIFF"] = "tiff";
    SendinblueAllowedExtensions["BMP"] = "bmp";
    SendinblueAllowedExtensions["MP3"] = "mp3";
    SendinblueAllowedExtensions["M4A"] = "m4a";
    SendinblueAllowedExtensions["M4V"] = "m4v";
    SendinblueAllowedExtensions["WAV"] = "wav";
    SendinblueAllowedExtensions["MP4"] = "mp4";
    SendinblueAllowedExtensions["MOV"] = "mov";
    SendinblueAllowedExtensions["AVI"] = "avi";
})(SendinblueAllowedExtensions || (SendinblueAllowedExtensions = {}));
