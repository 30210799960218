import { FC } from "react";

import classNames from "classnames";
import ExternalNextLink, { LinkProps } from "next/link";

import { useStyles } from "./useStyles";

type NextLinkProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> &
  Omit<LinkProps, "href"> & {
    children?: React.ReactNode;
    href: LinkProps["href"] | undefined;
  } & React.RefAttributes<HTMLAnchorElement>;

export const NextLink: FC<NextLinkProps> = ({ href, prefetch = false, className, children, ...nextLinkProps }) => {
  const classes = useStyles();

  if (!href) {
    return <>{children}</>;
  }

  return (
    <ExternalNextLink
      {...nextLinkProps}
      href={href}
      className={classNames(classes.nextLink, className)}
      prefetch={prefetch}
    >
      {children}
    </ExternalNextLink>
  );
};
