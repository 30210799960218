import { createContext } from "react";

import { ExperimentClient } from "@amplitude/experiment-js-client";

interface AmplitudeContextProps {
  experimentClient: ExperimentClient | null;
  isAmplitudeLoading: boolean;
}

export const AmplitudeContext = createContext<AmplitudeContextProps>({
  experimentClient: null,
  isAmplitudeLoading: true,
});
