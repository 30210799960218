import { useCallback } from "react";

interface InteractiveElementEventsProps<T> {
  onClick?: (event: React.KeyboardEvent<T>) => void;
  onKeyDown?: (event: React.KeyboardEvent<T>) => void;
  onKeyUp?: (event: React.KeyboardEvent<T>) => void;
}

export const useInteractiveElementEvents = <T>({ onClick, onKeyDown, onKeyUp }: InteractiveElementEventsProps<T>) => {
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<T>) => {
      onKeyDown?.(event);
      if (event.key === "Enter" || event.code === "Enter") {
        onClick?.(event);
      }
    },
    [onClick, onKeyDown]
  );
  const handleKeyUp = useCallback(
    (event: React.KeyboardEvent<T>) => {
      onKeyUp?.(event);
      if (event.key === " " || event.code === "Space") {
        onClick?.(event);
      }
    },
    [onClick, onKeyUp]
  );

  return { handleKeyDown, handleKeyUp };
};
