import { DetailedHTMLProps, forwardRef, HTMLAttributes } from "react";

import { SxProps, Theme } from "@mui/material";
import { ClassNameMap } from "@mui/styles";
import classnames from "classnames";

import { useInteractiveElementEvents } from "../../../hooks/useInteractiveElementEvents";
import { LegacyZeffyCorePalette, IconSize } from "../../../types/mui";
import { ZeffyColorTokenTextClasses } from "../helpers";
import { useMergeClasses } from "../useMergeClasses";

import { IconBaseClasses, zeffyIconClasses, Root } from "./styles";

export interface IconProps
  extends Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "ref" | "onClick" | "color"> {
  classes?: Partial<ClassNameMap<IconBaseClasses>>;
  className?: string;
  vibe?: LegacyZeffyCorePalette | ZeffyColorTokenTextClasses | "inherit";
  inline?: boolean;
  onClick?: (event: unknown) => void;
  pointer?: boolean;
  size?: IconSize;
  style?: React.CSSProperties;
  sx?: SxProps<Theme>;
}

export const Icon = forwardRef<HTMLDivElement, IconProps>(
  (
    {
      children,
      className,
      classes: externalClasses,
      vibe = "tertiary",
      inline = false,
      onClick,
      onKeyDown,
      onKeyUp,
      size = "medium",
      tabIndex,
      pointer,
      ...rest
    },
    ref
  ) => {
    const { handleKeyDown, handleKeyUp } = useInteractiveElementEvents<HTMLDivElement>({ onClick, onKeyDown, onKeyUp });

    const classes = useMergeClasses(zeffyIconClasses, externalClasses);

    return (
      <Root
        ref={ref}
        className={classnames(
          classes.root,
          {
            [classes.pointer]: Boolean(onClick) || pointer,
            [classes[`inline-${size}`]]: inline,
          },
          classes[vibe],
          classes[size],
          className
        )}
        onClick={onClick}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        tabIndex={tabIndex !== undefined ? tabIndex : onClick ? 0 : undefined}
        {...rest}
      >
        {children}
      </Root>
    );
  }
);

Icon.displayName = "Icon";
