import { memo } from "react";

import { styled, Theme, PaletteColor } from "@mui/material/styles";
import MuiTypography from "@mui/material/Typography";
import { CSSProperties } from "@mui/styles/withStyles";

import { legacyCorePalette } from "../../../theme/palette";
import { zeffyTheme } from "../../../theme/theme";
import {
  getRootStyleClassMap,
  getZeffyColorClassesKeyMap,
  getZeffyColorTokenTextStyleMap,
  getClassesKeyMap,
  InternalClasses,
} from "../helpers";

import { TypographyClasses, LegacyTypographyPaletteClasses, LegacyTypographyZeffyColorClasses } from "./types";

import { LegacyZeffyColors, LegacyZeffyColorShades, LegacyZeffyCorePalette } from "@/types/mui";

type ZeffyColorClassesRecord<T extends LegacyZeffyColors> = Record<LegacyTypographyZeffyColorClasses<T>, CSSProperties>;
type ZeffyPaletteClassesRecord = Record<LegacyTypographyPaletteClasses, CSSProperties>;

const getLegacyZeffyColorClasses = <T extends LegacyZeffyColors>(
  theme: Theme,
  zeffyColor: T
): ZeffyColorClassesRecord<T> =>
  Object.keys(theme.palette[zeffyColor]).reduce((shades: ZeffyColorClassesRecord<T>, colorShade: string) => {
    shades[`${zeffyColor}${colorShade}` as LegacyTypographyZeffyColorClasses<T>] = {
      color: theme.palette[zeffyColor][Number(colorShade) as LegacyZeffyColorShades],
    };
    return shades;
  }, {} as ZeffyColorClassesRecord<T>);

const getLegacyPaletteClasses = (theme: Theme): ZeffyPaletteClassesRecord =>
  Object.keys(legacyCorePalette).reduce((palette: ZeffyPaletteClassesRecord, paletteColor: string) => {
    const paletteColorName = paletteColor as LegacyZeffyCorePalette;
    Object.keys(theme.palette[paletteColorName]).forEach((colorShade: string) => {
      palette[`${paletteColorName}-${colorShade}` as LegacyTypographyPaletteClasses] = {
        color: theme.palette[paletteColorName][colorShade as keyof PaletteColor],
      };
    });
    return palette;
  }, {} as ZeffyPaletteClassesRecord);

const PREFIX = "ZeffyTypography";

export const zeffyTypographyClasses: InternalClasses<typeof PREFIX, TypographyClasses> = {
  ...getClassesKeyMap(PREFIX, getLegacyZeffyColorClasses(zeffyTheme, "neutral")),
  ...getClassesKeyMap(PREFIX, getLegacyZeffyColorClasses(zeffyTheme, "neutralVariant")),
  ...getClassesKeyMap(PREFIX, getLegacyPaletteClasses(zeffyTheme)),
  ...getZeffyColorClassesKeyMap(PREFIX),
};

export const StyledTypography = memo(
  styled(MuiTypography)(({ theme }: { theme: Theme }) => ({
    ...getRootStyleClassMap(PREFIX, getLegacyZeffyColorClasses(theme, "neutral")),
    ...getRootStyleClassMap(PREFIX, getLegacyZeffyColorClasses(theme, "neutralVariant")),
    ...getRootStyleClassMap(PREFIX, getLegacyPaletteClasses(theme)),
    ...getZeffyColorTokenTextStyleMap(PREFIX, theme, "color"),
  }))
);
