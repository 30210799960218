import router from "next/router";

import { canAccessClientStorage } from "./embed";
import { getFromLocalStorage, setInLocalStorage } from "./localStorage";

const PageHasBeenForceRefreshed = "page-has-been-force-refreshed";
const REFRESH_COUNT_MAX = 3;

export const retryPageLoading = (): { willRefresh: boolean } => {
  const refreshCountStored = parseInt(getFromLocalStorage?.(PageHasBeenForceRefreshed) || "0");

  const refreshCount = isNaN(refreshCountStored) ? 0 : refreshCountStored;
  const incrementRefreshCount = refreshCount + 1;

  if (refreshCount < REFRESH_COUNT_MAX && canAccessClientStorage()) {
    setInLocalStorage?.(PageHasBeenForceRefreshed, incrementRefreshCount.toString());
    router.reload();
    return { willRefresh: true };
  } else {
    setInLocalStorage?.(PageHasBeenForceRefreshed, "0");
    return { willRefresh: false };
  }
};
