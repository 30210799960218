export var AmplitudeEventKeys;
(function (AmplitudeEventKeys) {
    AmplitudeEventKeys["ClickSlippingAwayReport"] = "CLICK-SLIPPING-AWAY-REPORT";
    AmplitudeEventKeys["ClickVisitorReport"] = "CLICK-VISITOR-REPORT";
    AmplitudeEventKeys["ClickChurnedReport"] = "CLICK-CHURN-REPORT";
})(AmplitudeEventKeys || (AmplitudeEventKeys = {}));
export var AmplitudeVariantValue;
(function (AmplitudeVariantValue) {
    AmplitudeVariantValue["OFF"] = "off";
    AmplitudeVariantValue["CONTROL"] = "control";
    AmplitudeVariantValue["A"] = "a";
    AmplitudeVariantValue["B"] = "b";
    AmplitudeVariantValue["C"] = "c";
})(AmplitudeVariantValue || (AmplitudeVariantValue = {}));
export var AmplitudeFlagKey;
(function (AmplitudeFlagKey) {
    AmplitudeFlagKey["TaxDeductionMessaging"] = "tax-deduction-messaging";
    AmplitudeFlagKey["DonationFormCopyAbTest"] = "donation-form-copy-ab-test";
    AmplitudeFlagKey["TicketingFormButtonCopyAbTest"] = "ticketing-form-button-copy-ab-test";
    AmplitudeFlagKey["CheckboxAcknowledgingContribution"] = "checkbox-acknowledging-contribution-activation";
    AmplitudeFlagKey["EmptyStateExperiment"] = "empty-state-redirection";
    AmplitudeFlagKey["IsCashappEnabled"] = "is-cashapp-enabled";
    AmplitudeFlagKey["V2FormToggle"] = "v2-form-toggle";
    AmplitudeFlagKey["MigrateToStytch"] = "stytch";
    AmplitudeFlagKey["TwoFAByPhone"] = "2fa-by-phone";
    AmplitudeFlagKey["EngagementCheckListFeatureFlag"] = "engagement_checklist_ab_test";
    AmplitudeFlagKey["EngagementChecklistClosedWonUsers"] = "engagement-checklist-closed-won-users";
    AmplitudeFlagKey["CommunicationStrategy"] = "communication-strategy";
    AmplitudeFlagKey["ValuePropDuringSignUp"] = "value-prop-during-sign-up";
    AmplitudeFlagKey["CheckoutButtonAndSummaryOnTicketForms"] = "checkout-button-and-summary-on-ticket-forms";
    AmplitudeFlagKey["VisitorsReport"] = "visitors-report";
    AmplitudeFlagKey["QaResetEngagementCheckList"] = "qa-resetengagementchecklist";
    AmplitudeFlagKey["QaActions"] = "qaactions";
    AmplitudeFlagKey["MobileDropFormCreation"] = "mobile-drop-form-creation";
    AmplitudeFlagKey["TwoFAAtBankConnection"] = "2fa-at-bank-connection";
    AmplitudeFlagKey["DemotivateEmbeds"] = "demotivate-embeds";
    AmplitudeFlagKey["StripeLinkPaymentMethod"] = "stripe-link-payment-method";
    AmplitudeFlagKey["IsCustomEmailFlowActivated"] = "is-custom-email-flow-activated";
    AmplitudeFlagKey["CampaignHub"] = "campaign-hub";
    AmplitudeFlagKey["MemorableFormsNewLayout"] = "memorable-forms-new-layout";
    AmplitudeFlagKey["OneClickSignUp"] = "one-click-signup";
    AmplitudeFlagKey["NpoStarterBanking"] = "npo-starter-banking";
    AmplitudeFlagKey["IsEmailBuilderFlowActivated"] = "is-email-builder-flow-activated";
    AmplitudeFlagKey["NpoStarterToDoList"] = "npo-starter-to-do-list";
    AmplitudeFlagKey["V2DonationFormToggle"] = "v2-donation-form-toggle";
    AmplitudeFlagKey["IsImportGmailActive"] = "is-gmail-import-active";
    AmplitudeFlagKey["SimplifiedOnboardingChecklist"] = "simplified-onboarding-checklist";
    AmplitudeFlagKey["V2AuctionFormToggle"] = "v2-auction-form-toggle";
    AmplitudeFlagKey["AuctionMemorableForm"] = "auction-memorable-form";
    AmplitudeFlagKey["TipExplanationInLiveForm"] = "tip-explanation-in-live-form";
})(AmplitudeFlagKey || (AmplitudeFlagKey = {}));
