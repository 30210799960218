import { spacing } from "./spacing";

import { LegacySpacing } from "@/types/mui";

export const createLegacySpacing =
  (spacingInput = 8): LegacySpacing =>
  (value?: number): number =>
    value !== undefined ? value * spacingInput : spacingInput;

export const legacySpacing = createLegacySpacing(spacing);
