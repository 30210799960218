import { ZeffyMixins } from "@/types/mui";

export const mixins: ZeffyMixins = {
  drawerMixin: {
    width: 260,
  },
  toolbarHeight: 128,
  smallIconContainerWidth: 48,
  truncateOverflow: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  limitTextToNLines: (n: number = 2) => ({
    overflow: "hidden",
    overflowWrap: "break-word",
    display: "-webkit-box",
    "-webkit-line-clamp": n,
    "-webkit-box-orient": "vertical",
  }),
};
