export const AMPLITUDE_EVENT_KEY = "AmplitudeEventKey";
export const AMPLITUDE_GROUP_TYPE = "Organization";
export const ORGANIZATION_ONGOING_AB_TESTS_KEY = "organization_ongoing_ab_tests";
export const DONOR_ONGOING_AB_TEST = "donor_ongoing_ab_test";
export const USER_ONGOING_AB_TESTS = "user_ongoing_ab_tests";
export const AMPLITUDE_EXPERIMENT_CLIENT_CONFIG = ({ fetchTimeoutMillis }) => ({
    fetchTimeoutMillis,
    fetchRetries: 2,
    fetchRetryBackoffMinMillis: 400,
    fetchRetryTimeoutMillis: fetchTimeoutMillis * 2,
});
